import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {
    IResearchConclusionSlice,
    ISliderSubject,
    ISliderCompetence,
    ISliderCcompetence,
    ISliderSkills,
} from "./types"

const initialState: IResearchConclusionSlice = {
    subjects: [],
    subjectsSelected: [],
    competencesSelected: [],
    ccompetencesSelected: [],
    skillsSelected: [],
    researchStarted: false,
    researchFinished: false,
}

export const researchConclusionSlice = createSlice({
    name: "researchConclusion",
    initialState,
    reducers: {
        setSubjects: (state, action: PayloadAction<ISliderSubject[]>) => {
            state.subjects = action.payload
        },
        addSubjectsSelected: (state, action: PayloadAction<ISliderSubject>) => {
            state.subjectsSelected.push(action.payload)
        },
        removeSubjectsSelected: (state, action: PayloadAction<ISliderSubject>) => {
            state.subjectsSelected = state.subjectsSelected.filter(
                (subject) => subject.subject_name !== action.payload.subject_name
            )
        },
        setSubjectsSelected: (state, action: PayloadAction<ISliderSubject[]>) => {
            state.subjectsSelected = action.payload
        },
        addCompetencesSelected: (state, action: PayloadAction<ISliderCompetence>) => {
            state.competencesSelected.push(action.payload)
        },
        removeCompetencesSelected: (state, action: PayloadAction<ISliderCompetence>) => {
            state.competencesSelected = state.competencesSelected.filter(
                (competence) => competence.competence_name !== action.payload.competence_name
            )
        },
        setCompetencesSelected: (state, action: PayloadAction<ISliderCompetence[]>) => {
            state.competencesSelected = action.payload
        },
        addCcompetencesSelected: (state, action: PayloadAction<ISliderCcompetence>) => {
            state.ccompetencesSelected.push(action.payload)
        },
        removeCcompetencesSelected: (state, action: PayloadAction<ISliderCcompetence>) => {
            state.ccompetencesSelected = state.ccompetencesSelected.filter(
                (ccompetence) => ccompetence.ccompetence_name !== action.payload.ccompetence_name
            )
        },
        setCcompetencesSelected: (state, action: PayloadAction<ISliderCcompetence[]>) => {
            state.ccompetencesSelected = action.payload
        },
        addSkillSelected: (state, action: PayloadAction<ISliderSkills>) => {
            state.skillsSelected.push(action.payload)
        },
        removeSkillSelected: (state, action: PayloadAction<ISliderSkills>) => {
            state.skillsSelected = state.skillsSelected.filter(
                (skill) => skill.skill_name !== action.payload.skill_name
            )
        },
        setSkillsSelected: (state, action: PayloadAction<ISliderSkills[]>) => {
            state.skillsSelected = action.payload
        },
        resetSelectedFields: (state) => {
            state.subjects = []
            state.subjectsSelected = []
            state.competencesSelected = []
            state.ccompetencesSelected = []
            state.skillsSelected = []
        },
        resetSelectedFieldsWithoutSubjects: (state) => {
            state.subjectsSelected = []
            state.competencesSelected = []
            state.ccompetencesSelected = []
            state.skillsSelected = []
        },
        setResearchStarted: (state, action: PayloadAction<boolean>) => {
            state.researchStarted = action.payload
        },
        setResearchFinished: (state, action: PayloadAction<boolean>) => {
            state.researchFinished = action.payload
        },
    },
})

export const {actions: researchConclusionActions} = researchConclusionSlice
export const {reducer: researchConclusionReducer} = researchConclusionSlice
