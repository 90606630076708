// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HistorySection_wrapper__79lh6 {\n  margin-top: 30px;\n}\n.HistorySection_title__BkQjm {\n  display: flex;\n  justify-content: space-between;\n  background-color: #ffffff;\n  border-radius: 5px;\n  padding: 10px;\n  font-weight: 500;\n  font-size: 24px;\n  cursor: pointer;\n}\n.HistorySection_content__UZNgL {\n  max-height: 0;\n  overflow: hidden;\n  transition: max-height 0.5s ease-in-out;\n}\n.HistorySection_show__wG-j8 {\n  max-height: 9999px;\n  transition: max-height 0.5s ease-in-out;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/HistorySection/HistorySection.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,uCAAuC;AACzC;AACA;EACE,kBAAkB;EAClB,uCAAuC;AACzC","sourcesContent":[".wrapper {\n  margin-top: 30px;\n}\n.title {\n  display: flex;\n  justify-content: space-between;\n  background-color: #ffffff;\n  border-radius: 5px;\n  padding: 10px;\n  font-weight: 500;\n  font-size: 24px;\n  cursor: pointer;\n}\n.content {\n  max-height: 0;\n  overflow: hidden;\n  transition: max-height 0.5s ease-in-out;\n}\n.show {\n  max-height: 9999px;\n  transition: max-height 0.5s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HistorySection_wrapper__79lh6",
	"title": "HistorySection_title__BkQjm",
	"content": "HistorySection_content__UZNgL",
	"show": "HistorySection_show__wG-j8"
};
export default ___CSS_LOADER_EXPORT___;
