// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HistorySectionItem_wrapper__X-auH {\n  margin-top: 20px;\n}\n.HistorySectionItem_task__-O0Pr {\n  font-weight: 500;\n  font-size: 16px;\n}\n.HistorySectionItem_title__L7Opg {\n  font-weight: 500;\n  margin-bottom: 10px;\n  line-height: 1.2;\n}\n.HistorySectionItem_subtitle__Co7Po {\n  font-weight: 500;\n  margin-bottom: 16px;\n  line-height: 1.2;\n}\n.HistorySectionItem_knowledge__GN0U2 {\n  font-weight: 500;\n  margin-bottom: 16px;\n  line-height: 1.2;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/HistorySection/HistorySectionItem/HistorySectionItem.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  margin-top: 20px;\n}\n.task {\n  font-weight: 500;\n  font-size: 16px;\n}\n.title {\n  font-weight: 500;\n  margin-bottom: 10px;\n  line-height: 1.2;\n}\n.subtitle {\n  font-weight: 500;\n  margin-bottom: 16px;\n  line-height: 1.2;\n}\n.knowledge {\n  font-weight: 500;\n  margin-bottom: 16px;\n  line-height: 1.2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HistorySectionItem_wrapper__X-auH",
	"task": "HistorySectionItem_task__-O0Pr",
	"title": "HistorySectionItem_title__L7Opg",
	"subtitle": "HistorySectionItem_subtitle__Co7Po",
	"knowledge": "HistorySectionItem_knowledge__GN0U2"
};
export default ___CSS_LOADER_EXPORT___;
