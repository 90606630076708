// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieChartWithCenterText_label__jYjw7 {\n  font-size: 50px;\n  font-weight: 700;\n}\n\n.PieChartWithCenterText_label_wrapper__Mb6uz {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border-radius: 50%;\n}\n\n.PieChartWithCenterText_label_title__ex4rv {\n  color: #444;\n  z-index: 2;\n  font-weight: bold;\n}\n\n.PieChartWithCenterText_label_percent__wLxK9 {\n  position: relative;\n  font-size: 110px;\n  font-weight: bold;\n  color: #444;\n  z-index: 2;\n  line-height: 1;\n}\n\n.PieChartWithCenterText_label_span__QIPrp {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 22px;\n  color: #444;\n  z-index: 2;\n}", "",{"version":3,"sources":["webpack://./src/shared/components/PieChartWithCenterText/PieChartWithCenterText.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AACA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;AAEF;;AAAA;EAEE,WAAA;EACA,UAAA;EACA,iBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,UAAA;EACA,cAAA;AAGF;;AADA;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;AAIF","sourcesContent":[".label {\n  font-size: 50px;\n  font-weight: 700;\n}\n.label_wrapper {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border-radius: 50%;\n}\n.label_title {\n  //margin-top: 20px;\n  color: #444;\n  z-index: 2;\n  font-weight: bold;\n}\n.label_percent {\n  position: relative;\n  font-size: 110px;\n  font-weight: bold;\n  color: #444;\n  z-index: 2;\n  line-height: 1;\n}\n.label_span {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 22px;\n  color: #444;\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "PieChartWithCenterText_label__jYjw7",
	"label_wrapper": "PieChartWithCenterText_label_wrapper__Mb6uz",
	"label_title": "PieChartWithCenterText_label_title__ex4rv",
	"label_percent": "PieChartWithCenterText_label_percent__wLxK9",
	"label_span": "PieChartWithCenterText_label_span__QIPrp"
};
export default ___CSS_LOADER_EXPORT___;
