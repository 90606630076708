import styles from "./getClassnameForTitle.module.css"

export function getClassnameForTitle(title: string): string {
    switch ((title || "").toLocaleLowerCase()) {
        case "заявка":
            return styles.request
        case "бесплатно":
            return styles.free
        case "подписка":
            return styles.subscription
        case "покупка":
            return styles.paid
    }

    return ""
}
