// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResearchConclusionPage_button__xonwz {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  background-color: white;\n  border-radius: 5px;\n  position: fixed;\n  top: 50px;\n  right: calc(50% - 560px);\n  cursor: pointer;\n  z-index: 56;\n  transition: 0.3s;\n}\n.ResearchConclusionPage_button__xonwz:hover {\n  transition: 0.3s;\n  transform: scale(1.1);\n}\n.ResearchConclusionPage_button__xonwz svg {\n  margin-left: 5px;\n}\n\n.ResearchConclusionPage_modal__18aOX > div {\n  width: 900px;\n  min-height: 100%;\n  border-radius: 0;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,SAAA;EACA,wBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;AACF;AAAE;EACD,gBAAA;EACA,qBAAA;AAED;AAAE;EACD,gBAAA;AAED;;AAEC;EACE,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,SAAA;AAAH","sourcesContent":[".button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  background-color: white;\n  border-radius: 5px;\n  position: fixed;\n  top: 50px;\n  right: calc(50% - 560px);\n  cursor: pointer;\n  z-index: 56;\n  transition: 0.3s;\n  &:hover {\n\ttransition: 0.3s;\n\ttransform: scale(1.1);\n  }\n  svg {\n\tmargin-left: 5px;\n  }\n}\n.modal {\n\t> div {\n\t  width: 900px;\n\t  min-height: 100%;\n\t  //min-height: 620px;\n\t  border-radius: 0;\n\t  margin: 0;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ResearchConclusionPage_button__xonwz",
	"modal": "ResearchConclusionPage_modal__18aOX"
};
export default ___CSS_LOADER_EXPORT___;
