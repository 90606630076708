import classNames from "classnames"
import styles from "./SliderSectionItem.module.scss"
import {Checkbox, Chip, FormControlLabel, FormGroup} from "@mui/material"
import {ReactNode} from "react"

interface ISliderSectionItem {
    title: string
    active: boolean
    disableAllButton: boolean
    disableReset: boolean
    onResetClick: any
    onSelectAllClick: any
    children: ReactNode
}

export const SliderSectionItem = ({
    title,
    active,
    disableAllButton,
    disableReset,
    children,
    onResetClick,
    onSelectAllClick,
}: ISliderSectionItem) => {
    return (
        // <div className={classNames(styles.wrapper, {[styles.small]: active})}>
        <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.all}>
                <span
                    className={classNames({[styles.disabled]: disableAllButton})}
                    onClick={onSelectAllClick}>
                    Все
                </span>
                <Chip
                    size="small"
                    label="Сбросить"
                    disabled={disableReset}
                    onClick={onResetClick}
                    className={styles.chip}
                />
            </div>
            <FormGroup>{children}</FormGroup>
        </div>
    )
}
