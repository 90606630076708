import {ReactNode} from "react"
import styles from "./Paper.module.css"

interface IPaper {
    children: ReactNode
    background?: string
    color?: string
}

export const Paper = ({children, background = "#FFFFFF", color}: IPaper) => {
    return (
        <div className={styles.paper} style={{background: background, color: color}}>
            {children}
        </div>
    )
}
