import {useDispatch, useSelector} from "react-redux"
import {
    researchSettingsActions,
    selectAllFormsValid,
    selectForms,
} from "../../../shared/store/researchSettings"
import {useStartTestMutation} from "../../../shared/api/endpoints/researchSettingsEndpoints"
import {useNavigate} from "react-router-dom"
import {IFormRequest} from "../../../shared/store/researchSettings/types"
import {Button} from "@mui/material"
import {researchConclusionActions} from "../../../shared/store/researchConslusion"

export const StartTestButton = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const forms = useSelector(selectForms)
    const allFormsValid = useSelector(selectAllFormsValid)
    const {resetForms} = researchSettingsActions
    const {setResearchStarted} = researchConclusionActions
    const [startTest] = useStartTestMutation()

    const sendData = async () => {
        const data: IFormRequest[] = forms.map(({competence, ccompetence, skill, complexity}) => ({
            competence: {id: competence},
            ccompetence: {id: ccompetence},
            skill: {id: skill},
            complexity,
        }))
        const result: any = await startTest({forms: data})
        const sessionId = result?.data?.session_id
        if (sessionId) {
            dispatch(resetForms())
            dispatch(setResearchStarted(true))
            navigate(`/cipui/cip_dashboard/research_page/${sessionId}`)
        } else {
            // TODO show error message
            console.log("No sessionId")
        }
    }

    return (
        <Button
            size="large"
            disabled={!allFormsValid}
            variant="contained"
            color={"secondary"}
            onClick={sendData}
            fullWidth>
            НАЧАТЬ ДИАГНОСТИКУ
        </Button>
    )
}
