import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useSwiper} from "swiper/react"
import {
    ISliderCcompetence,
    researchConclusionActions,
    selectCcompetencesSelected,
    selectCompetencesSelected,
    selectSkillsSelected,
} from "../../../../../shared/store/researchConslusion"
import {SliderSectionItem} from "../SliderSectionItem/SliderSectionItem"
import {Checkbox, FormControlLabel} from "@mui/material"
import styles from "../SliderSection.module.scss"
import {getFilteredSkillsForCcompetence} from "./helpers"

interface ICcompetenceColumn {
    active: boolean
}

export const CcompetenceColumn = ({active}: ICcompetenceColumn) => {
    const swiper = useSwiper()
    const dispatch = useDispatch()
    const ccompetencesSelected = useSelector(selectCcompetencesSelected)
    const skillsSelected = useSelector(selectSkillsSelected)
    const competencesSelected = useSelector(selectCompetencesSelected)
    const ccompetences = competencesSelected.flatMap((competence) => competence.ccompetences)
    const {
        setCcompetencesSelected,
        removeCcompetencesSelected,
        addCcompetencesSelected,
        setSkillsSelected,
    } = researchConclusionActions
    const disableAllButton = ccompetences.length <= 0
    const disableReset = ccompetences.length <= 0 || ccompetencesSelected.length === 0

    const handleSelectAll = () => dispatch(setCcompetencesSelected(ccompetences))

    useEffect(() => {
        if (ccompetencesSelected.length === 0) {
            swiper.slideTo(0, 500)
        }
    }, [ccompetencesSelected])

    const handleReset = () => {
        swiper.slideTo(0, 500)
        dispatch(setCcompetencesSelected([]))
        dispatch(setSkillsSelected([]))
    }

    const checkboxIsChecked = (name: string) =>
        ccompetencesSelected.some((ccompetence) => ccompetence.ccompetence_name === name)

    const handleToggleCheckbox = (e: any, ccompetence: ISliderCcompetence) => {
        if (e.target.checked) {
            dispatch(addCcompetencesSelected(ccompetence))
        } else {
            dispatch(removeCcompetencesSelected(ccompetence))
            if (skillsSelected.length > 0) {
                const filteredSkills = getFilteredSkillsForCcompetence(ccompetence, skillsSelected)
                dispatch(setSkillsSelected(filteredSkills))
            }
        }
    }
    return (
        <SliderSectionItem
            title="Компоненты компетенции"
            active={false}
            disableAllButton={disableAllButton}
            disableReset={disableReset}
            onResetClick={handleReset}
            onSelectAllClick={handleSelectAll}>
            {ccompetences.map((ccompetence) => (
                <FormControlLabel
                    key={ccompetence.ccompetence_id}
                    className={styles.checkbox}
                    label={ccompetence.ccompetence_name}
                    control={
                        <Checkbox
                            size="small"
                            checked={checkboxIsChecked(ccompetence.ccompetence_name)}
                            onClick={(e) => handleToggleCheckbox(e, ccompetence)}
                        />
                    }
                />
            ))}
        </SliderSectionItem>
    )
}
