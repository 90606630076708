import React, {useEffect, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {
    useGetSubjectDataQuery,
    useLazyGetCompetenceDataQuery,
    useLazyGetComponentCompetenceDataQuery,
    useLazyGetSkillDataQuery,
    useLazyGetCSkillDataQuery,
} from "../../../shared/api/endpoints/researchSettingsEndpoints"
import {CustomSelect} from "../../ResearchSettingsPage/ResearchSettingsForm/Select/Select"
import {ButtonWithTooltip} from "../../../shared/components"
import {faTrashCan} from "@fortawesome/free-solid-svg-icons"
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus"
import {
    CipStructureLessonItem,
    MappingResponse,
    mappingSliceActions,
    MappingSliceForm,
    selectMappingForms,
} from "../../../shared/store/mapping"
import {EraMappingTypes} from "../../../shared/store/researchSettings/types"
import styles from "../../ResearchSettingsPage/ResearchSettingsForm/ResearchSettingsForm.module.css"

enum Mapping {
    Course = "course",
    Lesson = "lesson",
    Module = "module",
}

interface MappingFormProps {
    mappingForm: MappingSliceForm
    mapping: EraMappingTypes
    currentMapping: MappingResponse<CipStructureLessonItem> | undefined
}

export const MappingForm = ({mappingForm, mapping, currentMapping}: MappingFormProps) => {
    const {subject_area, competence, ccompetence, skill, cskill, id, valid} = mappingForm
    const didMount = useRef(false)
    const dispatch = useDispatch()
    const mappingForms = useSelector(selectMappingForms)
    const {editFormItem, addForm, removeForm} = mappingSliceActions
    const lastFormId = mappingForms[mappingForms.length - 1].id
    const isLastOrTheOnlyForm = id === lastFormId
    const nextFormId = lastFormId + 1
    const {currentData: currentSubjectData} = useGetSubjectDataQuery()
    const [lazyGetCompetenceData, {currentData: currentCompetenceData}] =
        useLazyGetCompetenceDataQuery()
    const [lazyGetComponentCompetenceData, {currentData: currentComponentCompetenceData}] =
        useLazyGetComponentCompetenceDataQuery()
    const [lazyGetSkillData, {currentData: currentSkillData}] = useLazyGetSkillDataQuery()
    const [lazyGetCSkillData, {currentData: currentCSkillData}] = useLazyGetCSkillDataQuery()

    useEffect(() => {
        const prefillForm = async () => {
            if (currentMapping?.cip_structure?.[id]) {
                const {subject_area, competence, ccompetence, skill, cskill} =
                    currentMapping.cip_structure[id]

                const toAwait = []
                if (subject_area) {
                    toAwait.push(lazyGetCompetenceData(subject_area.id))
                }
                if (competence && mapping !== "course") {
                    toAwait.push(lazyGetComponentCompetenceData(competence.id))
                }
                if (ccompetence && mapping !== "course") {
                    toAwait.push(lazyGetSkillData(ccompetence.id))
                }
                if (skill && mapping === "lesson") {
                    toAwait.push(lazyGetCSkillData(skill.id))
                }

                // TODO maybe add a spinner
                await Promise.all(toAwait)

                if (subject_area) {
                    dispatch(
                        editFormItem({
                            formId: id,
                            field: "subject_area",
                            value: subject_area.id,
                        })
                    )
                }
                if (competence) {
                    dispatch(
                        editFormItem({
                            formId: id,
                            field: "competence",
                            value: competence.id,
                        })
                    )
                }
                if (ccompetence && mapping !== "course") {
                    dispatch(
                        editFormItem({
                            formId: id,
                            field: "ccompetence",
                            value: ccompetence.id,
                        })
                    )
                }
                if (skill && mapping !== "course") {
                    dispatch(
                        editFormItem({
                            formId: id,
                            field: "skill",
                            value: skill.id,
                        })
                    )
                }

                if (cskill && mapping === "lesson") {
                    dispatch(
                        editFormItem({
                            formId: id,
                            field: "cskill",
                            value: cskill.id,
                        })
                    )
                }
            }
        }
        if (didMount.current) prefillForm()
        else didMount.current = true
    }, [currentMapping])

    useEffect(() => {
        let newValidState = undefined
        if (mapping === "course") {
            newValidState = !!subject_area
        } else if (mapping === "module") {
            newValidState = subject_area && competence && ccompetence
        } else if (mapping === "lesson") {
            newValidState = subject_area && competence && ccompetence && skill
        }
        if (newValidState !== valid) {
            dispatch(editFormItem({formId: id, field: "valid", value: newValidState}))
        }
    }, [subject_area, competence, ccompetence, skill, cskill, mapping])

    const handleSubjectData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await lazyGetCompetenceData(e.target.value)
        if (competence || ccompetence || skill || cskill) {
            dispatch(editFormItem({formId: id, field: "competence", value: null}))
            dispatch(editFormItem({formId: id, field: "ccompetence", value: null}))
            dispatch(editFormItem({formId: id, field: "skill", value: null}))
            dispatch(editFormItem({formId: id, field: "cskill", value: null}))
        }
        dispatch(editFormItem({formId: id, field: "subject_area", value: e.target.value}))
    }
    const handleCompetenceData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await lazyGetComponentCompetenceData(e.target.value)
        if (ccompetence || skill || cskill) {
            dispatch(editFormItem({formId: id, field: "ccompetence", value: null}))
            dispatch(editFormItem({formId: id, field: "skill", value: null}))
            dispatch(editFormItem({formId: id, field: "cskill", value: null}))
        }
        dispatch(editFormItem({formId: id, field: "competence", value: e.target.value}))
    }
    const handleComponentCompetenceData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await lazyGetSkillData(e.target.value)
        if (skill || cskill) {
            dispatch(editFormItem({formId: id, field: "skill", value: null}))
            dispatch(editFormItem({formId: id, field: "cskill", value: null}))
        }
        dispatch(editFormItem({formId: id, field: "ccompetence", value: e.target.value}))
    }

    const handleSkillData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (mapping === "lesson") {
            await lazyGetCSkillData(e.target.value)
            if (cskill) {
                dispatch(editFormItem({formId: id, field: "cskill", value: null}))
            }
        }
        dispatch(editFormItem({formId: id, field: "skill", value: e.target.value}))
    }
    const handleCSkillData = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(editFormItem({formId: id, field: "cskill", value: e.target.value}))
    }

    const handleAddForm = () => {
        dispatch(
            addForm({
                id: nextFormId,
                subject_area: null,
                competence: null,
                ccompetence: null,
                skill: null,
                cskill: null,
                valid: false,
            })
        )
    }

    return (
        <div className={styles.wrapper}>
            {mappingForms.length > 1 && (
                <div className={styles.close_wrapper}>
                    {id !== 0 ? <h5>Ещё</h5> : <div></div>}
                    <ButtonWithTooltip
                        onClick={() => dispatch(removeForm(id))}
                        icon={faTrashCan}
                        text="Удалить"
                    />
                </div>
            )}
            <form className={styles.form}>
                <CustomSelect
                    id={`${id}`}
                    onChange={handleSubjectData}
                    value={subject_area || ""}
                    placeholder="Область компетенции"
                    disabled={!currentSubjectData}
                    optionList={currentSubjectData || []}
                />
                <CustomSelect
                    id={`${id}`}
                    onChange={handleCompetenceData}
                    value={competence || ""}
                    placeholder="Компетенция"
                    disabled={!currentCompetenceData || !subject_area}
                    optionList={currentCompetenceData || []}
                />
                {mapping !== Mapping.Course ? (
                    <>
                        <CustomSelect
                            id={`${id}`}
                            onChange={handleComponentCompetenceData}
                            value={ccompetence || ""}
                            placeholder="Компонент компетенции"
                            disabled={!currentComponentCompetenceData || !competence}
                            optionList={currentComponentCompetenceData || []}
                        />
                        <CustomSelect
                            id={`${id}`}
                            onChange={handleSkillData}
                            value={skill || ""}
                            placeholder={"Знание/умение"}
                            disabled={!currentSkillData || !ccompetence}
                            optionList={currentSkillData || []}
                        />
                    </>
                ) : null}
                {mapping === Mapping.Lesson ? (
                    <CustomSelect
                        id={`${id}`}
                        onChange={handleCSkillData}
                        value={cskill || ""}
                        placeholder="Класс умения"
                        disabled={!currentCSkillData || !skill}
                        optionList={currentCSkillData || []}
                    />
                ) : null}
            </form>
            {isLastOrTheOnlyForm && (
                <div style={{paddingTop: "1em", display: "flex", justifyContent: "flex-end"}}>
                    <ButtonWithTooltip onClick={handleAddForm} icon={faPlus} text="Добавить" />
                </div>
            )}
        </div>
    )
}
