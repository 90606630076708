import {PieChartWithCenterText} from "../PieChartWithCenterText/PieChartWithCenterText"
import styles from "./PieChartByUserDeficits.module.scss"

interface IPieChart {
    deficit: number | undefined
    subject_name: string
    subject_competences: {
        id: string
        name: string
    }[]
    width: number
    height: number
    innerRadius: number
    outerRadius: number
    labelFill: string
    withAnimation?: boolean
    cursor?: string
}

export const PieChartByUserDeficits = ({
    deficit,
    subject_name,
    subject_competences,
    width,
    height,
    innerRadius,
    outerRadius,
    labelFill = "#fff",
    withAnimation,
    cursor,
}: IPieChart) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.piechart}>
                    <PieChartWithCenterText
                        deficit={deficit}
                        width={width}
                        height={height}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        labelFill={labelFill}
                        labelClassName={styles.label}
                        withAnimation={withAnimation}
                        cursor={cursor}
                    />
                </div>
                <div className={styles.info}>
                    <h6>{subject_name}</h6>
                    {subject_competences.map((competence) => (
                        <p key={competence.id}>{competence.name}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}
