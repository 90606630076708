// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResearchSettingsPage_subtitle__rcXW9 {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ResearchSettingsPage/ResearchSettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".subtitle {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "ResearchSettingsPage_subtitle__rcXW9"
};
export default ___CSS_LOADER_EXPORT___;
