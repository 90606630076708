import {FC} from "react"
import {ProgressBar} from "../../ProgressBar/ProgressBar"
import DiagnosticCenterIcon from "../diagnosticCenter.jpeg"
import classNames from "classnames"
import styles from "./UnfinishedResearchCard.module.scss"

interface UnfinishedResearchCardProps {
    title: string
    subtitle: string
    cards: number
    institutionName?: string
    expectedFinishTime?: string
    institutionIcon?: string
    checkedSkills: number
    totalCheckedSkills: number
    startTime: string
    onClick: () => void
    finished: boolean
}

export const UnfinishedResearchCard: FC<UnfinishedResearchCardProps> = (props) => {
    const {
        title,
        subtitle,
        cards,
        institutionName,
        expectedFinishTime,
        institutionIcon,
        checkedSkills,
        totalCheckedSkills,
        startTime,
        onClick,
        finished,
    } = props

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.card, {[styles.finished_card]: finished})}
                onClick={onClick}>
                <h5>{title}</h5>
                <h6>{subtitle}</h6>
                {!finished && (
                    <ProgressBar
                        progress={(checkedSkills / totalCheckedSkills) * 100}
                        barHeight="5px"
                        hideTitle
                        hidePercentTitle
                        progressColor={"#ffc107"}
                    />
                )}
                <div className={styles.operator}>
                    <div className={styles.icon}>
                        <img src={institutionIcon || DiagnosticCenterIcon} alt="#" />
                    </div>
                    <div className={styles.info}>
                        <h6>{institutionName || "Центр самодиагностики"}</h6>
                        <p>Дата начала: {startTime}</p>
                        {finished ? (
                            <p>Дата окончания: {expectedFinishTime || "нет"}</p>
                        ) : (
                            <p>Ожидаемая дата окончания: {expectedFinishTime || "нет"}</p>
                        )}
                    </div>
                </div>
                {cards > 1 && <div className={styles.plus}>+{cards - 1}</div>}
            </div>
            {cards > 1 && (
                <div
                    className={classNames(styles.bottom_card, {
                        [styles.finished_bottom_card]: finished,
                    })}
                />
            )}
        </div>
    )
}
