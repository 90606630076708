import {useEffect, useState} from "react"
import {useGetEraDescriptionsQuery} from "../../../shared/api/endpoints/userEndpoints"
import {RecommendationItemWithTooltip} from "../../../widgets"
import {CourseDescriptionResponse, EraCourseModuleOrLessonIdPair} from "./types"
import styles from "./Recommendations.module.scss"

interface RecommendationsProps {
    eraCoursesModulesLessonsMeta: EraCourseModuleOrLessonIdPair[]
    forDashboardPage?: boolean
}
interface SlicedRecommendationsProps {
    eraDescription: CourseDescriptionResponse[]
}

const SlicedRecommendations = ({eraDescription}: SlicedRecommendationsProps) => {
    let itemsToShow: number = 6
    if (eraDescription[0].content_type === "subject") {
        itemsToShow = 6
    } else if (eraDescription[0].content_type === "module") {
        itemsToShow = 3
    } else if (eraDescription[0].content_type === "lesson") {
        itemsToShow = 6
    }
    const [nowShowing, setNowShowing] = useState<number>(itemsToShow)
    const [showShowMoreButton, setShowShowMoreButton] = useState<boolean>(false)

    useEffect(() => {
        if (eraDescription.length > nowShowing) {
            setShowShowMoreButton(true)
        } else {
            setShowShowMoreButton(false)
        }
    }, [eraDescription.length, nowShowing])

    const handleShowMore = () => {
        setNowShowing((prevState) => prevState + itemsToShow)
    }

    const slicedEraDescription = eraDescription?.slice(0, nowShowing)
    return (
        <>
            <div className={styles.recommendations}>
                {slicedEraDescription?.map((recommendation, index) => (
                    <RecommendationItemWithTooltip
                        key={index}
                        className={styles[`recommendation_${recommendation.content_type}`]}
                        cardClassName={styles[`card_${recommendation.content_type}`]}
                        recommendation={recommendation}
                        tooltipType={recommendation.content_type}
                        tooltipId={recommendation.id}
                    />
                ))}
            </div>
            {showShowMoreButton && (
                <div className={styles.show_more_wrapper}>
                    <button type={"button"} className={styles.show_more} onClick={handleShowMore}>
                        Показать больше
                    </button>
                </div>
            )}
        </>
    )
}

interface MainPageRecommendationsListProps {
    eraCoursesModulesLessonsMeta: EraCourseModuleOrLessonIdPair[]
}

export const MainPageRecommendationsList = ({
    eraCoursesModulesLessonsMeta,
}: MainPageRecommendationsListProps) => {
    const joinedIds = eraCoursesModulesLessonsMeta.map(({id}) => id).join("|")
    // step 3 - request information for course/module/lesson cards (images, description, etc...)
    const {currentData: eraDescription} = useGetEraDescriptionsQuery({
        // @ts-ignore
        by: eraCoursesModulesLessonsMeta[0].content_type + "s",
        ids: joinedIds,
    })
    const [chunk, setChunk] = useState<number>(5)
    useEffect(() => {
        const listener = () => {
            if (window.innerWidth > 1600) {
                setChunk(5)
            } else if (window.innerWidth > 1450) {
                setChunk(8)
            } else if (window.innerWidth > 1230) {
                setChunk(6)
            } else if (window.innerWidth > 1005) {
                setChunk(4)
            } else if (window.innerWidth > 530) {
                setChunk(4)
            }
        }
        window.addEventListener("resize", listener)
        return () => {
            window.removeEventListener("resize", listener)
        }
    }, [])

    return (
        <div className={styles.recommendations}>
            {eraDescription?.slice(0, chunk).map((recommendation, index) => (
                <RecommendationItemWithTooltip
                    key={index}
                    className={styles.recommendation}
                    recommendation={recommendation}
                    tooltipType={recommendation.content_type}
                    tooltipId={recommendation.id}
                />
            ))}
        </div>
    )
}

const Recommendations = ({
    eraCoursesModulesLessonsMeta,
    forDashboardPage,
}: RecommendationsProps) => {
    const joinedIds = eraCoursesModulesLessonsMeta.map(({id}) => id).join("|")
    // step 3 - request information for course/module/lesson cards (images, description, etc...)
    const {currentData: eraDescription} = useGetEraDescriptionsQuery({
        // @ts-ignore
        by: eraCoursesModulesLessonsMeta[0].content_type + "s",
        ids: joinedIds,
    })
    return forDashboardPage && eraDescription ? (
        <SlicedRecommendations eraDescription={eraDescription}></SlicedRecommendations>
    ) : (
        <div className={styles.recommendations}>
            {eraDescription?.map((recommendation, index) => (
                <RecommendationItemWithTooltip
                    key={index}
                    className={styles.recommendation}
                    recommendation={recommendation}
                    tooltipType={recommendation.content_type}
                    tooltipId={recommendation.id}
                />
            ))}
        </div>
    )
}

export default Recommendations
