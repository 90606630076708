import styles from "./HistorySectionItem.module.css"
import {Paper} from "../../../../../shared/components"
import {IConclusionHistoryResponse} from "../../../../../shared/store/researchSettings/types"

interface IHistorySectionItem {
    index: number
    task: IConclusionHistoryResponse
}

interface IPaperItem {
    title: string
    subtitle: string
    knowledge?: string
    background?: string
    color?: string
}

const PaperItem = ({title, subtitle, knowledge, background, color}: IPaperItem) => {
    return (
        <Paper background={background} color={color}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            {knowledge ? <div className={styles.knowledge}>Знание/Умение: {knowledge}</div> : null}
        </Paper>
    )
}

export const HistorySectionItem = ({index, task}: IHistorySectionItem) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.task}>Задание {index}</div>
            <PaperItem
                title="Вопрос"
                subtitle={task.question.content_html}
                knowledge={task.question.skill}
            />
            <PaperItem title="Ответ" subtitle={task.answer.content_html} background="#ffffff8c" />
            {task.comment_html && (
                <PaperItem
                    title="Комментарий"
                    subtitle={task.comment_html}
                    background="#2c3d6e"
                    color="#ffffff"
                />
            )}
        </div>
    )
}
