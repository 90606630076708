import {
    ISliderCcompetence,
    ISliderCompetence,
    ISliderSkills,
    ISliderSubject,
} from "../../../../../shared/store/researchConslusion"

export const getFilteredSkillsForCcompetence = (
    ccompetence: ISliderCcompetence,
    skillsSelected: ISliderSkills[]
) => {
    const uncheckedSkills = ccompetence.skills.map((skill) => skill.skill_name)
    return skillsSelected.filter((skill) => !uncheckedSkills.includes(skill.skill_name))
}

export const getFilteredSkillsForCompetence = (
    competence: ISliderCompetence,
    skillsSelected: ISliderSkills[]
) => {
    const uncheckedSkills = competence.ccompetences.flatMap((ccompetence) =>
        ccompetence.skills.map((skill) => skill.skill_name)
    )
    return skillsSelected.filter((skill) => !uncheckedSkills.includes(skill.skill_name))
}

export const getFilteredCcompetenceForCompetence = (
    competence: ISliderCompetence,
    ccompetencesSelected: ISliderCcompetence[]
) => {
    const uncheckedCcompetences = competence.ccompetences.map(
        (ccompetence) => ccompetence.ccompetence_name
    )
    return ccompetencesSelected.filter(
        (ccompetence) => !uncheckedCcompetences.includes(ccompetence.ccompetence_name)
    )
}

export const getFilteredSkillsForSubject = (
    subject: ISliderSubject,
    skillsSelected: ISliderSkills[]
) => {
    const uncheckedSkills = subject.competences.flatMap((competence) =>
        competence.ccompetences.flatMap((ccompetence) =>
            ccompetence.skills.map((skill) => skill.skill_name)
        )
    )
    return skillsSelected.filter((skill) => !uncheckedSkills.includes(skill.skill_name))
}

export const getFilteredCcompetenceForSubject = (
    subject: ISliderSubject,
    ccompetencesSelected: ISliderCcompetence[]
) => {
    const uncheckedCcompetences = subject.competences.flatMap((competence) =>
        competence.ccompetences.map((ccompetence) => ccompetence.ccompetence_name)
    )
    return ccompetencesSelected.filter(
        (ccompetence) => !uncheckedCcompetences.includes(ccompetence.ccompetence_name)
    )
}

export const getFilteredCompetenceForSubject = (
    subject: ISliderSubject,
    competencesSelected: ISliderCompetence[]
) => {
    const uncheckedCompetences = subject.competences.map((competence) => competence.competence_name)
    return competencesSelected.filter(
        (competence) => !uncheckedCompetences.includes(competence.competence_name)
    )
}
