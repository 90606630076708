import {useEffect, useState} from "react"
import {useGetEraProfileQuery} from "../../../shared/api/endpoints/userEndpoints"

export const useSubscriptionDialog = () => {
    const {currentData: profile} = useGetEraProfileQuery()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (profile) {
            setOpen(!profile.account.subscriptionActive)
        }
    }, [profile])

    return {open, setOpen}
}
