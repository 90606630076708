import {ReactNode} from "react"
import {NavLink} from "react-router-dom"
import classNames from "classnames"
import styles from "./Navbar.module.scss"

interface NavbarMenuItemWithTooltipProps {
    to: string
    tooltip: string
    children: ReactNode
}
export const NavbarMenuItemWithTooltip = ({
    to,
    tooltip,
    children,
}: NavbarMenuItemWithTooltipProps) => {
    return (
        <NavLink
            end
            to={to}
            className={({isActive, isPending}) =>
                classNames(styles.menu_item, {[styles.active]: isActive})
            }>
            {children}
            <div className={styles.tooltip}>{tooltip}</div>
        </NavLink>
    )
}
