import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {AssignOrClearMappingButtons} from "./AssignMappingButton/AssignMappingButton"
import {Modal} from "../../shared/components/Modal/Modal"
import {useLocation} from "react-router-dom"
import {Box, Checkbox, FormControlLabel} from "@mui/material"
import {EraMappingTypes} from "../../shared/store/researchSettings/types"
import {MappingForm} from "./MappingForm/MappingForm"
import {
    CipStructureLessonItem,
    MappingComplexity,
    MappingResponse,
    mappingSliceActions,
    selectMappingComplexity,
    selectMappingForms,
} from "../../shared/store/mapping"
import {
    useLazyGetMappingQuery,
    useLazyGetMappingRecommendationByTypeQuery,
} from "../../shared/api/endpoints/userEndpoints"
import styles from "./MappingPage.module.scss"

export const MappingPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const mappingForms = useSelector(selectMappingForms)
    const mappingComplexity = useSelector(selectMappingComplexity)
    const {setForms, changeComplexity, setComplexity} = mappingSliceActions

    // TODO move them to path args
    const query = new URLSearchParams(location.search)
    const mappingType = query.get("mapping") as EraMappingTypes | null
    const id = query.get("id")

    const hasMappingAndIdParam = !!(mappingType && id)
    const [lazyGetMapping] = useLazyGetMappingQuery()
    const [lazyGetMappingRecommendation] = useLazyGetMappingRecommendationByTypeQuery()
    const [currentMapping, setCurrentMapping] = useState<MappingResponse<CipStructureLessonItem>>()

    useEffect(() => {
        const getInitialFormsState = async () => {
            const {data: usedMapping} = await lazyGetMapping({
                by: mappingType as EraMappingTypes,
                id: id as string,
            })
            const {data: recommendedMapping} = await lazyGetMappingRecommendation({
                by: mappingType as Exclude<EraMappingTypes, "course">,
                id: id as string,
            })
            if (usedMapping && recommendedMapping) {
                setCurrentMapping(usedMapping)
                dispatch(
                    setComplexity({
                        easy: usedMapping.is_easy,
                        medium: usedMapping.is_medium,
                        hard: usedMapping.is_hard,
                    })
                )
                let formsCount = usedMapping.cip_structure.length
                if (formsCount > 1) {
                    dispatch(
                        setForms(
                            usedMapping.cip_structure.map((form, index) => {
                                return {
                                    id: index,
                                    subject_area: null,
                                    competence: null,
                                    ccompetence: null,
                                    skill: null,
                                    cskill: null,
                                    valid: false,
                                }
                            })
                        )
                    )
                }
            } else if (!usedMapping && recommendedMapping) {
                setCurrentMapping(recommendedMapping)
                dispatch(
                    setComplexity({
                        easy: recommendedMapping.is_easy,
                        medium: recommendedMapping.is_medium,
                        hard: recommendedMapping.is_hard,
                    })
                )
                let formsCount = recommendedMapping.cip_structure.length
                if (formsCount > 1) {
                    dispatch(
                        setForms(
                            recommendedMapping.cip_structure.map((form, index) => {
                                return {
                                    id: index,
                                    subject_area: null,
                                    competence: null,
                                    ccompetence: null,
                                    skill: null,
                                    cskill: null,
                                    valid: false,
                                }
                            })
                        )
                    )
                }
            } else if (usedMapping && !recommendedMapping) {
                setCurrentMapping(usedMapping)
                dispatch(
                    setComplexity({
                        easy: usedMapping.is_easy,
                        medium: usedMapping.is_medium,
                        hard: usedMapping.is_hard,
                    })
                )
                let formsCount = usedMapping.cip_structure.length
                if (formsCount > 1) {
                    dispatch(
                        setForms(
                            usedMapping.cip_structure.map((form, index) => {
                                return {
                                    id: index,
                                    subject_area: null,
                                    competence: null,
                                    ccompetence: null,
                                    skill: null,
                                    cskill: null,
                                    valid: false,
                                }
                            })
                        )
                    )
                }
            }
        }
        getInitialFormsState()
    }, [])

    const handleComplexityCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        let isEasyAndHardChecked = false
        if (
            e.target.value === "easy" &&
            !mappingComplexity.easy &&
            mappingComplexity.hard &&
            !mappingComplexity.medium
        ) {
            isEasyAndHardChecked = true
        }
        if (
            e.target.value === "hard" &&
            mappingComplexity.easy &&
            !mappingComplexity.hard &&
            !mappingComplexity.medium
        ) {
            isEasyAndHardChecked = true
        }
        dispatch(changeComplexity(e.target.value as keyof MappingComplexity))
        if (isEasyAndHardChecked) dispatch(changeComplexity("medium"))
    }

    return (
        <Modal modalTitle="Параметры" hideCloseIcon className={styles.modal}>
            {hasMappingAndIdParam
                ? mappingForms.map((mappingForm) => (
                      <MappingForm
                          key={mappingForm.id}
                          mappingForm={mappingForm}
                          mapping={mappingType}
                          currentMapping={currentMapping}
                      />
                  ))
                : null}
            <Box component="fieldset" className={styles.checkbox_box}>
                <legend className={styles.checkbox_box_legend}>Уровень освоения</legend>
                <div className={styles.checkbox_wrapper}>
                    <FormControlLabel
                        control={<Checkbox onChange={handleComplexityCheckbox} />}
                        label="НАЧАЛЬНЫЙ"
                        value="easy"
                        checked={mappingComplexity.easy}
                    />
                    <FormControlLabel
                        control={<Checkbox onChange={handleComplexityCheckbox} />}
                        label="СРЕДНИЙ"
                        value="medium"
                        checked={mappingComplexity.medium}
                    />
                    <FormControlLabel
                        control={<Checkbox onChange={handleComplexityCheckbox} />}
                        label="УГЛУБЛЕННЫЙ"
                        value="hard"
                        checked={mappingComplexity.hard}
                    />
                </div>
            </Box>
            {hasMappingAndIdParam ? (
                <AssignOrClearMappingButtons
                    mappingComplexity={mappingComplexity}
                    mappingForms={mappingForms}
                    mappingType={mappingType}
                    id={id}
                />
            ) : null}
        </Modal>
    )
}
