import axios from "axios"
import AccessToken from "./AccessToken"

// export const API_URL = "http://localhost:8000/" // for local tests
export const API_URL = "https://erascope.com/"
// export const API_URL = "http://localhost:8080/"
// export const API_URL = "http://192.168.0.124:8001/" // for test

const HttpApi = axios.create({
    baseURL: API_URL,
})

HttpApi.interceptors.request.use((config) => {
    config.headers.Authorization = `bearer ${AccessToken()}`
    config.headers.Accept = "application/json"
    return config
})

export default HttpApi
