import {apiEndpoints} from "./apiEndpoints"
import {
    URL_GET_SUBJECTS_DATA,
    URL_GET_COMPETENCE_DATA_FMT,
    URL_GET_CCOMPETENCE_DATA_FMT,
    URL_GET_SKILL_DATA_FMT,
    URL_POST_FORM_DATA,
    URL_POST_ASSIGN_EXAM,
    URL_GET_CSKILL_DATA_FMT,
} from "../requestUrl"
import {IdNameObj, IResult, IFormResponse, IFormRequest} from "../../store/researchSettings/types"
import {AssignExamRequest, AssignExamResponse} from "../../../pages/AssignExamPage/types"

export const researchSettingsEndpoints = apiEndpoints.injectEndpoints({
    endpoints: (build) => ({
        getSubjectData: build.query<IdNameObj[], void>({
            query: () => ({
                url: URL_GET_SUBJECTS_DATA,
                method: "GET",
            }),
        }),
        getCompetenceData: build.query<IdNameObj[], string>({
            query: (subjectId) => ({
                url: URL_GET_COMPETENCE_DATA_FMT(subjectId),
                method: "GET",
            }),
        }),
        getComponentCompetenceData: build.query<IdNameObj[], string>({
            query: (competenceId) => ({
                url: URL_GET_CCOMPETENCE_DATA_FMT(competenceId),
                method: "GET",
            }),
        }),
        getSkillData: build.query<IdNameObj[], string>({
            query: (ccompetenceId) => ({
                url: URL_GET_SKILL_DATA_FMT(ccompetenceId),
                method: "GET",
            }),
        }),
        getCSkillData: build.query<IdNameObj[], string>({
            query: (skillId) => ({
                url: URL_GET_CSKILL_DATA_FMT(skillId),
                method: "GET",
            }),
        }),
        startTest: build.mutation<IResult<IFormResponse>, {forms: IFormRequest[]}>({
            query: (forms) => ({
                url: URL_POST_FORM_DATA,
                method: "POST",
                body: forms,
            }),
        }),
        assignExam: build.mutation<IResult<AssignExamResponse>, AssignExamRequest>({
            query: (body) => ({
                url: URL_POST_ASSIGN_EXAM,
                method: "POST",
                body: body,
            }),
        }),
    }),
})

export const {
    useGetSubjectDataQuery,
    useLazyGetCompetenceDataQuery,
    useLazyGetComponentCompetenceDataQuery,
    useLazyGetSkillDataQuery,
    useLazyGetCSkillDataQuery,
    useStartTestMutation,
    useAssignExamMutation,
} = researchSettingsEndpoints
