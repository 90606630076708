function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}
export function getRandomColor() {
    let h = randomInt(0, 360)
    let s = randomInt(42, 98)
    let l = randomInt(40, 90)
    return `hsl(${h},${s}%,${l}%)`
}

export const getMockedRandomColor = (index: number) => {
    const colors = [
        "#0026a870",
        "#8c000070",
        "#5ba60070",
        "#ff830070",
        "#ab8c0070",
        "#0095a870",
        "#49009670",
        "#4d000070",
        "#a6590070",
        "#ffcb0070",
        "#27421770",
        "#18505770",
        "#5675f670",
        "#8000f870",
        "#ff030370",
        "#3f301f70",
        "#f8cf0070",
        "#88f80070",
        "#00dbf870",
        "#00000070",
        "#d255f570",
    ]
    if (index < colors.length) {
        return colors[index]
    } else {
        return colors[index % colors.length]
    }
}
