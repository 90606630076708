import {ProgressBar} from "../index"
import {PieChartWithCenterText} from "../index"
import styles from "./DeficitChart.module.css"

interface IDeficitChart {
    withProgressBars: boolean
    subjectDeficit: number | undefined
    subjectTitle: string | undefined
    modules: any[] | undefined
}

export const DeficitChart = ({
    withProgressBars,
    subjectDeficit,
    subjectTitle,
    modules,
}: IDeficitChart) => {
    return (
        <>
            <h2 className={styles.subject}>{subjectTitle}</h2>
            <h3 className={styles.title}>Основной дефицит обучения:</h3>
            <div className={styles.chart__wrapper}>
                <div className={styles.pie__wrapper}>
                    <PieChartWithCenterText deficit={subjectDeficit} />
                </div>
                {withProgressBars && (
                    <ul className={styles.details__statistic}>
                        {modules?.map((module: any) => (
                            <li className={styles.deficit__title} key={module.moduleTitle}>
                                <ProgressBar
                                    progress={module.moduleDeficit}
                                    title={module.moduleTitle}
                                    progressColor="#d3d3d3"
                                    barHeight={"45px"}
                                    titleFontSize={"16px"}
                                    titleFontWeight={600}
                                    titleWidth={"130px"}
                                />
                                <ul>
                                    {module?.lessons?.map((lesson: any) => (
                                        <li
                                            className={styles.deficit__list__item}
                                            key={lesson.title}>
                                            <ProgressBar
                                                progress={lesson.deficit}
                                                title={lesson.title}
                                                barHeight={"25px"}
                                                titleFontSize={"14px"}
                                                titleWidth={"130px"}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    )
}
