import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {MappingComplexity, MappingSlice, MappingSliceForm} from "./types"

const initialState: MappingSlice = {
    mappingForms: [
        {
            id: 0,
            subject_area: null,
            competence: null,
            ccompetence: null,
            skill: null,
            cskill: null,
            valid: false,
        },
    ],
    complexity: {
        easy: false,
        medium: false,
        hard: false,
    },
}

export const mappingSlice = createSlice({
    name: "mapping",
    initialState,
    reducers: {
        addForm: (state, action: PayloadAction<MappingSliceForm>) => {
            state.mappingForms.push(action.payload)
        },
        setForms: (state, action: PayloadAction<MappingSliceForm[]>) => {
            state.mappingForms = action.payload
        },
        removeForm: (state, action: PayloadAction<number>) => {
            state.mappingForms = state.mappingForms.filter((form) => form.id !== action.payload)
        },
        resetForms: (state) => {
            state.mappingForms = [
                {
                    id: 0,
                    subject_area: null,
                    competence: null,
                    ccompetence: null,
                    skill: null,
                    cskill: null,
                    valid: false,
                },
            ]
            state.complexity = {
                easy: false,
                medium: false,
                hard: false,
            }
        },
        editFormItem: (
            state,
            action: PayloadAction<{formId: number; field: keyof MappingSliceForm; value: any}>
        ) => {
            state.mappingForms = state.mappingForms.map((form) =>
                form.id === action.payload.formId
                    ? {...form, [action.payload.field]: action.payload.value}
                    : form
            )
        },
        changeComplexity: (state, action: PayloadAction<keyof MappingComplexity>) => {
            state.complexity = {
                ...state.complexity,
                [action.payload]: !state.complexity[action.payload],
            }
        },
        setComplexity: (state, action: PayloadAction<MappingComplexity>) => {
            state.complexity = action.payload
        },
    },
})

export const {actions: mappingSliceActions} = mappingSlice
export const {reducer: mappingSliceReducer} = mappingSlice
