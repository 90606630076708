import {MainPage} from "./pages/MainPage/MainPage"
import {CipDashboardPage} from "./pages/CipDashboardPage/CipDashboardPage"
import {Routes, Route, Navigate} from "react-router-dom"
import {ResearchPage} from "./pages/ResearchPage/ResearchPage"
import ResearchSettingsPage from "./pages/ResearchSettingsPage/ResearchSettingsPage"
import {ResearchConclusionPage} from "./pages/ResearchConclusionPage/ResearchConclusionPage"
import {AssignExamPage} from "./pages/AssignExamPage/AssignExamPage"
import {MappingPage} from "./pages/MappingPage/MappingPage"
import {EraResearchResult} from "./pages/EraResearchResult/EraResearchResult"
import {Navbar} from "./widgets"
import {ProtectedRoute} from "./shared/components/ProtectedRoute/ProtectedRoute"
import {LoginNavbar} from "./widgets/Navbar/Navbar"
import "./App.css"

function App() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/cipui" />} />
            <Route
                path="/cipui/login"
                element={
                    <LoginNavbar>
                        <MainPage forLoginPage />
                    </LoginNavbar>
                }
            />
            <Route element={<ProtectedRoute />}>
                <Route
                    path="/cipui"
                    index
                    element={
                        <Navbar>
                            <MainPage />
                        </Navbar>
                    }
                />
                <Route
                    path="/cipui/cip_dashboard"
                    element={
                        <Navbar>
                            <CipDashboardPage />
                        </Navbar>
                    }>
                    <Route path="research_settings_page" element={<ResearchSettingsPage />} />
                    <Route path="research_page/:sessionId" element={<ResearchPage />} />
                    <Route
                        path="research_page/:sessionId/conclusion"
                        element={<ResearchConclusionPage />}
                    />
                </Route>
                <Route path="*" element={<MainPage />} />
            </Route>
            <Route path="/cipui/assign_exam" element={<AssignExamPage />} />
            <Route path="/cipui/mapping" element={<MappingPage />} />
            <Route path="/cipui/research_page/:sessionId" element={<ResearchPage iframe />} />
            <Route
                path="/cipui/research_page/:sessionId/conclusion"
                element={<ResearchConclusionPage iframe />}
            />
            <Route path="/cipui/era_research_result" element={<EraResearchResult />} />
        </Routes>
    )
}

export default App
