import {ReactNode, useEffect} from "react"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import styles from "./Modal.module.scss"

interface ModalProps {
    modalTitle: string
    onClose?: () => void
    hideCloseIcon?: boolean
    children: ReactNode
    className?: string
    id?: string
}

export const Modal = (props: ModalProps) => {
    const {modalTitle, onClose, hideCloseIcon = false, children, className, id} = props

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.overflow = "visible"
        }
    }, [])

    return (
        <div className={classNames(styles.overlay, className)} id={id}>
            <div className={styles.modal}>
                {!hideCloseIcon && (
                    <div className={styles.close} onClick={onClose}>
                        <FontAwesomeIcon size="xl" icon={faXmark} />
                    </div>
                )}
                <h2 className={styles.title}>{modalTitle}</h2>
                {children}
            </div>
        </div>
    )
}
