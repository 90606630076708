// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CipDashboardPage_container__7Qhqv {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: #444;\n  min-height: 100vh;\n  padding: 10px;\n}\n\n.CipDashboardPage_button__TS\\+62 {\n  width: 100%;\n  height: 80px;\n  background-color: #444;\n  color: #fff;\n  text-align: center;\n  text-transform: uppercase;\n  font-weight: bold;\n  padding: 20px;\n  border: #d3d3d3 3px solid;\n  font-size: 18px;\n  cursor: pointer;\n}\n.CipDashboardPage_button__TS\\+62:hover {\n  background-color: #d3d3d3;\n  color: #444;\n  border: #444 3px solid;\n}\n.CipDashboardPage_button__TS\\+62 svg {\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/pages/CipDashboardPage/CipDashboardPage.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,sBAAA;EACA,8BAAA;EACC,sBAAA;EACA,iBAAA;EACA,aAAA;AACJ;;AAEA;EACE,WAAA;EACA,YAAA;EAEA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,eAAA;AAAF;AACE;EACD,yBAAA;EACA,WAAA;EACA,sBAAA;AACD;AACE;EACD,kBAAA;AACD","sourcesContent":[".container {\n  \tdisplay: flex;\n  \tflex-direction: column;\n  \tjustify-content: space-between;\n    background-color: #444;\n    min-height: 100vh;\n    padding: 10px;\n}\n\n.button {\n  width: 100%;\n  height: 80px;\n  //margin-top: 10px;\n  background-color: #444;\n  color: #fff;\n  text-align: center;\n  text-transform: uppercase;\n  font-weight: bold;\n  padding: 20px;\n  border: #d3d3d3 3px solid;\n  font-size: 18px;\n  cursor: pointer;\n  &:hover {\n\tbackground-color: #d3d3d3;\n\tcolor: #444;\n\tborder: #444 3px solid;\n  }\n  svg {\n\tmargin-right: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CipDashboardPage_container__7Qhqv",
	"button": "CipDashboardPage_button__TS+62"
};
export default ___CSS_LOADER_EXPORT___;
