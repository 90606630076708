import {useLocation, useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"
import {selectAllFormsValid, selectForms} from "../../../shared/store/researchSettings"
import {useAssignExamMutation} from "../../../shared/api/endpoints/researchSettingsEndpoints"
import {IFormRequest} from "../../../shared/store/researchSettings/types"
import {Button} from "@mui/material"
import {AssignExamGradeSet, AssignExamRequest} from "../types"
import {Dayjs} from "dayjs"

interface AssignExamButtonProps {
    startTime: Dayjs
    finishTime: Dayjs
}

export const AssignExamButton = (props: AssignExamButtonProps) => {
    const location = useLocation()
    const {startTime, finishTime} = props
    const navigate = useNavigate()
    const forms = useSelector(selectForms)
    const allFormsValid = useSelector(selectAllFormsValid)
    const [assignExam] = useAssignExamMutation()
    const query = new URLSearchParams(location.search)
    const gradeSetId = query.get("gradeSetId")
    const teacherId = query.get("teacherId")
    const isIndividual = query.get("isIndividual")
    const users = query.get("users")

    const handleAssignExam = async () => {
        const data: IFormRequest[] = forms.map(({competence, ccompetence, skill, complexity}) => ({
            competence: {id: competence},
            ccompetence: {id: ccompetence},
            skill: {id: skill},
            complexity,
        }))
        const requestData = {
            forms: data,
            grade_set: {
                start_datetime: startTime.toISOString(),
                finish_datetime: finishTime.toISOString(),
                era_grade_set_id: gradeSetId,
                era_teacher_id: teacherId,
                is_individual: isIndividual === "true",
            },
            users: users?.split(","),
        }
        const result: any = await assignExam(requestData as AssignExamRequest)
        // result type = AssignExamGradeSet
        if (result.data.grade_set) {
            // eslint-disable-next-line no-restricted-globals
            parent.postMessage("closed", "*")
        }
    }

    return (
        <Button
            size="large"
            disabled={!allFormsValid}
            variant="contained"
            color={"secondary"}
            onClick={handleAssignExam}
            fullWidth>
            НАЗНАЧИТЬ ДИАГНОСТИКУ
        </Button>
    )
}
