import {PieChart, Cell, Label, Pie, Tooltip} from "recharts"
import styles from "./PieChartWithCenterText.module.scss"
import classNames from "classnames"

interface CustomLabelProps {
    labelWidth?: number
    deficit: number | undefined
}

const CustomLabel = ({labelWidth, deficit}: CustomLabelProps) => {
    return (
        <div
            className={styles.label_wrapper}
            style={{width: `${labelWidth}px`, height: `${labelWidth}px`}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div className={styles.label_title}>Дефицит</div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className={styles.label_percent}>
                        {deficit || 0}
                        <span className={styles.label_span}>%</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface IPieChart {
    deficit: number | undefined
    width?: number
    height?: number
    innerRadius?: number
    outerRadius?: number
    labelFill?: string
    labelClassName?: string
    withAnimation?: boolean
    withCustomLabel?: boolean
    labelWidth?: number
    cursor?: string
}

export const PieChartWithCenterText = ({
    deficit,
    width = 250,
    height = 250,
    innerRadius = 80,
    outerRadius = 125,
    labelFill = "#444",
    labelClassName,
    withAnimation = false,
    withCustomLabel = false,
    labelWidth,
    cursor = "pointer",
}: IPieChart) => {
    const data = [
        {
            name: "Дефицит",
            value: deficit,
            color: "#ff6666",
        },
        {
            name: "Уровень обученности",
            value: 100 - Number(deficit),
            color: "#67e46a",
        },
    ]
    return (
        <div style={{position: "relative"}}>
            <PieChart width={width} height={height} style={{cursor: cursor}}>
                <Pie
                    data={data}
                    dataKey="value"
                    innerRadius={0}
                    outerRadius={innerRadius}
                    fill="#ffffff"
                    startAngle={90}
                    endAngle={-270}
                    isAnimationActive={withAnimation}>
                    {!withCustomLabel && (
                        <Label
                            fill={labelFill}
                            value={(deficit || 0) + "%"}
                            position="center"
                            className={classNames(styles.label, labelClassName)}
                        />
                    )}
                </Pie>
                <Pie
                    data={data}
                    dataKey="value"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    startAngle={90}
                    endAngle={-270}
                    isAnimationActive={withAnimation}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} strokeWidth={0} />
                    ))}
                </Pie>
                <Tooltip wrapperStyle={{zIndex: 10}} />
            </PieChart>
            {withCustomLabel && <CustomLabel labelWidth={labelWidth} deficit={deficit} />}
        </div>
    )
}
