import {useEffect} from "react"
import {Slider, CurrentLessonsWidget, Footer, MainPageRecommendations} from "../../widgets"
import styles from "./MainPage.module.scss"

interface MainPageProps {
    forLoginPage?: boolean
}

export const MainPage = ({forLoginPage}: MainPageProps) => {
    useEffect(() => {
        document.title = "ЭРА-СКОП: Домашняя страница"
    }, [])

    return (
        <div className={styles.container}>
            <div>
                <Slider />
                {!forLoginPage && <MainPageRecommendations />}
                {!forLoginPage && <CurrentLessonsWidget />}
            </div>
            <Footer />
        </div>
    )
}
