import {useEffect, useMemo, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCompass} from "@fortawesome/free-solid-svg-icons"
import Recommendations from "../../pages/MainPage/Recommendations/Recommendations"
import {EraCourseModuleOrLessonIdPair} from "../../pages/MainPage/Recommendations/types"
import {ChartData} from "../UserDeficits/types"
import {useLazyGetDescriptionIdsQuery} from "../../shared/api/endpoints/userEndpoints"
import styles from "./DashboardRecommendations.module.scss"

interface DashboardRecommendationsProps {
    sortedUserDeficits: ChartData[] | undefined
}

export const DashboardRecommendations = ({sortedUserDeficits}: DashboardRecommendationsProps) => {
    const [lazyGetDescriptionIdsQuery] = useLazyGetDescriptionIdsQuery()
    const [mainUserDeficit, setMainUserDeficit] = useState<ChartData | undefined>()
    const [coursesList, setCoursesList] = useState<EraCourseModuleOrLessonIdPair[]>([])
    const [modulesList, setModulesList] = useState<EraCourseModuleOrLessonIdPair[]>([])
    const [lessonsList, setLessonsList] = useState<EraCourseModuleOrLessonIdPair[]>([])

    const recommendationsList = useMemo(
        () => [
            {item: coursesList, name: "Программы / Курсы"},
            {item: modulesList, name: "Разделы / Модули"},
            {item: lessonsList, name: "Уроки / Занятия"},
        ],
        [coursesList, modulesList, lessonsList]
    )

    useEffect(() => {
        const getDashboardRecommendations = async () => {
            if (sortedUserDeficits && sortedUserDeficits.length > 0) {
                const theMainUserDeficit = sortedUserDeficits[0]
                setMainUserDeficit(theMainUserDeficit)
                const [{data: subjectIds}, {data: moduleIds}, {data: lessonIds}] =
                    await Promise.all([
                        lazyGetDescriptionIdsQuery({
                            by: "subjects",
                            query: theMainUserDeficit.query,
                        }),
                        lazyGetDescriptionIdsQuery({
                            by: "modules",
                            query: theMainUserDeficit.query,
                            isDeep: true,
                        }),
                        lazyGetDescriptionIdsQuery({
                            by: "lessons",
                            query: theMainUserDeficit.query,
                            isDeep: true,
                        }),
                    ])
                if (subjectIds) setCoursesList(subjectIds.results)
                if (moduleIds) setModulesList(moduleIds.results)
                if (lessonIds) setLessonsList(lessonIds.results)
            }
        }
        getDashboardRecommendations()
    }, [sortedUserDeficits])

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h2>Рекомендации</h2>
                <FontAwesomeIcon icon={faCompass} />
            </div>
            {mainUserDeficit ? (
                <div className={styles.header}>
                    <h4>Область компетенций</h4>
                    <h1>{mainUserDeficit.main_component.name}</h1>
                    <p>Освоена на {100 - mainUserDeficit.main_component.deficit}%</p>
                </div>
            ) : (
                <div style={{marginTop: "30px"}}>
                    Для получения рекоммендаций, пройдите свою первую диагностику
                </div>
            )}
            {recommendationsList.map(({item, name}) =>
                item.length > 0 ? (
                    <div key={name} className={styles.cards}>
                        <h3>{name}</h3>
                        <Recommendations
                            eraCoursesModulesLessonsMeta={item}
                            forDashboardPage={true}
                        />
                    </div>
                ) : null
            )}
        </div>
    )
}
