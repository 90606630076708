import {TooltipProps, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip} from "recharts"
import {ValueType, NameType} from "recharts/types/component/DefaultTooltipContent"
import {IConclusionChartResponse} from "../../store/researchSettings/types"

function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function randomColor() {
    var h = randomInt(0, 360)
    var s = randomInt(42, 98)
    var l = randomInt(40, 90)
    return `hsl(${h},${s}%,${l}%)`
}

function stripedColor(index: number) {
    switch (index % 2) {
        case 0:
            return "#8884d8"
        default:
            return "#82ca9d"
    }
}

function trimLongNames(input: string, maxLength: number = 42) {
    const trimmedInput = input.trim()
    if (trimmedInput.length <= maxLength) {
        return trimmedInput
    }
    return input.substring(0, maxLength - 1) + "…"
}

interface IConclusionBarChart {
    chartData: IConclusionChartResponse[]
}

export const ConclusionBarChart = ({chartData}: IConclusionBarChart) => {
    const MAGIC_ZERO_HEIGHT_FOR_BARS: number = 1.000424242
    const data = chartData?.map((item, index) => {
        const newMap = new Map()
        for (const [key, value] of Object.entries(item.cskills_average_result)) {
            if (value === 0) {
                newMap.set(key, MAGIC_ZERO_HEIGHT_FOR_BARS)
            } else {
                newMap.set(key, value)
            }
        }
        return {
            ...Object.fromEntries(newMap.entries()),
            ccomp_name: item.ccompetence_name,
            skill_name: item.skill_name,
        }
    })

    const cskillsOnlyArray = data?.map(({ccomp_name, skill_name, ...rest}) => rest)

    let cskillsMap = new Map()
    for (let i = 0; i < cskillsOnlyArray.length; i++) {
        const entries = Object.entries(cskillsOnlyArray[i])
        for (let [key, value] of entries) {
            cskillsMap.set(key, value)
        }
    }

    const renderTickWithText = (tickProps: any) => {
        const {x, y, index, payload, visibleTicksCount} = tickProps
        const {offset} = payload
        const value = data[index].ccomp_name
        const isFirst = index == 0
        const isNewCComp = !isFirst && data[index - 1].ccomp_name !== value
        const barsCountInCcomp = data.filter((item) => item.ccomp_name === value).length
        if (isNewCComp || isFirst) {
            const pathX = Math.floor(x - offset)
            return (
                <>
                    <path d={`M${pathX},${y + 10}v${-35}`} stroke="gray" />
                    <g transform={`translate(${pathX + 10},${y})`}>
                        <text fontSize={"12px"} x={0} y={10} fill="#444">
                            {barsCountInCcomp === 1 && visibleTicksCount > 4
                                ? trimLongNames(value, 7)
                                : trimLongNames(value, 17)}
                        </text>
                    </g>
                </>
            )
        }

        return <span />
    }

    const CustomTooltip = ({active, payload, label}: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            const ccompNameOrNone = payload[0].payload.ccomp_name || ""
            const skillNameOrValue = payload[0].payload.skill_name || payload[0].value
            return (
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "10px",
                        border: "1px solid black",
                        borderRadius: "5px",
                    }}>
                    <p>Компонент компетенции: {trimLongNames(ccompNameOrNone)}</p>
                    <p>Знание: {trimLongNames(skillNameOrValue)}</p>
                    <ul>
                        {payload.map((itemPayload, index) => (
                            <li key={index}>
                                {itemPayload.name}:
                                {itemPayload.value === MAGIC_ZERO_HEIGHT_FOR_BARS
                                    ? 0
                                    : itemPayload.value}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        }

        return null
    }

    return (
        <BarChart width={820} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey="skill_name" // key doesn't count, but changing it to "ccomp_name" breaks charts
                axisLine={false}
                tickLine={false}
                interval={0}
                tick={renderTickWithText}
            />
            <YAxis domain={[0, 100]} />
            <Tooltip content={<CustomTooltip />} />
            {Array.from(cskillsMap.keys()).map((cskill, index) => {
                return <Bar key={index} dataKey={cskill} stackId="a" fill={stripedColor(index)} />
            })}
        </BarChart>
    )
}
