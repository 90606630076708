import {Paper} from "../../../../shared/components"
import {CourseDescriptionResponse} from "../../../MainPage/Recommendations/types"

import {RecommendationItemWithTooltip} from "../../../../widgets"
import styles from "./RecommendationsSection.module.scss"

interface IRecommendationsSection {
    recommendations: CourseDescriptionResponse[]
}

export const RecommendationsSection = ({recommendations}: IRecommendationsSection) => {
    return (
        <Paper background="#2c3d6e">
            <div className={styles.title}>Рекомендации</div>
            <div className={styles.recommendations}>
                {recommendations?.map((recommendation: any, index: number) => (
                    <RecommendationItemWithTooltip
                        key={index}
                        className={styles.recommendation}
                        recommendation={recommendation}
                        tooltipType={recommendation.content_type}
                        tooltipId={recommendation.id}
                    />
                ))}
            </div>
        </Paper>
    )
}
