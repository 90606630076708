import {useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button} from "@mui/material"
import {EraMappingTypes} from "../../../shared/store/researchSettings/types"
import {
    useDeleteMappingMutation,
    useLazyGetMappingQuery,
    usePostMappingMutation,
    usePutMappingMutation,
} from "../../../shared/api/endpoints/userEndpoints"
import {
    MappingComplexity,
    mappingSliceActions,
    MappingSliceForm,
    selectAllMappingFormsValid,
    selectMappingComplexity,
} from "../../../shared/store/mapping"
import {getRequestDataByMappingType} from "../../../shared/helpers/getRequestDataByMappingType"

interface Props {
    mappingComplexity: MappingComplexity
    mappingForms: MappingSliceForm[]
    mappingType: EraMappingTypes
    id: string
}

export const AssignOrClearMappingButtons = ({
    mappingComplexity,
    mappingForms,
    mappingType,
    id,
}: Props) => {
    const dispatch = useDispatch()
    const allFormsValid = useSelector(selectAllMappingFormsValid)
    const complexity = useSelector(selectMappingComplexity)
    const {resetForms} = mappingSliceActions
    const [lazyGetMapping] = useLazyGetMappingQuery()
    const [postMapping] = usePostMappingMutation()
    const [putMapping] = usePutMappingMutation()
    const [deleteMapping] = useDeleteMappingMutation()

    const hasComplexity = useMemo(
        () => complexity.easy || complexity.medium || complexity.hard,
        [complexity]
    )
    const formsIsFullyCleared = useMemo(
        () => mappingForms.length === 1 && !mappingForms[0].subject_area && !hasComplexity,
        [mappingForms, hasComplexity]
    )

    const allFormsValidAndComplexitySelectedOrFullyCleared = useMemo(() => {
        if (formsIsFullyCleared) {
            return true
        } else {
            return allFormsValid && hasComplexity
        }
    }, [hasComplexity, allFormsValid, formsIsFullyCleared])

    const handleDeleteMapping = () => dispatch(resetForms())

    const handleAssignMapping = async () => {
        if (formsIsFullyCleared) {
            deleteMapping({by: mappingType, id: id})
            return
        }
        const requestData = getRequestDataByMappingType(
            mappingComplexity,
            mappingForms,
            mappingType,
            id
        )
        if (!requestData) {
            // TODO some error handling
            return
        }
        const currentMapping = await lazyGetMapping({by: mappingType, id: id})
        if (currentMapping.isError) {
            await postMapping({by: mappingType, data: requestData})
            // eslint-disable-next-line no-restricted-globals
            parent.postMessage("closed", "*")
        } else {
            await putMapping({by: mappingType, data: requestData, id: id})
            // eslint-disable-next-line no-restricted-globals
            parent.postMessage("closed", "*")
        }
    }

    return (
        <div style={{display: "flex", justifyContent: "space-between", paddingTop: "1em"}}>
            <Button
                size="large"
                // disabled={!allFormsValid}
                variant="contained"
                color={"secondary"}
                onClick={handleDeleteMapping}>
                Очистить параметры
            </Button>
            <Button
                size="large"
                disabled={!allFormsValidAndComplexitySelectedOrFullyCleared}
                variant="contained"
                color={"secondary"}
                onClick={handleAssignMapping}>
                Сохранить
            </Button>
        </div>
    )
}
