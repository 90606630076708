import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {IForm, IResearchSettingsSlice} from "./types"

const initialState: IResearchSettingsSlice = {
    forms: [
        {
            id: 0,
            subject: null,
            competence: null,
            ccompetence: null,
            skill: null,
            complexity: [],
            valid: false,
        },
    ],
    lastFormIdChangedManually: null,
}

export const researchSettingsSlice = createSlice({
    name: "researchSettings",
    initialState,
    reducers: {
        addForm: (state, action: PayloadAction<IForm>) => {
            state.forms.push(action.payload)
        },
        removeForm: (state, action: PayloadAction<number>) => {
            state.forms = state.forms.filter((form) => form.id !== action.payload)
        },
        resetForms: (state) => {
            state.forms = [
                {
                    id: 0,
                    subject: null,
                    competence: null,
                    ccompetence: null,
                    skill: null,
                    complexity: [],
                    valid: false,
                },
            ]
        },
        editFormItem: (
            state,
            action: PayloadAction<{formId: number; field: keyof IForm; value: any}>
        ) => {
            state.forms = state.forms.map((form) =>
                form.id === action.payload.formId
                    ? {...form, [action.payload.field]: action.payload.value}
                    : form
            )
        },
        editFormItemManually: (
            state,
            action: PayloadAction<{formId: number; field: keyof IForm; value: any}>
        ) => {
            state.forms = state.forms.map((form) =>
                form.id === action.payload.formId
                    ? {...form, [action.payload.field]: action.payload.value}
                    : form
            )
            state.lastFormIdChangedManually = action.payload.formId
        },
    },
})

export const {actions: researchSettingsActions} = researchSettingsSlice
export const {reducer: researchSettingsReducer} = researchSettingsSlice
