import {useDispatch, useSelector} from "react-redux"
import {useSwiper} from "swiper/react"
import {
    ISliderCompetence,
    researchConclusionActions,
    selectCcompetencesSelected,
    selectCompetencesSelected,
    selectSkillsSelected,
    selectSubjectsSelected,
} from "../../../../../shared/store/researchConslusion"
import {SliderSectionItem} from "../SliderSectionItem/SliderSectionItem"
import {Checkbox, FormControlLabel} from "@mui/material"
import styles from "../SliderSection.module.scss"
import {getFilteredCcompetenceForCompetence, getFilteredSkillsForCompetence} from "./helpers"
import {useEffect} from "react"

interface ICompetenceColumn {
    active: boolean
}

export const CompetenceColumn = ({active}: ICompetenceColumn) => {
    const swiper = useSwiper()
    const dispatch = useDispatch()
    const competencesSelected = useSelector(selectCompetencesSelected)
    const ccompetencesSelected = useSelector(selectCcompetencesSelected)
    const skillsSelected = useSelector(selectSkillsSelected)
    const subjectsSelected = useSelector(selectSubjectsSelected)
    const competences = subjectsSelected.flatMap((subject) => subject.competences)
    const {
        setCompetencesSelected,
        removeCompetencesSelected,
        addCompetencesSelected,
        setCcompetencesSelected,
        setSkillsSelected,
    } = researchConclusionActions
    const disableAllButton = competences.length <= 0
    const disableReset = competences.length <= 0 || competencesSelected.length === 0

    const handleSelectAll = () => dispatch(setCompetencesSelected(competences))

    useEffect(() => {
        if (competencesSelected.length === 0) {
            swiper.slidePrev(500)
        }
    }, [competencesSelected])

    const handleReset = () => {
        swiper.slidePrev(500)
        dispatch(setCompetencesSelected([]))
        dispatch(setCcompetencesSelected([]))
        dispatch(setSkillsSelected([]))
    }

    const checkboxIsChecked = (name: string) =>
        competencesSelected.some((competence) => competence.competence_name === name)

    const handleToggleCheckbox = (e: any, competence: ISliderCompetence) => {
        if (e.target.checked) {
            dispatch(addCompetencesSelected(competence))
        } else {
            dispatch(removeCompetencesSelected(competence))
            if (ccompetencesSelected.length > 0) {
                const filteredCcompetences = getFilteredCcompetenceForCompetence(
                    competence,
                    ccompetencesSelected
                )
                dispatch(setCcompetencesSelected(filteredCcompetences))
            }
            if (skillsSelected.length > 0) {
                const filteredSkills = getFilteredSkillsForCompetence(competence, skillsSelected)
                dispatch(setSkillsSelected(filteredSkills))
            }
        }
    }
    return (
        <SliderSectionItem
            title="Компетенции"
            active={false}
            disableAllButton={disableAllButton}
            disableReset={disableReset}
            onResetClick={handleReset}
            onSelectAllClick={handleSelectAll}>
            {competences.map((competence) => (
                <FormControlLabel
                    key={competence.competence_id}
                    className={styles.checkbox}
                    label={competence.competence_name}
                    control={
                        <Checkbox
                            size="small"
                            checked={checkboxIsChecked(competence.competence_name)}
                            onClick={(e) => handleToggleCheckbox(e, competence)}
                        />
                    }
                />
            ))}
        </SliderSectionItem>
    )
}
