import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"

interface SubscriptionDialogProps {
    open: boolean
    onClose: () => void
}

export const SubscriptionDialog = ({open, onClose}: SubscriptionDialogProps) => {
    const handleSubscribe = () => {
        localStorage.setItem("cip-subscription", "true")
        window.location.assign("/home")
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Оформление подписки</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Для использования Центра самодиагностики необходимо оформить подписку
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Не сейчас</Button>
                <Button
                    onClick={handleSubscribe}
                    color="primary"
                    variant="contained"
                    sx={{"&:hover": {backgroundColor: "#666"}}}>
                    Перейти к оформлению
                </Button>
            </DialogActions>
        </Dialog>
    )
}
