import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"
import {store} from "./shared/store"
import {createTheme, ThemeProvider} from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main: "#444",
        },
        secondary: {
            main: "#ffffff",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#f7f7f7",
                    },
                },
            },
        },
    },
})

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
)
