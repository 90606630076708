// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliderSectionItem_title__pULTq {\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n\n.SliderSectionItem_all__P\\+cqj {\n  display: flex;\n  align-items: center;\n  padding-bottom: 10px;\n  border-bottom: 1px solid white;\n}\n.SliderSectionItem_all__P\\+cqj span {\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.SliderSectionItem_checkbox__tqC4k span {\n  font-size: 14px;\n}\n\n.SliderSectionItem_chip__d6EzT {\n  cursor: pointer;\n}\n\n.SliderSectionItem_disabled__TtLMt {\n  pointer-events: none;\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/SliderSection/SliderSectionItem/SliderSectionItem.module.scss"],"names":[],"mappings":"AAMA;EACE,eAAA;EACA,mBAAA;AALF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,8BAAA;AAJF;AAKE;EACD,iBAAA;EACA,eAAA;AAHD;;AAOE;EACD,eAAA;AAJD;;AAOA;EACE,eAAA;AAJF;;AAMA;EACE,oBAAA;EACA,YAAA;AAHF","sourcesContent":["//.wrapper {\n//  width: 60%;\n//}\n//.small {\n//  width: 40%;\n//}\n.title {\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n.all {\n  display: flex;\n  align-items: center;\n  padding-bottom: 10px;\n  border-bottom: 1px solid white;\n  span {\n\tmargin-right: 5px;\n\tcursor: pointer;\n  }\n}\n.checkbox {\n  span {\n\tfont-size: 14px;\n  }\n}\n.chip {\n  cursor: pointer;\n}\n.disabled {\n  pointer-events: none;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "SliderSectionItem_title__pULTq",
	"all": "SliderSectionItem_all__P+cqj",
	"checkbox": "SliderSectionItem_checkbox__tqC4k",
	"chip": "SliderSectionItem_chip__d6EzT",
	"disabled": "SliderSectionItem_disabled__TtLMt"
};
export default ___CSS_LOADER_EXPORT___;
