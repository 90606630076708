import classNames from "classnames"
import "./Spinner.scss"

interface ISpinner {
    className?: string
}

export const Spinner = ({className}: ISpinner) => (
    <div className={classNames("spinner", className)}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
)
