import {TooltipBy} from "../components/RecommendationItem/RecommendationsTooltip/types"
import {EraMappingTypes, OneOfSubjectsModulesLessons} from "../store/researchSettings/types"

const PROTO_API_PREFIX: string = "cip/api"
const REAL_API_PREFIX: string = "cipapi"
// const REAL_API_PREFIX: string = "api" // for test

export const URL_GET_OR_POST_QUESTION_DATA = (sessionId: string): string =>
    REAL_API_PREFIX + `/v1/examination/${sessionId}`

//-------------------- -------------------- --------------------
//--------------------   <RECOMMENDATIONS>  --------------------
//-------------------- -------------------- --------------------

// Recommendations Step 1 - to get Deficit per CIP Subject, it returns several objs: {*query*, [{subject, deficit}]}
export const URL_GET_MAIN_DEFICIT_WITH_SUBJECT_ID: string =
    REAL_API_PREFIX + "/v1/analytics/deficit/main/"

// Recommendations Step 2 - to get recommended ERA Subjects/Courses [{id, type}] for a given *query* based on CIP Subject, Comp, CComp, Skill, ...
export const URL_GET_RECOMMENDED_ERA_IDS_BY_TYPE = (
    by: "subjects" | "modules" | "lessons",
    query: string,
    isDeep: boolean = false
) => {
    const isModulesOrLessons = by !== "subjects"
    if (isDeep && isModulesOrLessons) {
        return REAL_API_PREFIX + `/v1/recommendations/era_${by}/?${query}&is_deep=1`
    } else {
        return REAL_API_PREFIX + `/v1/recommendations/era_${by}/?${query}`
    }
}
export const URL_GET_RECOMMENDED_ERA_IDS_ALL_TYPES_BY_CONCLUSION_DEFICIT = (
    params: string,
    pupilId?: string | null
) => {
    if (pupilId)
        return (
            REAL_API_PREFIX + `/v1/recommendations/conclusion_deficit/?${params}&pupil=${pupilId}`
        )
    return REAL_API_PREFIX + `/v1/recommendations/conclusion_deficit/?${params}`
}

// Recommendations Step 3 - to get images and descriptions for ERA Subjects/Courses ~, Modules, Lessons~ (to be added later)
export const URL_GET_ERA_DESCRIPTIONS_BY = (by: "subjects" | "modules" | "lessons", ids: string) =>
    REAL_API_PREFIX + `/v1/erascope/short_${by}/?${by}=${ids}`

// Recommendations Step 4 - to get lengthy descriptions and more data to be shown on a Tooltip for ERA Subjects/Courses, Modules, Lessons
// supported: by_subject, by_module, by_lesson
export const URL_GET_TOOLTIP = (by: TooltipBy, id: string): string =>
    REAL_API_PREFIX + `/v1/erascope/tool_tip/by_${by}/${id}`
//-------------------- -------------------- --------------------
//--------------------  </RECOMMENDATIONS>  --------------------
//-------------------- -------------------- --------------------

//-------------------- -------------------- --------------------
//--------------------  </MAPPING>  --------------------
//-------------------- -------------------- --------------------
export const URL_CRUD_MAPPING = (by: EraMappingTypes, id?: string): string => {
    let mappingName: OneOfSubjectsModulesLessons
    if (by === "course") {
        mappingName = "subjects"
    } else {
        // @ts-ignore
        mappingName = by + "s"
    }
    if (id) {
        return REAL_API_PREFIX + `/v1/recommendations/mapping/era_${mappingName}/${id}/`
    } else {
        return REAL_API_PREFIX + `/v1/recommendations/mapping/era_${mappingName}/`
    }
}
export const URL_GET_MAPPING_RECOMMENDATION_BY_TYPE = (
    by: Exclude<EraMappingTypes, "course">,
    id: string
): string => {
    return REAL_API_PREFIX + `/v1/recommendations/for_mapping/by_${by}/${id}/`
}
//-------------------- -------------------- --------------------
//--------------------  </MAPPING>  --------------------
//-------------------- -------------------- --------------------
export const URL_GET_RESEARCH_DATA: string = REAL_API_PREFIX + "/v1/examination/"
export const URL_GET_UNFINISHED_EXAMINATIONS = (isCompleted: boolean): string =>
    REAL_API_PREFIX + `/v1/examination/exam-sessions/?is_completed=${isCompleted}`
export const URL_GET_ERASCOPE_INSTITUTION_INFO: string =
    REAL_API_PREFIX + "/v1/erascope/institution-image"
export const URL_POST_RESEARCH_DATA: string = REAL_API_PREFIX + "/v1/examination/"
export const URL_GET_SUBJECTS_DATA: string = REAL_API_PREFIX + "/v1/qbank/subject"
export const URL_GET_USER_HISTORY = (pupilId: string | null) =>
    pupilId
        ? REAL_API_PREFIX + `/v1/analytics/user_history/?pupil=${pupilId}`
        : REAL_API_PREFIX + "/v1/analytics/user_history/"

export const URL_GET_COMPETENCE_DATA_FMT = (subjectId: string) => {
    return REAL_API_PREFIX + `/v1/qbank/competence?subject_id=${subjectId}`
}
export const URL_GET_CCOMPETENCE_DATA_FMT = (competenceId: string) => {
    return REAL_API_PREFIX + `/v1/qbank/ccompetence?competence_id=${competenceId}`
}
export const URL_GET_SKILL_DATA_FMT = (ccompetenceId: string) => {
    return REAL_API_PREFIX + `/v1/qbank/skill?ccompetence_id=${ccompetenceId}`
}
export const URL_GET_CSKILL_DATA_FMT = (skillId: string) => {
    return REAL_API_PREFIX + `/v1/qbank/cskill?skill_id=${skillId}`
}
export const URL_POST_FORM_DATA: string = REAL_API_PREFIX + "/v1/examination/start/"
export const URL_POST_ASSIGN_EXAM: string = REAL_API_PREFIX + "/v1/examination/start-grade-set/"
export const URL_GET_TEST_CONCLUSION_FMT = (sessionId: string) => {
    return REAL_API_PREFIX + `/v1/examination/${sessionId}/conclusion`
}
export const URL_GET_TEST_CONCLUSION_DEFICIT_FMT = (
    selectValue: string | undefined,
    pupilId: string | null
) => {
    let url =
        REAL_API_PREFIX +
        (selectValue ? `/v1/analytics/deficit/${selectValue}` : `/v1/analytics/deficit`)
    if (pupilId) url += `?pupil=${pupilId}`
    return url
}

export const URL_GET_TEST_CONCLUSION_CHART_FMT = (
    sessionId: string | undefined,
    selectValue: string | undefined
) =>
    REAL_API_PREFIX +
    (selectValue
        ? `/v1/analytics/${sessionId}/cskill-graph/${selectValue}`
        : `/v1/analytics/${sessionId}/cskill-graph`)
export const URL_GET_TEST_CONCLUSION_HISTORY_FMT = (
    sessionId: string | undefined,
    selectValue: string | undefined
) =>
    REAL_API_PREFIX +
    (selectValue
        ? `/v1/examination/${sessionId}/history/${selectValue}`
        : `/v1/examination/${sessionId}/history`)
export const URL_GET_ERA_RESEARCH_RESULT = (gradesetId: string) =>
    REAL_API_PREFIX + `/v1/analytics/grade_set_history/${gradesetId}/`
export const URL_GET_ERA_RESEARCH_CHART_FOR_GRADESET = (sessionId: string, selectValue: string) =>
    REAL_API_PREFIX + `/v1/analytics/${sessionId}/cskill-graph/${selectValue}?grade_set=1`
export const URL_GET_ERA_CURRENT_EDUCATION = () =>
    REAL_API_PREFIX + "/v1/erascope/my_current_education"
export const URL_GET_ERA_FIND_INSTITUTIONS = (query: string) =>
    `/backend/apiAccount/find-institutions?query=${query}`
export const URL_GET_ERA_SLIDER = () => REAL_API_PREFIX + "/v1/erascope/slider/"
export const URL_LOGIN = () => `/backend/auth/login`
export const URL_GET_ERA_PROFILE = () => `/backend/apiAccount/profile`
export const URL_GET_ERA_INSTITUTION = (institutionId: string) =>
    `/backend/apiAccount/institutionDetails?id=${institutionId}`
export const URL_GET_ERA_GRANTS = () => `/backend/apiUser/grants`
