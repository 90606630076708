import ResearchSettingsForm from "../ResearchSettingsPage/ResearchSettingsForm/ResearchSettingsForm"
import {Modal} from "../../shared/components/Modal/Modal"
import {useSelector} from "react-redux"
import {selectForms} from "../../shared/store/researchSettings"
import {AssignExamButton} from "./AssignExamButton/AssignExamButton"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {DateTimePicker} from "@mui/x-date-pickers"
import {useState} from "react"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import styles from "./AssignExamPage.module.scss"

export const AssignExamPage = () => {
    const forms = useSelector(selectForms)
    const [startTime, setStartTime] = useState(dayjs(Date.now()))
    const [finishTime, setFinishTime] = useState(dayjs(Date.now()))

    return (
        <Modal modalTitle="Диагностика" hideCloseIcon className={styles.modal}>
            <div style={{marginBottom: "20px"}}>
                <h5 className={styles.subtitle}>Укажите время проведения диагностики</h5>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <DateTimePicker
                        sx={{marginRight: "10px"}}
                        label="Начало диагностики"
                        value={startTime}
                        onChange={(newValue) => {
                            if (newValue) {
                                setStartTime(newValue)
                            }
                        }}
                    />
                    <DateTimePicker
                        label="Окончание диагностики"
                        value={finishTime}
                        onChange={(newValue) => {
                            if (newValue) {
                                setFinishTime(newValue)
                            }
                        }}
                        slotProps={{textField: {size: "medium"}}}
                    />
                </LocalizationProvider>
            </div>
            <h5 className={styles.subtitle}>Пожалуйста задайте параметры диагностики</h5>
            {forms.map((form) => (
                <ResearchSettingsForm key={form.id} form={form} />
            ))}
            <AssignExamButton startTime={startTime} finishTime={finishTime} />
        </Modal>
    )
}
