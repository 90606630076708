// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MappingPage_modal__njex2 > div {\n  width: 900px;\n  min-height: 100%;\n  border-radius: 0;\n  margin: 0;\n}\n\n.MappingPage_checkbox_wrapper__bN-TY {\n  display: flex;\n  align-items: center;\n}\n\n.MappingPage_checkbox_box__qpM0S {\n  position: relative;\n  left: -2px;\n  border: 1px solid rgba(0, 0, 0, 0.23);\n  border-radius: 5px;\n  overflow: hidden;\n  padding-block-end: 0.425em;\n}\n\n.MappingPage_checkbox_box_legend__mb0cK {\n  letter-spacing: 0.00938em;\n  height: 11px;\n  font-size: 0.75em;\n  max-width: 100%;\n  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/pages/MappingPage/MappingPage.module.scss"],"names":[],"mappings":"AACE;EACD,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,SAAA;AADD;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,qCAAA;EACA,kBAAA;EAEA,gBAAA;EACA,0BAAA;AAFF;;AAKA;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EAEA,2DAAA;EACA,mBAAA;AAFF","sourcesContent":[".modal {\n  > div {\n\twidth: 900px;\n\tmin-height: 100%;\n\t//min-height: 620px;\n\tborder-radius: 0;\n\tmargin: 0;\n  }\n}\n.checkbox_wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.checkbox_box {\n  position: relative;\n  left: -2px;\n  border: 1px solid rgba(0, 0, 0, 0.23);\n  border-radius: 5px;\n\n  overflow: hidden;\n  padding-block-end: 0.425em;\n}\n\n.checkbox_box_legend {\n  letter-spacing: 0.00938em;\n  height: 11px;\n  font-size: 0.75em;\n  max-width: 100%;\n  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;\n  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "MappingPage_modal__njex2",
	"checkbox_wrapper": "MappingPage_checkbox_wrapper__bN-TY",
	"checkbox_box": "MappingPage_checkbox_box__qpM0S",
	"checkbox_box_legend": "MappingPage_checkbox_box_legend__mb0cK"
};
export default ___CSS_LOADER_EXPORT___;
