import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {UnfinishedResearchCard} from "../../../shared/components"
import {useGetUnfinishedResearchesQuery} from "../../../shared/api/endpoints/userEndpoints"
import {UnfinishedExamCardAsync} from "./UnfinishedExamCardAsync/UnfinishedExamCardAsync"
import styles from "./UnfinishedResearches.module.scss"
import {Spinner} from "../../../shared/components/Spinner/Spinner"
import dayjs from "dayjs"
import {
    researchConclusionActions,
    selectResearchFinished,
    selectResearchStarted,
} from "../../../shared/store/researchConslusion"
import {useDispatch, useSelector} from "react-redux"

interface UnfinishedResearchesProps {
    finished: boolean
}

export const UnfinishedResearches = ({finished}: UnfinishedResearchesProps) => {
    const {currentData, isFetching, refetch} = useGetUnfinishedResearchesQuery(finished)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const researchStarted = useSelector(selectResearchStarted)
    const researchFinished = useSelector(selectResearchFinished)
    const {setResearchStarted, setResearchFinished} = researchConclusionActions

    useEffect(() => {
        if (!finished) {
            if (researchStarted) {
                dispatch(setResearchStarted(false))
                refetch()
            } else if (researchFinished) {
                dispatch(setResearchFinished(false))
                refetch()
            }
        } else if (finished) {
            if (researchFinished) {
                dispatch(setResearchFinished(false))
                refetch()
            }
        }
        return () => {
            dispatch(setResearchStarted(false))
            dispatch(setResearchFinished(false))
        }
    }, [researchStarted, researchFinished])

    if (currentData?.length === 0) {
        return null
    }
    const navigateToUnfinishedExam = (id: string) => {
        if (finished) {
            navigate(`/cipui/cip_dashboard/research_page/${id}/conclusion`)
        } else {
            navigate(`/cipui/cip_dashboard/research_page/${id}`)
        }
    }

    return (
        <div className={styles.wrapper}>
            <h2>{finished ? "Завершенные" : "Незавершенные"} диагностики</h2>
            <div className={styles.cards}>
                {isFetching ? (
                    <Spinner />
                ) : (
                    currentData?.map((card) =>
                        card.grade_set ? (
                            <UnfinishedExamCardAsync
                                key={card.id}
                                onClick={() => navigateToUnfinishedExam(card.id)}
                                title={card.headers[0].tittle}
                                subtitle={card.headers[0].subtitle}
                                cards={card.headers.length}
                                gradeSet={card.grade_set}
                                totalCheckedSkills={card.progress_bar_total_skills}
                                checkedSkills={card.progress_bar_checked_skills}
                                startTime={dayjs(card.start_time).format("DD/MM/YYYY, HH:mm")}
                                finished={finished}
                            />
                        ) : (
                            <UnfinishedResearchCard
                                key={card.id}
                                onClick={() => navigateToUnfinishedExam(card.id)}
                                title={card.headers[0].tittle}
                                subtitle={card.headers[0].subtitle}
                                cards={card.headers.length}
                                totalCheckedSkills={card.progress_bar_total_skills}
                                checkedSkills={card.progress_bar_checked_skills}
                                startTime={dayjs(card.start_time).format("DD/MM/YYYY, HH:mm")}
                                expectedFinishTime={
                                    card.finish_time
                                        ? dayjs(card.finish_time).format("DD/MM/YYYY, HH:mm")
                                        : undefined
                                }
                                finished={finished}
                            />
                        )
                    )
                )}
            </div>
        </div>
    )
}
