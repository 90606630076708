import {
    ISliderCcompetence,
    ISliderCompetence,
    ISliderSkills,
    ISliderSubject,
} from "../../store/researchConslusion"

const dashedPrefix = ' (прогноз)'

export function setDashedLinesForChartData(
    namesArray: string[],
    dataForChart: Record<string, string | number>[]
) {
    const lastItem = dataForChart[dataForChart.length - 1]
    // У последнего который будет всегда прогнозом вместо обычного name делаю dashed
    for (let [key, value] of Object.entries(lastItem)) {
        if (key === "date" || key === "session_id") {
            continue
        } else {
            lastItem[key + dashedPrefix] = lastItem[key]
            delete lastItem[key]
        }
    }
    let tmpNamesArray = [...namesArray]
    // Нахожу последнее значние с этим именем чтобы доабвить туда +"dashed"
    for (let i = dataForChart.length - 1; i >= 0; i--) {
        if (i === dataForChart.length - 1) {
            continue // Пропускаю первую итерацию потому что там все значения dashed
        }
        if (tmpNamesArray.length === 0) {
            break // Выхожу их цикла если прошелся по всем именам
        }
        for (let [key, value] of Object.entries(dataForChart[i])) {
            // debugger
            if (tmpNamesArray.includes(key)) {
                dataForChart[i][key + dashedPrefix] = value
                tmpNamesArray = tmpNamesArray.filter((name) => name !== key)
            }
        }
    }
    return dataForChart
}

export function getSkillsDataForChart(
    ccompetencesSelected: ISliderCcompetence[],
    skillsSelected: ISliderSkills[]
) {
    let allSkillsInSelectedCcompetences = ccompetencesSelected.flatMap(
        (ccompetence) => ccompetence.skills
    )
    let namesArray: string[] = skillsSelected.map((skill) => skill.skill_name)
    let skillsArray = allSkillsInSelectedCcompetences.filter((skill) =>
        namesArray.includes(skill.skill_name)
    )
    let dataForChart: any[] = []
    let dashedNamesArray: string[] = namesArray.map((name) => name + dashedPrefix)
    let uniqueDatesInDataForChart = new Set()
    for (let i = 0; i < skillsArray.length; i++) {
        let skill = skillsArray[i]
        if (uniqueDatesInDataForChart.has(skill.date)) {
            // Ищу есть ли уже в dataForChart обьекты с таким ключом
            let oldItemIndex = dataForChart.findIndex((item) => item.date === skill.date)
            dataForChart[oldItemIndex] = {
                // Добавляю в этот же обьект новый результат
                ...dataForChart[oldItemIndex],
                [skill.skill_name]: skill.result_pct,
            }
        } else {
            uniqueDatesInDataForChart.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
            dataForChart.push({
                date: skill.date,
                session_id: skill.session_id,
                [skill.skill_name]: skill.result_pct,
            }) // Добавляю результат в финальный массив для отрисовки графика
        }
    }
    dataForChart.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
    }) // Сортирую данные перед отправкой в график
    const finalDataForChart = setDashedLinesForChartData(namesArray, dataForChart)
    return [namesArray, dashedNamesArray, finalDataForChart]
}

export function getCcompetenceDataForChart(ccompetencesSelected: ISliderCcompetence[]) {
    let dashedNamesArray = []
    let namesArray = [] // Массив имен для графика
    let dataForChart: any[] = [] // Результирующий массив для графика
    let uniqueDatesInDataForChart = new Set()
    for (let i = 0; i < ccompetencesSelected.length; i++) {
        let ccompetence = ccompetencesSelected[i]
        let totalSkillsInCcomp = ccompetence.total_skills_in_ccomp
        namesArray.push(ccompetence.ccompetence_name)
        dashedNamesArray.push(ccompetence.ccompetence_name + dashedPrefix)
        let CcompSkillsArray = ccompetence.skills // Массив всех скиллов
        let sortedCcompSkillsArray = [...CcompSkillsArray].sort(function (a, b) {
            return Date.parse(a.date) - Date.parse(b.date)
        })
        let prevSkills = new Map()
        let uniqueDatesInCcomp = new Set() // Массив с датами
        for (let k = 0; k < sortedCcompSkillsArray.length; k++) {
            let skill = sortedCcompSkillsArray[k]
            if (uniqueDatesInCcomp.has(skill.date)) {
                continue // Если на эту дату уже я проверял скиллы то следующая итерация
            } else {
                let thisDateSkills = [] // Тут хранятся все значения скиллов на конкретную дату
                for (let s = 0; s < sortedCcompSkillsArray.length; s++) {
                    if (sortedCcompSkillsArray[s].date === skill.date) {
                        thisDateSkills.push(sortedCcompSkillsArray[s])
                    }
                } // Цикл замена filter (в нем 2 или 1 значение скиллов)
                let thisDateSkillsSummary = 0 // Рассчетная сумма скиллов на эту уникальную дату
                let tmpPrevSkills = new Map(prevSkills) // tmp копия prevSkills Map чтобы смотреть какие остались значения
                for (let m = 0; m < thisDateSkills.length; m++) {
                    let thisDateSkill = thisDateSkills[m]
                    tmpPrevSkills.delete(thisDateSkill.skill_name) // Удаляю из временного мапа скилл если н есть актуальный
                    thisDateSkillsSummary += thisDateSkill.result_pct // Дабавляю результат этого скилла к рассчетной сумме
                    prevSkills.set(thisDateSkill.skill_name, thisDateSkill.result_pct) // Обновляю prevSkills чтобы всегда было актуальное значение
                } // Прохожусь по скиллам на конкретнкую дату и смотрю на те что были до (в tmp переменной)
                if (tmpPrevSkills.size) {
                    // Если остались какие то скиллы не перезаписанными (те были в прошлом но сейчас их нет)
                    // @ts-ignore
                    for (let value of tmpPrevSkills.values()) {
                        thisDateSkillsSummary += value
                    } // То пушу их в итоговое число
                } // Проверяю остатки
                uniqueDatesInCcomp.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                if (uniqueDatesInDataForChart.has(skill.date)) {
                    // Ищу есть ли уже в dataForChart обьекты с таким ключом
                    let oldItemIndex = dataForChart.findIndex((item) => item.date === skill.date)
                    dataForChart[oldItemIndex] = {
                        // Добавляю в этот же обьект новый результат
                        ...dataForChart[oldItemIndex],
                        [ccompetence.ccompetence_name]: thisDateSkillsSummary / totalSkillsInCcomp,
                    }
                } else {
                    uniqueDatesInDataForChart.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                    dataForChart.push({
                        date: skill.date,
                        session_id: skill.session_id,
                        [ccompetence.ccompetence_name]: thisDateSkillsSummary / totalSkillsInCcomp,
                    }) // Добавляю результат в финальный массив для отрисовки графика
                }
            }
        }
    }
    dataForChart.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
    }) // Сортирую данные перед отправкой в график
    const finalDataForChart = setDashedLinesForChartData(namesArray, dataForChart)
    return [namesArray, dashedNamesArray, finalDataForChart]
}

export function getCompetenceDataForChart(competencesSelected: ISliderCompetence[]) {
    let dashedNamesArray = []
    let namesArray = [] // Массив имен для графика
    let dataForChart: any[] = [] // Результирующий массив для графика
    let uniqueDatesInDataForChart = new Set() // Массив с датами
    for (let i = 0; i < competencesSelected.length; i++) {
        let competence = competencesSelected[i]
        let totalSkillsInComp = competence.total_skills_in_comp
        namesArray.push(competence.competence_name)
        dashedNamesArray.push(competence.competence_name + dashedPrefix)
        let compSkillsArray = [] // Массив всех скиллов
        for (let j = 0; j < competence.ccompetences.length; j++) {
            let ccompetence = competence.ccompetences[j]
            compSkillsArray.push(...ccompetence.skills)
        }
        let sortedCompSkillsArray = [...compSkillsArray].sort(function (a, b) {
            return Date.parse(a.date) - Date.parse(b.date)
        })
        let prevSkills = new Map()
        let uniqueDatesInComp = new Set() // Массив с датами
        for (let k = 0; k < sortedCompSkillsArray.length; k++) {
            let skill = sortedCompSkillsArray[k]
            if (uniqueDatesInComp.has(skill.date)) {
                continue // Если на эту дату уже я проверял скиллы то следующая итерация
            } else {
                let thisDateSkills = [] // Тут хранятся все значения скиллов на конкретную дату
                for (let s = 0; s < sortedCompSkillsArray.length; s++) {
                    if (sortedCompSkillsArray[s].date === skill.date) {
                        thisDateSkills.push(sortedCompSkillsArray[s])
                    }
                } // Цикл замена filter (в нем 2 или 1 значение скиллов)
                let thisDateSkillsSummary = 0 // Рассчетная сумма скиллов на эту уникальную дату
                let tmpPrevSkills = new Map(prevSkills) // tmp копия prevSkills Map чтобы смотреть какие остались значения
                for (let m = 0; m < thisDateSkills.length; m++) {
                    let thisDateSkill = thisDateSkills[m]
                    tmpPrevSkills.delete(thisDateSkill.skill_name) // Удаляю из временного мапа скилл если н есть актуальный
                    thisDateSkillsSummary += thisDateSkill.result_pct // Дабавляю результат этого скилла к рассчетной сумме
                    prevSkills.set(thisDateSkill.skill_name, thisDateSkill.result_pct) // Обновляю prevSkills чтобы всегда было актуальное значение
                } // Прохожусь по скиллам на конкретнкую дату и смотрю на те что были до (в tmp переменной)
                if (tmpPrevSkills.size) {
                    // Если остались какие то скиллы не перезаписанными (те были в прошлом но сейчас их нет)
                    // @ts-ignore
                    for (let value of tmpPrevSkills.values()) {
                        thisDateSkillsSummary += value
                    } // То пушу их в итоговое число
                } // Проверяю остатки
                uniqueDatesInComp.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                if (uniqueDatesInDataForChart.has(skill.date)) {
                    // Ищу есть ли уже в dataForChart обьекты с таким ключом
                    let oldItemIndex = dataForChart.findIndex((item) => item.date === skill.date)
                    dataForChart[oldItemIndex] = {
                        // Добавляю в этот же обьект новый результат
                        ...dataForChart[oldItemIndex],
                        [competence.competence_name]: thisDateSkillsSummary / totalSkillsInComp,
                    }
                } else {
                    uniqueDatesInDataForChart.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                    dataForChart.push({
                        date: skill.date,
                        session_id: skill.session_id,
                        [competence.competence_name]: thisDateSkillsSummary / totalSkillsInComp,
                    }) // Добавляю результат в финальный массив для отрисовки графика
                }
            }
        }
    }
    dataForChart.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
    }) // Сортирую данные перед отправкой в график
    const finalDataForChart = setDashedLinesForChartData(namesArray, dataForChart)
    return [namesArray, dashedNamesArray, finalDataForChart]
}

export function getSubjectDataForChart(subjectsSelected: ISliderSubject[]) {
    let dashedNamesArray = []
    let namesArray = [] // Массив имен для графика
    let dataForChart: any[] = [] // Результирующий массив для графика
    let uniqueDatesInDataForChart = new Set() // Массив с датами
    for (let i = 0; i < subjectsSelected.length; i++) {
        let subject = subjectsSelected[i]
        let totalSkillsInSubject = subject.total_skills_in_subj
        namesArray.push(subject.subject_name)
        dashedNamesArray.push(subject.subject_name + dashedPrefix)
        let subjectSkillsArray = [] // Массив всех скиллов
        for (let j = 0; j < subject.competences.length; j++) {
            let competence = subject.competences[j]
            for (let l = 0; l < competence.ccompetences.length; l++) {
                let ccompetence = competence.ccompetences[l]
                subjectSkillsArray.push(...ccompetence.skills)
            }
        }
        let sortedSubjectSkillsArray = [...subjectSkillsArray].sort(function (a, b) {
            return Date.parse(a.date) - Date.parse(b.date)
        })
        let prevSkills = new Map()
        let uniqueDatesInSubject = new Set() // Массив с датами
        for (let k = 0; k < sortedSubjectSkillsArray.length; k++) {
            let skill = sortedSubjectSkillsArray[k]
            if (uniqueDatesInSubject.has(skill.date)) {
                continue // Если на эту дату уже я проверял скиллы то следующая итерация
            } else {
                let thisDateSkills = [] // Тут хранятся все значения скиллов на конкретную дату
                for (let s = 0; s < sortedSubjectSkillsArray.length; s++) {
                    if (sortedSubjectSkillsArray[s].date === skill.date) {
                        thisDateSkills.push(sortedSubjectSkillsArray[s])
                    }
                } // Цикл замена filter (в нем 2 или 1 значение скиллов)
                let thisDateSkillsSummary = 0 // Рассчетная сумма скиллов на эту уникальную дату
                let tmpPrevSkills = new Map(prevSkills) // tmp копия prevSkills Map чтобы смотреть какие остались значения
                for (let m = 0; m < thisDateSkills.length; m++) {
                    let thisDateSkill = thisDateSkills[m]
                    tmpPrevSkills.delete(thisDateSkill.skill_name) // Удаляю из временного мапа скилл если н есть актуальный
                    thisDateSkillsSummary += thisDateSkill.result_pct // Дабавляю результат этого скилла к рассчетной сумме
                    prevSkills.set(thisDateSkill.skill_name, thisDateSkill.result_pct) // Обновляю prevSkills чтобы всегда было актуальное значение
                } // Прохожусь по скиллам на конкретнкую дату и смотрю на те что были до (в tmp переменной)
                if (tmpPrevSkills.size) {
                    // Если остались какие то скиллы не перезаписанными (те были в прошлом но сейчас их нет)
                    // @ts-ignore
                    for (let value of tmpPrevSkills.values()) {
                        thisDateSkillsSummary += value
                    } // То пушу их в итоговое число
                } // Проверяю остатки
                uniqueDatesInSubject.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                if (uniqueDatesInDataForChart.has(skill.date)) {
                    // Ищу есть ли уже в dataForChart обьекты с таким ключом
                    let oldItemIndex = dataForChart.findIndex((item) => item.date === skill.date)
                    dataForChart[oldItemIndex] = {
                        // Добавляю в этот же обьект новый результат
                        ...dataForChart[oldItemIndex],
                        [subject.subject_name]: thisDateSkillsSummary / totalSkillsInSubject,
                    }
                } else {
                    uniqueDatesInDataForChart.add(skill.date) // Добавляю дату как обработанную чтобы не делать расчет на эту дату еще раз
                    dataForChart.push({
                        date: skill.date,
                        session_id: skill.session_id,
                        [subject.subject_name]: thisDateSkillsSummary / totalSkillsInSubject,
                    }) // Добавляю результат в финальный массив для отрисовки графика
                }
            }
        }
    }
    dataForChart.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
    }) // Сортирую данные перед отправкой в график
    const finalDataForChart = setDashedLinesForChartData(namesArray, dataForChart)
    return [namesArray, dashedNamesArray, finalDataForChart]
}
