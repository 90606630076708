interface IProps {
    progress: number
    title?: string
    titleFontSize?: string
    progressColor?: string
    barHeight?: string
    titleFontWeight?: number
    titleWidth?: string
    hideTitle?: boolean
    hidePercentTitle?: boolean
}
export const ProgressBar = ({
    progress,
    title,
    progressColor = "#d3d3d3",
    barHeight = "35px",
    titleFontSize,
    titleFontWeight = 500,
    titleWidth,
    hideTitle,
    hidePercentTitle,
}: IProps) => {
    return (
        <div>
            <div
                style={{
                    height: `${barHeight}`,
                    position: "relative",
                    overflow: "hidden",
                    width: "inherit",
                    borderRadius: "2px",
                    backgroundColor: "#eee",
                }}>
                <div
                    style={{
                        height: "100%",
                        width: `${progress}%`,
                        backgroundColor: progressColor,
                        transition: "width 0.5s",
                    }}></div>
                <div
                    style={{
                        fontSize: `${titleFontSize}`,
                        fontWeight: `${titleFontWeight}`,
                        maxWidth: "inherit",
                    }}>
                    <span
                        style={{
                            width: `${titleWidth}`,
                            fontWeight: "inherit",
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translate(0%,-50%)",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: `${hideTitle ? "none" : "block"}`,
                        }}>{`${title}:`}</span>
                    <span
                        style={{
                            fontWeight: "inherit",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translate(0%,-50%)",
                            display: `${hidePercentTitle ? "none" : "block"}`,
                        }}>{`${progress}%`}</span>
                </div>
            </div>
        </div>
    )
}
