// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EraResearchResult_modal__WemSV > div {\n  width: 900px;\n  min-height: 100%;\n  border-radius: 0;\n  margin: 0;\n}\n\n.EraResearchResult_subtitle__4TB-C {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/pages/EraResearchResult/EraResearchResult.module.scss"],"names":[],"mappings":"AACE;EACD,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,SAAA;AADD;;AAIA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AADF","sourcesContent":[".modal {\n  > div {\n\twidth: 900px;\n\tmin-height: 100%;\n\t//min-height: 620px;\n\tborder-radius: 0;\n\tmargin: 0;\n  }\n}\n.subtitle {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "EraResearchResult_modal__WemSV",
	"subtitle": "EraResearchResult_subtitle__4TB-C"
};
export default ___CSS_LOADER_EXPORT___;
