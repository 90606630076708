import {EraMappingTypes} from "../store/researchSettings/types"
import {MappingComplexity, MappingId, MappingSliceForm} from "../store/mapping"

export interface EraMappingRequestDataForCourse {
    id: string
    subject_areas: MappingId[]
    competences: MappingId[]
    is_easy: boolean
    is_medium: boolean
    is_hard: boolean
}
export interface EraMappingRequestDataForModule {
    id: string
    ccompetences: MappingId[]
    skills: MappingId[]
    is_easy: boolean
    is_medium: boolean
    is_hard: boolean
}
export interface EraMappingRequestDataForLesson {
    id: string
    skills: MappingId[]
    cskills: MappingId[]
    is_easy: boolean
    is_medium: boolean
    is_hard: boolean
}

export type EraMappingRequestDataForAny =
    | EraMappingRequestDataForCourse
    | EraMappingRequestDataForModule
    | EraMappingRequestDataForLesson

export const getRequestDataByMappingType = (
    mappingComplexity: MappingComplexity,
    mappingForms: MappingSliceForm[],
    mapping: EraMappingTypes,
    id: string
) => {
    let subject_areas: MappingId[] = []
    let competences: MappingId[] = []
    let ccompetences: MappingId[] = []
    let skills: MappingId[] = []
    let cskills: MappingId[] = []
    if (mapping === "course") {
        mappingForms.forEach(({subject_area, competence}) => {
            if (subject_area) {
                subject_areas.push({id: subject_area})
                if (competence) competences.push({id: competence})
            }
        })
        return {
            id: id,
            subject_areas,
            competences,
            is_easy: mappingComplexity.easy,
            is_medium: mappingComplexity.medium,
            is_hard: mappingComplexity.hard,
        } as EraMappingRequestDataForCourse
    } else if (mapping === "module") {
        mappingForms.forEach(({ccompetence, skill}) => {
            if (ccompetence) {
                ccompetences.push({id: ccompetence})
                if (skill) skills.push({id: skill})
            }
        })
        return {
            id: id,
            ccompetences,
            skills,
            is_easy: mappingComplexity.easy,
            is_medium: mappingComplexity.medium,
            is_hard: mappingComplexity.hard,
        } as EraMappingRequestDataForModule
    } else if (mapping === "lesson") {
        mappingForms.forEach(({skill, cskill}) => {
            if (skill) {
                skills.push({id: skill})
                if (cskill) cskills.push({id: cskill})
            }
        })
        return {
            id: id,
            skills,
            cskills,
            is_easy: mappingComplexity.easy,
            is_medium: mappingComplexity.medium,
            is_hard: mappingComplexity.hard,
        } as EraMappingRequestDataForLesson
    }
}
