import {useDispatch, useSelector} from "react-redux"
import {
    ISliderSkills,
    researchConclusionActions,
    selectSkillsSelected,
} from "../../../../../shared/store/researchConslusion"
import {SliderSectionItem} from "../SliderSectionItem/SliderSectionItem"
import {Checkbox, FormControlLabel} from "@mui/material"
import styles from "../SliderSection.module.scss"

interface ISkillsColumn {
    active: boolean
    skills: ISliderSkills[]
    uniqueSkillsArray: ISliderSkills[]
}

export const SkillsColumn = ({active, skills, uniqueSkillsArray}: ISkillsColumn) => {
    const dispatch = useDispatch()
    const skillsSelected = useSelector(selectSkillsSelected)
    const {setSkillsSelected, removeSkillSelected, addSkillSelected} = researchConclusionActions
    const disableAllButton = skills.length <= 0
    const disableReset = skills.length <= 0 || skillsSelected.length === 0

    const handleSelectAll = () => dispatch(setSkillsSelected(uniqueSkillsArray))

    const handleReset = () => dispatch(setSkillsSelected([]))

    const checkboxIsChecked = (id: string) => skillsSelected.some((skill) => skill.skill_id === id)

    const handleToggleCheckbox = (e: any, skill: ISliderSkills) => {
        if (e.target.checked) {
            dispatch(addSkillSelected(skill))
        } else {
            dispatch(removeSkillSelected(skill))
        }
    }
    return (
        <SliderSectionItem
            title="Умения"
            active={false}
            disableAllButton={disableAllButton}
            disableReset={disableReset}
            onResetClick={handleReset}
            onSelectAllClick={handleSelectAll}>
            {uniqueSkillsArray.map((skill) => (
                <FormControlLabel
                    key={skill.skill_id}
                    className={styles.checkbox}
                    label={skill.skill_name}
                    control={
                        <Checkbox
                            size="small"
                            checked={checkboxIsChecked(skill.skill_id)}
                            onClick={(e) => handleToggleCheckbox(e, skill)}
                        />
                    }
                />
            ))}
        </SliderSectionItem>
    )
}
