import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons/faBuildingColumns"
import {useLazyGetEraInstitutionQuery} from "../../api/endpoints/userEndpoints"
import styles from "./InstitutionCard.module.scss"
import {getInstitutionUserId} from "./getInstitutionUserId"

interface InstitutionCardProps {
    rating?: string
    image?: string
    name: string
    shortName: string
    fullName: string
    institutionId: string
}

export const InstitutionCard = (props: InstitutionCardProps) => {
    const {rating, image, name, shortName, fullName, institutionId} = props
    const [getEraInstitution] = useLazyGetEraInstitutionQuery()

    const navigateToInstitution = async () => {
        const response = await getEraInstitution(institutionId)
        const userId = await getInstitutionUserId(institutionId)
        // @ts-ignore
        localStorage.setItem("institution", JSON.stringify(response?.data.institution))
        localStorage.setItem("user-id", userId)
        localStorage.setItem("is-institution-guest", "true")
        window.open(`https://erascope.com/pupil/institution`, "_blank")
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.card} onClick={navigateToInstitution}>
                {rating && <div className={styles.rating}>{rating}</div>}
                <div className={styles.avatar}>
                    {image ? (
                        <img
                            src={`https://erascope.com/uploaded-avatars/${image}`}
                            alt={shortName}
                        />
                    ) : (
                        <FontAwesomeIcon size="2xl" icon={faBuildingColumns} />
                    )}
                </div>
                <div className={styles.info}>
                    <h6>{name}</h6>
                    <p>{fullName}</p>
                    <p>{shortName}</p>
                    {/*<div className={styles.subtitle}>Области компетенций</div>*/}
                </div>
            </div>
        </div>
    )
}
