// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnfinishedResearches_wrapper__Yncp- {\n  color: #d3d3d3;\n  padding: 30px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  background: #585858;\n  border-radius: 5px;\n}\n.UnfinishedResearches_wrapper__Yncp- h2 {\n  font-size: 30px;\n  padding-bottom: 20px;\n  margin-bottom: 30px;\n  border-bottom: 1px #6c6c6c solid;\n}\n\n.UnfinishedResearches_cards__ZhhIW {\n  display: flex;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/widgets/UnfinishedResearches/ui/UnfinishedResearches.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACD,eAAA;EACA,oBAAA;EACA,mBAAA;EACA,gCAAA;AAED;;AACA;EACE,aAAA;EACA,eAAA;AAEF","sourcesContent":[".wrapper {\n  color: #d3d3d3;\n  padding: 30px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  background: #585858;\n  border-radius: 5px;\n  h2 {\n\tfont-size: 30px;\n\tpadding-bottom: 20px;\n\tmargin-bottom: 30px;\n\tborder-bottom: 1px #6c6c6c solid;\n  }\n}\n.cards {\n  display: flex;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UnfinishedResearches_wrapper__Yncp-",
	"cards": "UnfinishedResearches_cards__ZhhIW"
};
export default ___CSS_LOADER_EXPORT___;
