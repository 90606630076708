import AccessToken from "../../shared/api/AccessToken"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons/faBuildingColumns"
import styles from "./Navbar.module.scss"

interface InstitutionsMenuItemProps {
    userId: string
    institutionId: string
    image?: string
    name: string
    role: string
}

const getRole = (role: string) => {
    if (role === "teacher") {
        return "Преподаватель"
    } else if (role === "pupil") {
        return "Слушатель"
    } else if (role === "admin") {
        return "Администратор"
    } else {
        return role
    }
}

export const InstitutionsMenuItem = ({
    userId,
    institutionId,
    image,
    name,
    role,
}: InstitutionsMenuItemProps) => {
    const navigateToInstitution = () => {
        localStorage.setItem("user-id", userId)
        if (role === "teacher") {
            window.open(`https://erascope.com/${role}/grades`, "_blank")
        } else if (role === "pupil") {
            window.open(`https://erascope.com/${role}/institution`, "_blank")
        } else if (role === "admin") {
            window.open(`https://erascope.com/${role}/grades`, "_blank")
        } else {
            window.open(`https://erascope.com/other`, "_blank")
        }
    }

    // const navigateToInstitution = async () => {
    //     localStorage.setItem("user-id", userId)
    //     window.open(`https://erascope.com/pupil/institution`, "_blank")
    // }

    return (
        <div onClick={navigateToInstitution} className={styles.menu_item}>
            {image ? (
                <img
                    className={styles.org_image}
                    src={`https://erascope.com/uploaded-avatars/${image}`}
                    alt={name}
                />
            ) : (
                <FontAwesomeIcon size="sm" className={styles.org_image} icon={faBuildingColumns} />
            )}
            <div
                className={styles.tooltip}
                style={{flexDirection: "column", alignItems: "flex-start"}}>
                <div className={styles.tooltip_title}>{name}</div>
                <br />
                <div className={styles.tooltip_role}>{getRole(role)}</div>
            </div>
        </div>
    )
}
