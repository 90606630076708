import {ProfileResponse} from "../types"
import dayjs from "dayjs"
import {localizePlural} from "./localize-plural"

export const useSubscriptionDaysLeft = (profile?: ProfileResponse) => {
    if (!profile) {
        return ""
    }

    if (!profile.account.subscriptionEnd) {
        return "Подписка не оформлена"
    }

    const currentPeriodEnd = dayjs(profile.account.subscriptionEnd)
    const currentDate = dayjs()

    if (currentPeriodEnd.isBefore(currentDate)) {
        return "Подписка закончилась"
    }

    const daysLeft = currentPeriodEnd.diff(currentDate, "day")
    const isRecurring = !!profile.account.autoPayment

    const pluralForm = localizePlural({number: daysLeft, one: "день", few: "дня", many: "дней"})

    if (isRecurring) {
        return `До окончания периода подписки осталось ${daysLeft} ${pluralForm}`
    }

    return `До окончания действия подписки осталось ${daysLeft} ${pluralForm}`
}
