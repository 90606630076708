import React, {useCallback, useEffect, useState} from "react"
import {useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {
    researchSettingsActions,
    selectForms,
    selectFormIdManuallyChanged,
} from "../../../shared/store/researchSettings"
import {
    useGetSubjectDataQuery,
    useLazyGetCompetenceDataQuery,
    useLazyGetComponentCompetenceDataQuery,
    useLazyGetSkillDataQuery,
} from "../../../shared/api/endpoints/researchSettingsEndpoints"
import {Box, Checkbox, FormControlLabel} from "@mui/material"
import {CustomSelect} from "./Select/Select"
import {IForm} from "../../../shared/store/researchSettings/types"
import styles from "./ResearchSettingsForm.module.css"
import {AddFormButton} from "../AddFormButton/AddFormButton"
import {ButtonWithTooltip} from "../../../shared/components"
import {faTrashCan} from "@fortawesome/free-solid-svg-icons"

interface IResearchSettingsForm {
    form: IForm
}

const ResearchSettingsForm = ({form}: IResearchSettingsForm) => {
    const {id, subject, competence, ccompetence, skill, complexity, valid} = form
    const dispatch = useDispatch()
    const forms = useSelector(selectForms)
    const lastFormIdChangedManually = useSelector(selectFormIdManuallyChanged)
    const isFirstForm = id === 0
    const isLastOrTheOnlyForm = id === forms[forms.length - 1].id
    const [lazyGetCompetenceData, {currentData: currentCompetenceData}] =
        useLazyGetCompetenceDataQuery()
    const [lazyGetComponentCompetenceData, {currentData: currentComponentCompetenceData}] =
        useLazyGetComponentCompetenceDataQuery()
    const [lazyGetSkillData, {currentData: currentSkillData}] = useLazyGetSkillDataQuery()
    const {currentData: currentSubjectData} = useGetSubjectDataQuery()
    const {removeForm, editFormItem, editFormItemManually} = researchSettingsActions
    const {register, watch, setValue} = useForm()
    const [excludedSkills, setExcludedSkills] = useState<string[]>([])
    const [allSkillsExcluded, setAllSkillsExcluded] = useState<boolean>(false)

    useEffect(() => {
        const excludedSkillsSet: Set<string> = new Set<string>()
        for (let i = 0; i < forms.length; i++) {
            let form = forms[i]
            // Don't take exclusions from myself
            if (form.id === id) {
                continue
            }

            // Don't take exclusions from incomplete forms
            if (form.skill === null) {
                continue
            }

            // When I'm filling my form - it should have at least ccomp filled to get exclusions
            if (ccompetence === null) {
                continue
            }

            // Basic case - other form selects everything the same as we did, but the skill
            // Exclude this skill from our dropdown
            if (form.ccompetence === ccompetence && form.ccompetence !== "all") {
                excludedSkillsSet.add(form.skill) // cab be either "all" or an exact skill
                continue
            }

            // Now go the "conflicting cases", other form(s) have higher level "all" selected

            // E.g. some other form (form2) has changed from
            // [FORM 2      ] SubjA -> CompB -> CCompC -> SkillD2
            // [FORM 2      ] to
            // [FORM 2      ] SubjA -> CompB -> All -> All
            // and it now collides with our form (form1):
            // [FORM 1, ours] SubjA -> CompB -> CCompC -> SkillD1 / All
            // [FORM 1, ours] => so we have to clear *our* form:
            // [FORM 1, ours] SubjA -> CompB -> null -> null

            // Another e.g. form2 has changed from
            // [FORM 2      ] SubjA -> CompB -> null -> null
            // [FORM 2      ] to
            // [FORM 2      ] SubjA -> CompB -> All -> All
            // and it now collides with our form (form1):
            // [FORM 1, ours] SubjA -> CompB -> All -> All
            // [FORM 1, ours] => so we have to clear *our* form:
            // [FORM 1, ours] SubjA -> CompB -> null -> null

            // In such and other cases - we need to do 2 things:
            // - get exclusion lists
            // - force update the forms to remove some selected options
            // We determine which form was modified last by `lastFormIdChangedManually`
            // and force update only those forms which are not the last modified ones.

            if (form.competence === competence && form.ccompetence === "all") {
                excludedSkillsSet.add(form.skill) // should be "all" or an exact skill

                // Don't update my form from outside, if it's not fully filled
                if (skill === null) {
                    continue
                }
                // Don't update my form if I'm filling it now
                if (lastFormIdChangedManually === id) {
                    continue
                }

                setValue("ccompetence", "")
                setValue("skill", "")
                dispatch(editFormItem({formId: id, field: "ccompetence", value: null}))
                dispatch(editFormItem({formId: id, field: "skill", value: null}))
            }
        }
        setExcludedSkills(Array.from(excludedSkillsSet))
        setAllSkillsExcluded(
            currentSkillData?.length === excludedSkillsSet.size || excludedSkillsSet.has("all")
        )
    }, [forms, lastFormIdChangedManually])

    useEffect(() => {
        const hasAnyComplexity = complexity.length > 0
        const isFormFullyFilled = subject && competence && ccompetence && skill && hasAnyComplexity
        if (isFormFullyFilled) {
            dispatch(editFormItem({formId: id, field: "valid", value: true}))
        } else {
            if (valid) dispatch(editFormItem({formId: id, field: "valid", value: false}))
        }
    }, [subject, competence, ccompetence, skill, complexity])

    const handleSubjectData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await lazyGetCompetenceData(e.target.value)
        if (competence || ccompetence || skill) {
            setValue("competence", "")
            setValue("ccompetence", "")
            setValue("skill", "")
            dispatch(editFormItem({formId: id, field: "competence", value: null}))
            dispatch(editFormItem({formId: id, field: "ccompetence", value: null}))
            dispatch(editFormItem({formId: id, field: "skill", value: null}))
        }
        setValue("subject", e.target.value)
        dispatch(editFormItemManually({formId: id, field: "subject", value: e.target.value}))
    }
    const handleCompetenceData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        await lazyGetComponentCompetenceData(e.target.value)
        if (ccompetence || skill) {
            setValue("ccompetence", "")
            setValue("skill", "")
            dispatch(editFormItem({formId: id, field: "ccompetence", value: null}))
            dispatch(editFormItem({formId: id, field: "skill", value: null}))
        }
        setValue("competence", e.target.value)
        dispatch(editFormItemManually({formId: id, field: "competence", value: e.target.value}))
    }
    const handleComponentCompetenceData = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "all") {
            if (skill) {
                setValue("skill", "")
                dispatch(editFormItem({formId: id, field: "skill", value: null}))
            }
            // TODO move to the bottom
            await lazyGetSkillData(e.target.value)
        } else {
            setValue("skill", "all")
            dispatch(editFormItem({formId: id, field: "skill", value: "all"}))
        }
        setValue("ccompetence", e.target.value)
        dispatch(editFormItemManually({formId: id, field: "ccompetence", value: e.target.value}))
    }

    const handleSkillData = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue("skill", e.target.value)
        dispatch(editFormItemManually({formId: id, field: "skill", value: e.target.value}))
    }

    const handleComplexityCheckbox = useCallback(() => {
        const complexityCheckboxes = watch([
            "complexity_easy",
            "complexity_medium",
            "complexity_hard",
        ])
        const checkedComplexityCheckboxes = complexityCheckboxes.filter(Boolean)
        const isEasyAndHardChecked = ["easy", "hard"].every((condition) =>
            checkedComplexityCheckboxes.includes(condition)
        )
        if (isEasyAndHardChecked) {
            dispatch(
                editFormItem({formId: id, field: "complexity", value: ["easy", "medium", "hard"]})
            )
        } else {
            dispatch(
                editFormItem({formId: id, field: "complexity", value: checkedComplexityCheckboxes})
            )
        }
    }, [complexity])

    return (
        <div className={styles.wrapper}>
            {!isFirstForm && (
                <div className={styles.close_wrapper}>
                    <h5>Дополнительная диагностика</h5>
                    <ButtonWithTooltip
                        onClick={() => dispatch(removeForm(id))}
                        icon={faTrashCan}
                        text="Отказаться от дополнительной диагностики"
                    />
                </div>
            )}
            <form className={styles.form}>
                <CustomSelect
                    id={`${id}`}
                    value={subject || ""}
                    placeholder="Область компетенции"
                    disabled={!currentSubjectData}
                    optionList={currentSubjectData || []}
                    {...register("subject", {
                        required: true,
                        onChange: handleSubjectData,
                    })}
                />
                <CustomSelect
                    id={`${id}`}
                    value={competence || ""}
                    placeholder="Компетенция"
                    disabled={!currentCompetenceData || !subject}
                    optionList={currentCompetenceData || []}
                    {...register("competence", {
                        required: true,
                        onChange: handleCompetenceData,
                    })}
                />
                <CustomSelect
                    id={`${id}`}
                    value={ccompetence || ""}
                    withAllOption
                    placeholder="Компонент компетенции"
                    disabled={!currentComponentCompetenceData || !competence}
                    optionList={currentComponentCompetenceData || []}
                    {...register("ccompetence", {
                        required: true,
                        onChange: handleComponentCompetenceData,
                    })}
                />
                <CustomSelect
                    id={`${id}`}
                    value={skill || ""}
                    withAllOption={ccompetence === "all" || excludedSkills.length === 0}
                    placeholder={
                        allSkillsExcluded ? "Вы уже выбрали все знания/умения" : "Знание/умение"
                    }
                    disabled={
                        ccompetence === "all" ||
                        !currentSkillData ||
                        !ccompetence ||
                        allSkillsExcluded
                    }
                    optionList={
                        currentSkillData?.filter((skill) => !excludedSkills.includes(skill.id)) ||
                        []
                    }
                    {...register("skill", {
                        required: true,
                        onChange: handleSkillData,
                    })}
                />
                <div className={styles.complexity}>
                    <Box component="fieldset" className={styles.checkbox_box}>
                        <legend className={styles.checkbox_box_legend}>Уровни сложности</legend>
                        <div className={styles.checkbox_wrapper}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...register("complexity_easy", {
                                            onChange: handleComplexityCheckbox,
                                        })}
                                    />
                                }
                                label="НАЧАЛЬНЫЙ"
                                value="easy"
                                checked={complexity.includes("easy")}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...register("complexity_medium", {
                                            onChange: handleComplexityCheckbox,
                                        })}
                                    />
                                }
                                label="СРЕДНИЙ"
                                value="medium"
                                checked={complexity.includes("medium")}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...register("complexity_hard", {
                                            onChange: handleComplexityCheckbox,
                                        })}
                                    />
                                }
                                label="ПРОДВИНУТЫЙ"
                                value="hard"
                                checked={complexity.includes("hard")}
                            />
                        </div>
                    </Box>
                    {isLastOrTheOnlyForm && (
                        <div style={{paddingTop: "0.35em"}}>
                            <AddFormButton />
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default ResearchSettingsForm
