import {FC} from "react"
import {UnfinishedResearchCard} from "../../../../shared/components"
import {useGetErascopeInstitutionInfoQuery} from "../../../../shared/api/endpoints/userEndpoints"
import {UnfinishedExaminationsResponse} from "../../types"
import DefaultInstitutionIcon from "./default_institution.jpg"
import dayjs from "dayjs"

interface UnfinishedResearchCardAsyncProps {
    title: string
    subtitle: string
    gradeSet: {era_grade_set_id: string; expected_finish_datetime: string}
    cards: number
    checkedSkills: UnfinishedExaminationsResponse["progress_bar_checked_skills"]
    totalCheckedSkills: UnfinishedExaminationsResponse["progress_bar_total_skills"]
    startTime: UnfinishedExaminationsResponse["start_time"]
    onClick: () => void
    finished: boolean
}

export const UnfinishedExamCardAsync: FC<UnfinishedResearchCardAsyncProps> = (props) => {
    const {
        title,
        subtitle,
        gradeSet,
        cards,
        checkedSkills,
        totalCheckedSkills,
        startTime,
        onClick,
        finished,
    } = props
    const {currentData: institutionInfo} = useGetErascopeInstitutionInfoQuery(
        gradeSet.era_grade_set_id
    )
    return institutionInfo ? (
        <UnfinishedResearchCard
            onClick={onClick}
            title={title}
            subtitle={subtitle}
            cards={cards}
            totalCheckedSkills={totalCheckedSkills}
            checkedSkills={checkedSkills}
            startTime={startTime}
            institutionName={institutionInfo[0].short_name}
            institutionIcon={institutionInfo[0].image || DefaultInstitutionIcon}
            expectedFinishTime={dayjs(gradeSet.expected_finish_datetime).format(
                "DD/MM/YYYY, HH:mm"
            )}
            finished={finished}
        />
    ) : null
}
