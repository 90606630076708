// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonWithTooltip_button__3hMU5 {\n  width: 50px;\n  height: 50px;\n  font-size: 16px;\n  cursor: pointer;\n  display: flex;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n  padding: 0 17px;\n  border-radius: 5px;\n  background: white;\n  transition: opacity 0.3s ease-in;\n  opacity: 50%;\n}\n.ButtonWithTooltip_button__3hMU5 p {\n  display: none;\n}\n.ButtonWithTooltip_button__3hMU5:hover {\n  opacity: 100%;\n  width: auto;\n  justify-content: space-between;\n}\n.ButtonWithTooltip_button__3hMU5:hover p{\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/ButtonWithTooltip/ButtonWithTooltip.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,eAAe;EACf,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,gCAAgC;EAChC,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,cAAc;AAChB","sourcesContent":[".button {\n  width: 50px;\n  height: 50px;\n  font-size: 16px;\n  cursor: pointer;\n  display: flex;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n  padding: 0 17px;\n  border-radius: 5px;\n  background: white;\n  transition: opacity 0.3s ease-in;\n  opacity: 50%;\n}\n.button p {\n  display: none;\n}\n.button:hover {\n  opacity: 100%;\n  width: auto;\n  justify-content: space-between;\n}\n.button:hover p{\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ButtonWithTooltip_button__3hMU5"
};
export default ___CSS_LOADER_EXPORT___;
