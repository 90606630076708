import {InstitutionsResponse} from "../../../widgets/Navbar/types"
import HttpApi from "../../api/HttpApi"

export const getInstitutionUserId = async (institutionId: string) => {
    const response = await HttpApi.get<{
        success: boolean
        institutions: InstitutionsResponse[]
    }>("/backend/apiAccount/users")

    if (response.data.success) {
        const institutions = response.data.institutions
        return institutions.find((institution) => institution._id === institutionId)?.userId || ""
    }

    return ""
}
