import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query"
import AccessToken from "./AccessToken"
import {API_URL} from "./HttpApi"

export const baseQuery = async (args: any, api: any, extraOptions: any) => {
    const query = await fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, {getState}) => {
            const token = AccessToken()
            if (token) {
                headers.set("authorization", `bearer ${token}`)
            }
            headers.set("accept", "application/json")
            return headers
        },
    })(args, api, extraOptions)

    return query
}
