// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResearchConclusionForm_text__cfWZ- {\n  margin: 10px 0;\n  font-weight: 500;\n  font-size: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/ResearchConclusionForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".text {\n  margin: 10px 0;\n  font-weight: 500;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "ResearchConclusionForm_text__cfWZ-"
};
export default ___CSS_LOADER_EXPORT___;
