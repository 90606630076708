// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* free vs paid, etc. - subscription label types */\n\n.getClassnameForTitle_request__yCK-S {\n    color: #d1a900;\n}\n\n.getClassnameForTitle_free__EpCMr {\n    color: #93c47d;\n}\n\n.getClassnameForTitle_subscription__qzscW {\n    color: #e91e63;\n}\n\n.getClassnameForTitle_paid__42Ll0 {\n    color: #ff00fd;\n}\n", "",{"version":3,"sources":["webpack://./src/shared/helpers/getClassnameForTitle/getClassnameForTitle.module.css"],"names":[],"mappings":"AAAA,kDAAkD;;AAElD;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* free vs paid, etc. - subscription label types */\n\n.request {\n    color: #d1a900;\n}\n\n.free {\n    color: #93c47d;\n}\n\n.subscription {\n    color: #e91e63;\n}\n\n.paid {\n    color: #ff00fd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"request": "getClassnameForTitle_request__yCK-S",
	"free": "getClassnameForTitle_free__EpCMr",
	"subscription": "getClassnameForTitle_subscription__qzscW",
	"paid": "getClassnameForTitle_paid__42Ll0"
};
export default ___CSS_LOADER_EXPORT___;
