// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssignExamPage_modal__uhmYd > div {\n  width: 900px;\n  min-height: 100%;\n  border-radius: 0;\n  margin: 0;\n}\n\n.AssignExamPage_subtitle__5hELd {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/pages/AssignExamPage/AssignExamPage.module.scss"],"names":[],"mappings":"AACG;EACA,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,SAAA;AADH;;AAIA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AADF","sourcesContent":[".modal {\n  \t> div {\n\t  width: 900px;\n\t  min-height: 100%;\n\t  //min-height: 620px;\n\t  border-radius: 0;\n\t  margin: 0;\n  \t}\n}\n.subtitle {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "AssignExamPage_modal__uhmYd",
	"subtitle": "AssignExamPage_subtitle__5hELd"
};
export default ___CSS_LOADER_EXPORT___;
