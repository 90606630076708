// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPage_container__eujA9 {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: #444;\n  min-height: 100vh;\n  padding: 10px;\n}", "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,8BAAA;EACG,sBAAA;EACD,iBAAA;EACC,aAAA;AACJ","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-between;\n    background-color: #444;\n  \tmin-height: 100vh;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MainPage_container__eujA9"
};
export default ___CSS_LOADER_EXPORT___;
