import React, {FC, ReactNode} from "react"
import {getClassnameForTitle} from "../../helpers/getClassnameForTitle/getClassnameForTitle"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSquarePollHorizontal} from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import styles from "./RecommendationItem.module.scss"
import {AccessTypes} from "./RecommendationsTooltip/types"
import {getTextFromAccessType} from "../../helpers/getTextFromAccessType"
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons/faBuildingColumns"

interface RecommendationsItemProps {
    image: string
    orgImage: string
    orgName: string
    lessonTitle: string
    accessType: AccessTypes
    rating: string | null
    className?: string
    tooltipClassName?: string
    tooltip: ReactNode
    onHover: () => void
    cardClassName?: string
}

export const RecommendationsItem: FC<RecommendationsItemProps> = (props) => {
    const {
        image,
        orgImage,
        orgName,
        lessonTitle,
        accessType,
        rating,
        className,
        tooltipClassName,
        tooltip,
        onHover,
        cardClassName,
    } = props
    const russianAccessType: string = getTextFromAccessType(accessType)
    return (
        <div onMouseEnter={onHover} className={classNames(styles.wrapper, className)}>
            <div className={classNames(styles.card, cardClassName)}>
                <img className={styles.image} src={image} alt={lessonTitle} />
                <div className={styles.card_details}>
                    <div className={styles.card_info}>
                        <div className={styles.org}>
                            {orgImage ? (
                                <img src={orgImage} alt={orgName} />
                            ) : (
                                <FontAwesomeIcon
                                    size="2xl"
                                    className={styles.icon}
                                    icon={faBuildingColumns}
                                />
                            )}
                            <p>{orgName}</p>
                        </div>
                        <div className={styles.lesson_title}>
                            <h5>{lessonTitle}</h5>
                        </div>
                        <div
                            className={classNames(
                                styles.access,
                                getClassnameForTitle(russianAccessType)
                            )}>
                            {russianAccessType.toUpperCase()}
                            {/*<FontAwesomeIcon icon={faSquarePollHorizontal} />*/}
                            {/*{rating}*/}
                        </div>
                    </div>
                </div>
                {tooltip && (
                    <div className={classNames(styles.tooltip, tooltipClassName)}>{tooltip}</div>
                )}
            </div>
        </div>
    )
}
