// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecommendationsSection_title__xDOHf {\n  font-size: 1rem;\n  margin-bottom: 10px;\n  font-weight: 500;\n  line-height: 1.2;\n  color: #ffffff;\n}\n\n.RecommendationsSection_recommendations__1luRZ {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 10px 0;\n}\n\n.RecommendationsSection_recommendation__DOUQh {\n  width: 50%;\n  height: 350px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/RecommendationsSection/RecommendationsSection.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AACA;EACE,aAAA;EACA,eAAA;EACA,WAAA;EACA,WAAA;AAEF;;AAAA;EACE,UAAA;EACA,aAAA;AAGF","sourcesContent":[".title\t{\n  font-size: 1rem;\n  margin-bottom: 10px;\n  font-weight: 500;\n  line-height: 1.2;\n  color: #ffffff;\n}\n.recommendations {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 10px 0;\n}\n.recommendation {\n  width: 50%;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "RecommendationsSection_title__xDOHf",
	"recommendations": "RecommendationsSection_recommendations__1luRZ",
	"recommendation": "RecommendationsSection_recommendation__DOUQh"
};
export default ___CSS_LOADER_EXPORT___;
