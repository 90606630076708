import {useState} from "react"
import {RecommendationsTooltip} from "../../shared/components/RecommendationItem/RecommendationsTooltip/RecommendationsTooltip"
import {RecommendationsItem} from "../../shared/components"
import {CourseDescriptionResponse} from "../../pages/MainPage/Recommendations/types"
import {IRecommendationResponse} from "../../shared/store/researchSettings/types"
import {useLazyGetTooltipQuery} from "../../shared/api/endpoints/userEndpoints"
import {getFullName} from "../../shared/helpers/getFullName"
import {TooltipRequest} from "../../shared/components/RecommendationItem/RecommendationsTooltip/types"

interface RecommendationItemWithTooltipProps {
    className?: string
    cardClassName?: string
    recommendation: CourseDescriptionResponse
    tooltipType: IRecommendationResponse["type"]
    tooltipId: IRecommendationResponse["id"]
}

export const RecommendationItemWithTooltip = ({
    className,
    recommendation,
    tooltipType,
    tooltipId,
    cardClassName,
}: RecommendationItemWithTooltipProps) => {
    const [tooltipData, setTooltipData] = useState<TooltipRequest | undefined>(undefined)
    const [lazyGetTooltipQuery] = useLazyGetTooltipQuery()

    const onHoverHandler = async () => {
        if (tooltipData) return
        const {data} = await lazyGetTooltipQuery({
            by: tooltipType,
            id: tooltipId,
        })
        setTooltipData(data)
    }

    return (
        <RecommendationsItem
            className={className}
            cardClassName={cardClassName}
            onHover={onHoverHandler}
            image={recommendation.image}
            orgImage={recommendation.institution.image}
            orgName={recommendation.institution.short_name}
            lessonTitle={recommendation.name}
            accessType={recommendation.access_type}
            rating={recommendation.rating}
            tooltip={
                tooltipData ? (
                    <RecommendationsTooltip
                        access_type={tooltipData.access_type}
                        duration={tooltipData.duration}
                        finishedPupil={tooltipData.qty_finished_pupil}
                        price={tooltipData.price}
                        annotation={tooltipData.short_annotation}
                        name={tooltipData.name}
                        summary={tooltipData.summary}
                        ageLimit={tooltipData.age_limit}
                        teacherImage={tooltipData.teachers[0]?.image}
                        teacherRole={tooltipData.teachers[0]?.role}
                        teacher={getFullName(tooltipData.teachers[0])}
                        author={getFullName(tooltipData.author)}
                        institutionId={tooltipData.institution_id}
                        institutionName={tooltipData.institution_short_name}
                        userId={tooltipData.institution_user_id}
                    />
                ) : (
                    <></>
                )
            }
        />
    )
}
