import {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {ResearchConclusionForm} from "./ResearchConclusionForm/ResearchConclusionForm"
import {Modal} from "../../shared/components/Modal/Modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp"
import classNames from "classnames"
import styles from "./ResearchConclusionPage.module.scss"

interface ResearchConclusionPageProps {
    iframe?: boolean
}

export const ResearchConclusionPage = ({iframe}: ResearchConclusionPageProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search)
    const pupilId = query.get("pupilId")
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false)
    useEffect(() => {
        document.title = "ЦИП: Результаты диагностики"
        const modal = document.getElementById("conclusion") as HTMLElement
        const showTopBtnHandler = () => {
            if (modal.scrollTop > 100) {
                setShowScrollButton(true)
            } else {
                setShowScrollButton(false)
            }
        }
        modal.addEventListener("scroll", showTopBtnHandler)
        return () => modal.removeEventListener("scroll", showTopBtnHandler)
    }, [])

    const scrollToTop = () => {
        const modal = document.getElementById("conclusion") as HTMLElement
        modal.scrollTo({top: 0, behavior: "smooth"})
    }
    const handleCloseModal = () => navigate("/cipui/cip_dashboard")

    return (
        <>
            {showScrollButton && !pupilId && (
                <div onClick={scrollToTop} className={styles.button}>
                    На верх
                    <FontAwesomeIcon size="lg" icon={faArrowUp} />
                </div>
            )}
            <Modal
                id="conclusion"
                modalTitle="Диагностика"
                onClose={iframe ? () => {} : handleCloseModal}
                hideCloseIcon={!!pupilId || iframe}
                className={classNames({[styles.modal]: pupilId || iframe})}>
                <ResearchConclusionForm pupilId={pupilId} />
            </Modal>
        </>
    )
}
