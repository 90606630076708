interface LocalizeNumberArgs {
    number: number
    one?: string
    few?: string
    many?: string
}

export const localizePlural = ({number, one, few, many}: LocalizeNumberArgs) => {
    const formatter = new Intl.PluralRules(undefined, {
        type: "ordinal",
    })

    const pluralCategory = formatter.select(number)

    switch (pluralCategory) {
        case "one":
            return one
        case "few":
            return few
        case "many":
            return many
        default:
            return many
    }
}
