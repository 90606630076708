import {useDispatch, useSelector} from "react-redux"
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus"
import {researchSettingsActions, selectForms} from "../../../shared/store/researchSettings"
import {ButtonWithTooltip} from "../../../shared/components"

interface AddFormButtonProps {
    buttonText?: string
}

export const AddFormButton = ({buttonText = "Добавить диагностику"}: AddFormButtonProps) => {
    const dispatch = useDispatch()
    const forms = useSelector(selectForms)
    const {addForm} = researchSettingsActions
    const nextFormId = forms[forms.length - 1].id + 1

    const handleAddForm = () => {
        dispatch(
            addForm({
                id: nextFormId,
                subject: null,
                competence: null,
                ccompetence: null,
                skill: null,
                complexity: [],
                valid: false,
            })
        )
    }

    return <ButtonWithTooltip onClick={handleAddForm} icon={faPlus} text={buttonText} />
}
