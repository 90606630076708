import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import ResearchSettingsForm from "./ResearchSettingsForm/ResearchSettingsForm"
import {useDispatch, useSelector} from "react-redux"
import {researchSettingsActions, selectForms} from "../../shared/store/researchSettings"
import {StartTestButton} from "./StartTestButton/StartTestButton"
import {Modal} from "../../shared/components/Modal/Modal"
import styles from "./ResearchSettingsPage.module.css"

const ResearchSettingsPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const forms = useSelector(selectForms)
    const {resetForms} = researchSettingsActions

    useEffect(() => {
        document.title = "ЦИП: Начало диагностики"
        return () => {
            dispatch(resetForms())
        }
    }, [])

    const handleCloseModal = () => navigate("/cipui/cip_dashboard")

    return (
        <Modal modalTitle="Диагностика" onClose={handleCloseModal}>
            <>
                <h5 className={styles.subtitle}>Пожалуйста задайте параметры диагностики</h5>
                {forms.map((form) => (
                    <ResearchSettingsForm key={form.id} form={form} />
                ))}
                <StartTestButton />
            </>
        </Modal>
    )
}

export default ResearchSettingsPage
