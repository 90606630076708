import {combineReducers} from "@reduxjs/toolkit"
import {researchSettingsReducer} from "./researchSettings"
import {researchConclusionReducer} from "./researchConslusion"
import {mappingSliceReducer} from "./mapping"
import {apiEndpoints} from "../api/endpoints/apiEndpoints"

const rootReducer = combineReducers({
    researchSettingsReducer,
    researchConclusionReducer,
    mappingSliceReducer,
    [apiEndpoints.reducerPath]: apiEndpoints.reducer,
})

export type RootReducerType = ReturnType<typeof rootReducer>

export default rootReducer
