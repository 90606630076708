import {apiEndpoints} from "./apiEndpoints"
import {
    URL_GET_TEST_CONCLUSION_FMT,
    URL_GET_TEST_CONCLUSION_DEFICIT_FMT,
    URL_GET_TEST_CONCLUSION_CHART_FMT,
    URL_GET_TEST_CONCLUSION_HISTORY_FMT,
    URL_GET_USER_HISTORY,
    URL_GET_RECOMMENDED_ERA_IDS_ALL_TYPES_BY_CONCLUSION_DEFICIT,
    URL_GET_ERA_DESCRIPTIONS_BY,
    URL_GET_ERA_RESEARCH_RESULT,
    URL_GET_ERA_RESEARCH_CHART_FOR_GRADESET,
} from "../requestUrl"
import {
    IConclusionResponse,
    IConclusionDeficitResponse,
    IConclusionChartResponse,
    IConclusionHistoryResponse,
} from "../../store/researchSettings/types"
import {RecommendationIdWithType, ISliderSubject} from "../../store/researchConslusion"
import {CourseDescriptionResponse} from "../../../pages/MainPage/Recommendations/types"

export const researchConclusionEndpoints = apiEndpoints.injectEndpoints({
    endpoints: (build) => ({
        getTestConclusion: build.query<IConclusionResponse, string>({
            query: (sessionId) => ({
                url: URL_GET_TEST_CONCLUSION_FMT(sessionId),
                method: "GET",
            }),
        }),
        getTestConclusionDeficit: build.query<
            IConclusionDeficitResponse,
            {selectValue: string; pupilId: string | null}
        >({
            query: ({selectValue, pupilId}) => ({
                url: URL_GET_TEST_CONCLUSION_DEFICIT_FMT(selectValue, pupilId),
                method: "GET",
            }),
        }),
        getTestConclusionChart: build.query<
            IConclusionChartResponse[],
            {sessionId: string | undefined; selectValue: string | undefined}
        >({
            query: ({sessionId, selectValue}) => ({
                url: URL_GET_TEST_CONCLUSION_CHART_FMT(sessionId, selectValue),
                method: "GET",
            }),
        }),
        getTestConclusionHistory: build.query<
            IConclusionHistoryResponse[],
            {sessionId: string | undefined; selectValue: string | undefined}
        >({
            query: ({sessionId, selectValue}) => ({
                url: URL_GET_TEST_CONCLUSION_HISTORY_FMT(sessionId, selectValue),
                method: "GET",
            }),
        }),
        getTestConclusionUserHistory: build.query<ISliderSubject[], string | null>({
            query: (pupilId) => ({
                url: URL_GET_USER_HISTORY(pupilId),
                method: "GET",
            }),
        }),
        getEraConclusionRecommendations: build.query<
            RecommendationIdWithType[],
            {params: string; pupilId: string | null}
        >({
            query: ({params, pupilId}) => ({
                url: URL_GET_RECOMMENDED_ERA_IDS_ALL_TYPES_BY_CONCLUSION_DEFICIT(params, pupilId),
                method: "GET",
            }),
        }),
        getEraCoursesModulesLessonsShortInfoByIdTypePairs: build.query<
            CourseDescriptionResponse,
            {by: "subjects" | "modules" | "lessons"; ids: string}
        >({
            query: ({by, ids}) => ({
                url: URL_GET_ERA_DESCRIPTIONS_BY(by, ids),
                method: "GET",
            }),
        }),
        getEraResearchResult: build.query<ISliderSubject[], string>({
            query: (gradesetId) => ({
                url: URL_GET_ERA_RESEARCH_RESULT(gradesetId),
                method: "GET",
            }),
        }),
        getEraResearchChartResult: build.query<
            IConclusionChartResponse[],
            {sessionId: string; selectValue: string}
        >({
            query: ({sessionId, selectValue}) => ({
                url: URL_GET_ERA_RESEARCH_CHART_FOR_GRADESET(sessionId, selectValue),
                method: "GET",
            }),
        }),
    }),
})

export const {
    useGetTestConclusionQuery,
    useLazyGetTestConclusionDeficitQuery,
    useLazyGetTestConclusionChartQuery,
    useLazyGetTestConclusionHistoryQuery,
    useLazyGetTestConclusionUserHistoryQuery,
    useLazyGetEraConclusionRecommendationsQuery,
    useLazyGetEraCoursesModulesLessonsShortInfoByIdTypePairsQuery,
    useGetEraResearchResultQuery,
    useGetEraResearchChartResultQuery,
} = researchConclusionEndpoints
