import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faClock,
    faSquarePollHorizontal,
    faEye,
    faUser,
    faGraduationCap,
} from "@fortawesome/free-solid-svg-icons"
import {Button} from "@mui/material"
import {getTextFromAccessType} from "../../../helpers/getTextFromAccessType"
import {AccessTypes, RecommendationsTooltipProps} from "./types"
import classNames from "classnames"
import styles from "./RecommendationsTooltip.module.scss"
import DOMPurify from "dompurify"
import {useLazyGetEraInstitutionQuery} from "../../../api/endpoints/userEndpoints"

export const RecommendationsTooltip = (props: RecommendationsTooltipProps) => {
    const {
        access_type,
        duration,
        finishedPupil,
        price,
        annotation,
        name,
        summary,
        ageLimit,
        author,
        teacherImage,
        teacherRole,
        teacher,
        institutionId,
        institutionName,
        userId,
    } = props
    const [getEraInstitution] = useLazyGetEraInstitutionQuery()

    const navigateToInstitution = async () => {
        // const response = await getEraInstitution(institutionId)
        // // @ts-ignore
        // localStorage.setItem("institution", JSON.stringify(response?.data.institution))
        // window.open(`https://erascope.com/home`, "_blank")

        if (userId) {
            localStorage.setItem("user-id", userId)
            window.open(`https://erascope.com/pupil/institution`, "_blank")
        } else {
            const response = await getEraInstitution(institutionId)
            // @ts-ignore
            localStorage.setItem("institution", JSON.stringify(response?.data.institution))
            localStorage.setItem("guest", "true")
            window.open(`https://erascope.com/pupil/institution`, "_blank")
        }
    }
    return (
        <>
            <div className={styles.header}>
                <div className={styles.statistic_wrapper}>
                    {access_type && (
                        <div
                            className={classNames(styles.access_name, {
                                [styles[access_type]]: access_type,
                            })}>
                            {access_type === AccessTypes.payment
                                ? `${price} ₽`
                                : getTextFromAccessType(access_type)}
                        </div>
                    )}
                    <div className={styles.statistic}>
                        {ageLimit && <div className={styles.access_age}>{ageLimit}+</div>}
                        {duration !== undefined && (
                            <div className={styles.time}>
                                <FontAwesomeIcon size={"sm"} icon={faClock} />
                                {duration} часов
                            </div>
                        )}
                        {/*<div className={styles.rating}>*/}
                        {/*    <FontAwesomeIcon size={"sm"} icon={faSquarePollHorizontal} /> 9,6*/}
                        {/*</div>*/}
                        {finishedPupil !== undefined && (
                            <div className={styles.views}>
                                <FontAwesomeIcon size={"sm"} icon={faGraduationCap} />
                                {finishedPupil}
                            </div>
                        )}
                    </div>
                    {annotation && <p>{annotation}</p>}
                </div>
            </div>
            <div className={styles.content}>
                <button
                    type={"button"}
                    onClick={navigateToInstitution}
                    className={styles.content_button}>
                    <h2>{name}</h2>
                </button>
                {summary && (
                    <p
                        className={styles.summary}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(summary, {
                                FORBID_ATTR: ["style"],
                                ALLOWED_TAGS: [
                                    "p",
                                    "div",
                                    "strong",
                                    "em",
                                    "s",
                                    "cite",
                                    "ul",
                                    "ol",
                                    "li",
                                ],
                            }),
                        }}></p>
                )}
            </div>
            <div className={styles.footer}>
                <div className={styles.footer_teacher}>
                    {teacherImage ? (
                        <img src={teacherImage} alt="" />
                    ) : (
                        <FontAwesomeIcon size={"3x"} icon={faUser} />
                    )}
                    <p>
                        {teacherRole && <span>{teacherRole}</span>}
                        <br />
                        {teacher}
                    </p>
                </div>
                {institutionName && (
                    <button
                        type={"button"}
                        onClick={navigateToInstitution}
                        className={styles.footer_button}>
                        {institutionName}
                    </button>
                )}
                <div className={styles.author}>Автор: {author}</div>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={navigateToInstitution}
                    className={classNames({
                        [styles[`${access_type}_button`]]: access_type,
                    })}
                    fullWidth>
                    На страницу организации
                    {/*{access_type === AccessTypes.trainingApplication*/}
                    {/*    ? "Подать заявку"*/}
                    {/*    : "Пройти обучение"}*/}
                </Button>
            </div>
        </>
    )
}
