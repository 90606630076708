import React from "react"
import {useLocation} from "react-router-dom"
import {Modal} from "../../shared/components/Modal/Modal"
import {
    useGetEraResearchResultQuery,
    useGetEraResearchChartResultQuery,
} from "../../shared/api/endpoints/researchConclusionEndpoints"
import {SliderSection} from "../ResearchConclusionPage/ResearchConclusionForm/SliderSection/SliderSection"
import styles from "./EraResearchResult.module.scss"
import {ConclusionBarChart, Paper} from "../../shared/components"

export const EraResearchResult = () => {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const gradeSetId = query.get("gradeSetId")
    const dropdownValue = query.get("dropdownValue")
    const diagnosticId = query.get("diagnosticId")
    const dropdownJoinedIds = query.get("dropdownJoinedIds") as string
    // @ts-ignore
    const {currentData: sliderData} = useGetEraResearchResultQuery(gradeSetId)
    const {currentData: chartData} = useGetEraResearchChartResultQuery({
        // @ts-ignore
        sessionId: diagnosticId,
        // @ts-ignore
        selectValue: dropdownJoinedIds,
    })

    return (
        <Modal modalTitle="Результаты тестирования" hideCloseIcon className={styles.modal}>
            {chartData && (
                <Paper>
                    <ConclusionBarChart chartData={chartData} />
                </Paper>
            )}
            {sliderData && dropdownValue && (
                <SliderSection
                    data={sliderData}
                    dropdownValue={{
                        value: dropdownJoinedIds,
                        label: dropdownJoinedIds,
                    }}
                />
            )}
        </Modal>
    )
}
