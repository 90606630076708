import {useEffect, useState, ReactNode} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {Paper, ProgressBar} from "../../shared/components"
import {Modal} from "../../shared/components/Modal/Modal"
import {Spinner} from "../../shared/components/Spinner/Spinner"
import {AnswerDataRequest, QuestionDataResponse} from "./types"
import {
    useLazyGetQuestionDataQuery,
    usePostQuestionDataMutation,
} from "../../shared/api/endpoints/userEndpoints"
import styles from "./ResearchPage.module.scss"
import classNames from "classnames"
import {researchConclusionActions} from "../../shared/store/researchConslusion"
import {useDispatch} from "react-redux"

interface PaperItemProps {
    title: string
    background?: string
    children: ReactNode
}
const PaperItem = ({title, background = "#FFFFFF", children}: PaperItemProps) => {
    return (
        <Paper background={background}>
            <div className={styles.title}>{title}</div>
            {children}
        </Paper>
    )
}

interface ResearchPageProps {
    iframe?: boolean
}

export const ResearchPage = ({iframe}: ResearchPageProps) => {
    const {sessionId} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {setResearchFinished, setResearchStarted} = researchConclusionActions
    const [questionData, setQuestionData] = useState<QuestionDataResponse>()
    const [answer, setAnswer] = useState<AnswerDataRequest | undefined>(undefined)
    const [lazyGetQuestionData] = useLazyGetQuestionDataQuery()
    const [postQuestionData] = usePostQuestionDataMutation()

    const handleSetAnswer = (answerId: string, questionId: string) =>
        setAnswer({
            answer_id: answerId,
            question_id: questionId,
        })

    const getQuestionData = async () => {
        if (!sessionId) return
        try {
            const {data, error} = await lazyGetQuestionData(sessionId)
            if (!data) return console.log(error)
            if (data.status === "done") {
                if (iframe) {
                    navigate(`/cipui/research_page/${sessionId}/conclusion`)
                } else {
                    dispatch(setResearchStarted(false))
                    dispatch(setResearchFinished(true))
                    navigate(`/cipui/cip_dashboard/research_page/${sessionId}/conclusion`)
                }
            } else if (data.status === "ok") {
                setQuestionData(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleSendAnswer = async () => {
        if (!answer || !sessionId) return
        try {
            setAnswer(undefined)
            const posted = await postQuestionData({sessionId, body: answer})
            await getQuestionData()
        } catch (e) {
            console.log(e)
        }
    }

    const handleCloseModal = () => navigate("/cipui/cip_dashboard")

    useEffect(() => {
        document.title = "ЦИП: Диагностика"
        getQuestionData()
    }, [])

    return (
        <Modal
            modalTitle="Диагностика"
            onClose={iframe ? () => {} : handleCloseModal}
            hideCloseIcon={iframe}
            className={classNames({[styles.modal]: iframe})}>
            {questionData ? (
                <>
                    <h2 className={styles.navigation}>
                        {questionData.subject_area} / {questionData.competence} /{" "}
                        {questionData.ccompetence}
                    </h2>
                    <ProgressBar
                        progress={
                            (questionData.checked_skills / questionData.skills_to_be_checked) * 100
                        }
                        barHeight="5px"
                        hideTitle
                        hidePercentTitle
                        progressColor="#ff9300"
                    />
                    <PaperItem title="Вопрос:">
                        <div className={styles.subtitle}>{questionData.question_content_html}</div>
                    </PaperItem>
                    <PaperItem title="Варианты ответа:" background="#ffffff8c">
                        <ul className={styles.answers}>
                            {questionData.answers?.map((questionAnswer) => {
                                const {id, content_html} = questionAnswer
                                return (
                                    <li key={id} className={styles.answer}>
                                        <input
                                            className={styles.input}
                                            onChange={(e) =>
                                                handleSetAnswer(
                                                    e.target.id,
                                                    questionData.question_id
                                                )
                                            }
                                            type="radio"
                                            id={id}
                                            name="answer"
                                            checked={id === answer?.answer_id}
                                        />
                                        <label className={styles.label} htmlFor={id}>
                                            {content_html}
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                    </PaperItem>
                    <button className={styles.button} disabled={!answer} onClick={handleSendAnswer}>
                        Следующий вопрос
                    </button>
                </>
            ) : (
                <Spinner className={styles.spinner} />
            )}
        </Modal>
    )
}
