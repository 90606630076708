import React, {ReactNode, useEffect, useState} from "react"
import {faChartSimple} from "@fortawesome/free-solid-svg-icons/faChartSimple"
import {faHouse} from "@fortawesome/free-solid-svg-icons/faHouse"
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconDefinition} from "@fortawesome/fontawesome-svg-core"
import styles from "./Navbar.module.scss"
import HttpApi from "../../shared/api/HttpApi"
import {InstitutionsResponse} from "./types"
import {SearchModal} from "../SearchModal/SearchModal"
import {NavbarMenuItemWithTooltip} from "./NavbarMenuItemWithTooltip"
import {InstitutionsMenuItem} from "./InstitutionsMenuItem"
import {UserMenuItem} from "./UserMenuItem"
import DefaultUserImage from "./defaultUserImage.png"
import {LoginModal} from "../LoginModal/LoginModal"

interface MenuItem {
    tooltip: string
    icon: IconDefinition
    to: string
}

const menuItems: MenuItem[] = [
    {tooltip: "Домашняя страница", icon: faHouse, to: "/cipui/"},
    {tooltip: "Центр самодиагностики", icon: faChartSimple, to: "/cipui/cip_dashboard"},
]

interface NavbarProps {
    children: ReactNode
}
export const Navbar = ({children}: NavbarProps) => {
    const [opened, setOpened] = useState(false)
    const [institutions, setInstitutions] = useState<InstitutionsResponse[]>([])
    useEffect(() => {
        const getInstitutions = async () => {
            const response = await HttpApi.get<{
                success: boolean
                institutions: InstitutionsResponse[]
            }>("/backend/apiAccount/users")
            console.log(response)
            if (response.data.success) {
                setInstitutions(response.data.institutions)
            }
        }
        getInstitutions()
    }, [])
    const isMainPage = window.location.pathname === "/cipui/"
    return (
        <div className={styles.wrapper}>
            <div className={styles.navbar}>
                <UserMenuItem />
                {/*<div className={styles.subscription_time}>*/}
                {/*    180<div>ДНЕЙ ПОДПИСКИ</div>*/}
                {/*</div>*/}
                {menuItems.map((menuItem) => (
                    <NavbarMenuItemWithTooltip
                        key={menuItem.tooltip}
                        to={menuItem.to}
                        tooltip={menuItem.tooltip}>
                        <FontAwesomeIcon size="sm" icon={menuItem.icon} />
                    </NavbarMenuItemWithTooltip>
                ))}
                {/* {isMainPage && (
                    <div onClick={() => setOpened(true)} className={styles.menu_item}>
                        <FontAwesomeIcon size="sm" icon={faMagnifyingGlass} />
                        <div className={styles.tooltip}>Поиск</div>
                    </div>
                )} */}
                <div className={styles.org_menu}>
                    {institutions.length > 0 &&
                        institutions.map((institution) => (
                            <InstitutionsMenuItem
                                key={institution._id}
                                userId={institution.userId}
                                institutionId={institution._id}
                                role={institution.role}
                                name={institution.name}
                                image={institution.image}
                            />
                        ))}
                </div>
            </div>
            <div className={styles.content}>{children}</div>
            {opened && <SearchModal opened={opened} setOpened={setOpened} />}
        </div>
    )
}
interface LoginNavbarProps {
    children: ReactNode
}
export const LoginNavbar = ({children}: LoginNavbarProps) => {
    const [opened, setOpened] = useState(true)
    return (
        <div className={styles.wrapper}>
            <div className={styles.navbar}>
                <div className={styles.menu_item_with_after}>
                    <img className={styles.user_image} src={DefaultUserImage} alt="user avatar" />
                    <div
                        className={styles.tooltip}
                        style={{flexDirection: "column", alignItems: "flex-start"}}>
                        <div className={styles.username}>Войдите в профиль</div>
                        <br />
                        <button
                            type={"button"}
                            className={styles.logout}
                            onClick={() => setOpened(true)}>
                            Войти
                        </button>
                    </div>
                </div>
                <NavbarMenuItemWithTooltip to={"/cipui/login"} tooltip={"Домашняя страница"}>
                    <FontAwesomeIcon size="sm" icon={faHouse} />
                </NavbarMenuItemWithTooltip>
            </div>
            <div className={styles.content}>{children}</div>
            {opened && <LoginModal setOpened={setOpened} />}
        </div>
    )
}
