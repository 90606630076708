// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserDeficits_wrapper__S-uvG {\n  color: #d3d3d3;\n  padding: 30px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  background: #585858;\n  border-radius: 5px;\n}\n.UserDeficits_wrapper__S-uvG h2 {\n  font-size: 30px;\n  padding-bottom: 20px;\n  margin-bottom: 30px;\n  border-bottom: 1px #6c6c6c solid;\n}\n\n.UserDeficits_cards__92\\+I\\+ {\n  display: flex;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://./src/widgets/UserDeficits/UserDeficits.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACD,eAAA;EACA,oBAAA;EACA,mBAAA;EACA,gCAAA;AAED;;AACA;EACC,aAAA;EACA,eAAA;AAED","sourcesContent":[".wrapper {\n  color: #d3d3d3;\n  padding: 30px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  background: #585858;\n  border-radius: 5px;\n  h2 {\n\tfont-size: 30px;\n\tpadding-bottom: 20px;\n\tmargin-bottom: 30px;\n\tborder-bottom: 1px #6c6c6c solid;\n  }\n}\n.cards {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UserDeficits_wrapper__S-uvG",
	"cards": "UserDeficits_cards__92+I+"
};
export default ___CSS_LOADER_EXPORT___;
