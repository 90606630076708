import {RootReducerType} from "../reducers"
import {createSelector} from "@reduxjs/toolkit"

export const selectSubjects = (state: RootReducerType) => state.researchConclusionReducer.subjects
export const selectSubjectsSelected = (state: RootReducerType) =>
    state.researchConclusionReducer.subjectsSelected
export const selectCompetencesSelected = (state: RootReducerType) =>
    state.researchConclusionReducer.competencesSelected
export const selectCcompetencesSelected = (state: RootReducerType) =>
    state.researchConclusionReducer.ccompetencesSelected
export const selectSkillsSelected = (state: RootReducerType) =>
    state.researchConclusionReducer.skillsSelected
export const selectResearchStarted = (state: RootReducerType) =>
    state.researchConclusionReducer.researchStarted
export const selectResearchFinished = (state: RootReducerType) =>
    state.researchConclusionReducer.researchFinished
