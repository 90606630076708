import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import styles from "./ButtonWithTooltip.module.css"

interface IButtonWithTooltip {
    text: string
    icon: IconProp
    onClick?: () => void
}

export const ButtonWithTooltip = ({onClick, text, icon}: IButtonWithTooltip) => {
    return (
        <div onClick={onClick} className={styles.button}>
            <p>{text}</p>
            <FontAwesomeIcon size="lg" icon={icon} />
        </div>
    )
}
