import React, {memo} from "react"
import {A11y, Autoplay, Navigation, Pagination} from "swiper"
import "swiper/swiper-bundle.min.css"
import {Swiper, SwiperSlide} from "swiper/react"
import {
    useGetEraSliderDataQuery,
    useLazyGetEraInstitutionQuery,
} from "../../shared/api/endpoints/userEndpoints"
import {getTextFromAccessType} from "../../shared/helpers/getTextFromAccessType"
import styles from "./Slider.module.scss"
import classNames from "classnames"
import {SliderResponse} from "./types"
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons/faBuildingColumns"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const CurrentSlide = memo((props: SliderResponse) => {
    const {
        subject_name,
        subject_id,
        subject_image,
        institution_image,
        institution_name,
        institution_id,
        duration_days,
        access_type,
        institution_user_role,
        institution_user_id,
    } = props
    const [getEraInstitution] = useLazyGetEraInstitutionQuery()

    const navigateToInstitution = async () => {
        const response = await getEraInstitution(institution_id)
        // @ts-ignore
        localStorage.setItem("institution", JSON.stringify(response?.data.institution))
        localStorage.setItem("user-id", institution_user_id)
        localStorage.setItem("fromSlider", "true")
        window.open(`https://erascope.com/pupil/institution`, "_blank")
    }
    return (
        <div className={styles.container}>
            <img className={styles.image} src={subject_image} alt={subject_name} />
            <div className={styles.description}>
                <h2 className={styles.subject_name}>{subject_name}</h2>
                <div className={styles.info}>
                    <div className={styles.source}>
                        {institution_image ? (
                            <img src={institution_image} alt={institution_name} />
                        ) : (
                            <FontAwesomeIcon
                                size="sm"
                                className={styles.icon}
                                icon={faBuildingColumns}
                            />
                        )}
                        <p>{institution_name}</p>
                        {duration_days !== null && (
                            <div className={styles.duration}>
                                <h2 className={styles.duration_days}>{duration_days}</h2>
                                <div>дней</div>
                            </div>
                        )}
                    </div>
                    <p
                        className={classNames(styles.subscription_type, {
                            [styles[access_type]]: access_type,
                        })}
                        style={{marginTop: duration_days ? 0 : "15px"}}>
                        {getTextFromAccessType(access_type).toUpperCase()}
                    </p>
                </div>
                <button type={"button"} onClick={navigateToInstitution} className={styles.button}>
                    Подробнее
                </button>
            </div>
        </div>
    )
})

export const Slider = () => {
    const {currentData: sliderData} = useGetEraSliderDataQuery()

    return sliderData ? (
        <div className={styles.wrapper}>
            <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{clickable: true}}
                speed={1000}
                loop={true}
                allowTouchMove={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}>
                {sliderData?.map((slide) => (
                    <SwiperSlide key={slide.subject_id}>
                        <CurrentSlide
                            subject_id={slide.subject_id}
                            subject_image={slide.subject_image}
                            subject_name={slide.subject_name}
                            institution_id={slide.institution_id}
                            institution_name={slide.institution_name}
                            institution_image={slide.institution_image}
                            duration_days={slide.duration_days}
                            access_type={slide.access_type}
                            institution_user_role={slide.institution_user_role}
                            institution_user_id={slide.institution_user_id}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    ) : null
}
