export enum AccessTypes {
    free = "free",
    payment = "payment",
    subscription = "subscription",
    trainingApplication = "trainingApplication",
}

export interface RecommendationsTooltipProps {
    access_type: AccessTypes | null
    duration: number | null
    finishedPupil: number | null
    price: number | null
    annotation: string | null
    name: string | null
    summary: string | null
    ageLimit: string | null
    author: string
    teacherImage: string | null
    teacherRole: string | null
    teacher: string
    institutionId: string
    institutionName: string | null
    userId: string
}

export type TooltipBy = "lesson" | "module" | "subject"

export interface Person {
    first_name: string | null
    last_name: string | null
    middle_name: string | null
    image: string | null
}

export interface Teacher extends Person {
    account_id: string | null
    role: string | null
}

export interface TooltipRequest {
    access_type: AccessTypes | null
    duration: number | null
    qty_finished_pupil: number | null
    price: number | null
    name: string | null
    summary: string | null
    author: Person
    teachers: Teacher[]
    institution_id: string
    institution_short_name: string | null
    age_limit: string | null
    short_annotation: string | null
    institution_user_role: string
    institution_user_id: string
}
