import {apiEndpoints} from "./apiEndpoints"
import {
    URL_GET_ERASCOPE_INSTITUTION_INFO,
    URL_GET_TOOLTIP,
    URL_GET_UNFINISHED_EXAMINATIONS,
    URL_GET_ERA_DESCRIPTIONS_BY,
    URL_GET_MAIN_DEFICIT_WITH_SUBJECT_ID,
    URL_GET_RECOMMENDED_ERA_IDS_BY_TYPE,
    URL_CRUD_MAPPING,
    URL_GET_MAPPING_RECOMMENDATION_BY_TYPE,
    URL_GET_OR_POST_QUESTION_DATA,
    URL_GET_ERA_CURRENT_EDUCATION,
    URL_GET_ERA_FIND_INSTITUTIONS,
    URL_GET_ERA_SLIDER,
    URL_LOGIN,
    URL_GET_ERA_PROFILE,
    URL_GET_ERA_GRANTS,
    URL_GET_ERA_INSTITUTION,
} from "../requestUrl"
import {
    CourseDescriptionResponse,
    EraCoursesModulesOrLessonsRecommendations,
} from "../../../pages/MainPage/Recommendations/types"
import {
    ErascopeInstitutionInfoResponse,
    UnfinishedExaminationsResponse,
} from "../../../widgets/UnfinishedResearches/types"
import {
    TooltipBy,
    TooltipRequest,
} from "../../components/RecommendationItem/RecommendationsTooltip/types"
import {ChartData} from "../../../widgets/UserDeficits/types"
import {
    CipStructureLessonItem,
    CipStructureModuleItem,
    CipStructureSubjectItem,
    MappingResponse,
} from "../../store/mapping"
import {EraMappingTypes, IResult} from "../../store/researchSettings/types"
import {EraMappingRequestDataForAny} from "../../helpers/getRequestDataByMappingType"
import {AnswerDataRequest, QuestionDataResponse} from "../../../pages/ResearchPage/types"
import {EraCurrentEducationResponse} from "../../../widgets/CurrentLessonsWidget/CurrentLessonsWidget"
import {InstitutionsResponse, ProfileResponse} from "../../../widgets/Navbar/types"
import {SliderResponse} from "../../../widgets/Slider/types"
import {
    LoginRequest,
    LoginResponse,
    LoginResponseWithError,
} from "../../../widgets/LoginModal/types"

export const userEndpoints = apiEndpoints.injectEndpoints({
    endpoints: (build) => ({
        getEraDescriptions: build.query<
            CourseDescriptionResponse[],
            {by: "subjects" | "modules" | "lessons"; ids: string}
        >({
            query: ({by, ids}) => ({
                url: URL_GET_ERA_DESCRIPTIONS_BY(by, ids),
                method: "GET",
            }),
        }),
        getUnfinishedResearches: build.query<UnfinishedExaminationsResponse[], boolean>({
            query: (isCompleted) => ({
                url: URL_GET_UNFINISHED_EXAMINATIONS(isCompleted),
                method: "GET",
            }),
        }),
        getErascopeInstitutionInfo: build.query<ErascopeInstitutionInfoResponse[], string>({
            query: (gradeset_id) => ({
                url: URL_GET_ERASCOPE_INSTITUTION_INFO,
                method: "GET",
                params: {gradeset_id: gradeset_id},
            }),
        }),
        getTooltip: build.query<TooltipRequest, {by: TooltipBy; id: string}>({
            query: ({by, id}) => ({
                url: URL_GET_TOOLTIP(by, id),
                method: "GET",
            }),
        }),
        getMainDeficit: build.query<ChartData[], void>({
            query: () => ({
                url: URL_GET_MAIN_DEFICIT_WITH_SUBJECT_ID,
                method: "GET",
            }),
        }),
        getDescriptionIds: build.query<
            EraCoursesModulesOrLessonsRecommendations,
            {by: "subjects" | "modules" | "lessons"; query: string; isDeep?: boolean}
        >({
            query: ({by, query, isDeep}) => ({
                url: URL_GET_RECOMMENDED_ERA_IDS_BY_TYPE(by, query, isDeep),
                method: "GET",
            }),
        }),
        getMapping: build.query<
            MappingResponse<// CipStructureSubjectItem | CipStructureModuleItem | CipStructureLessonItem
            CipStructureLessonItem>,
            {by: EraMappingTypes; id: string}
        >({
            query: ({by, id}) => ({
                url: URL_CRUD_MAPPING(by, id),
                method: "GET",
            }),
        }),
        putMapping: build.mutation<
            MappingResponse<
                CipStructureSubjectItem | CipStructureModuleItem | CipStructureLessonItem
            >,
            {
                by: EraMappingTypes
                data: EraMappingRequestDataForAny
                id: string
            }
        >({
            query: ({by, data, id}) => ({
                url: URL_CRUD_MAPPING(by, id),
                method: "PUT",
                body: data,
            }),
        }),
        postMapping: build.mutation<
            MappingResponse<
                CipStructureSubjectItem | CipStructureModuleItem | CipStructureLessonItem
            >,
            {
                by: EraMappingTypes
                data: EraMappingRequestDataForAny
            }
        >({
            query: ({by, data}) => ({
                url: URL_CRUD_MAPPING(by),
                method: "POST",
                body: data,
            }),
        }),
        deleteMapping: build.mutation<IResult<null>, {by: EraMappingTypes; id: string}>({
            query: ({by, id}) => ({
                url: URL_CRUD_MAPPING(by, id),
                method: "DELETE",
            }),
        }),
        getMappingRecommendationByType: build.query<
            MappingResponse<CipStructureLessonItem>,
            {by: Exclude<EraMappingTypes, "course">; id: string}
        >({
            query: ({by, id}) => ({
                url: URL_GET_MAPPING_RECOMMENDATION_BY_TYPE(by, id),
                method: "GET",
            }),
        }),
        getQuestionData: build.query<QuestionDataResponse, string>({
            query: (sessionId) => ({
                url: URL_GET_OR_POST_QUESTION_DATA(sessionId),
                method: "GET",
            }),
        }),
        postQuestionData: build.mutation<
            QuestionDataResponse,
            {sessionId: string; body: AnswerDataRequest}
        >({
            query: ({sessionId, body}) => ({
                url: URL_GET_OR_POST_QUESTION_DATA(sessionId),
                method: "POST",
                body,
            }),
        }),
        getEraCurrentEducation: build.query<EraCurrentEducationResponse[], void>({
            query: () => ({
                url: URL_GET_ERA_CURRENT_EDUCATION(),
                method: "GET",
            }),
        }),
        getEraFindInstitutions: build.query<
            {
                success: boolean
                institutions: InstitutionsResponse[]
            },
            string
        >({
            query: (query) => ({
                url: URL_GET_ERA_FIND_INSTITUTIONS(query),
                method: "GET",
            }),
        }),
        getEraSliderData: build.query<SliderResponse[], void>({
            query: () => ({
                url: URL_GET_ERA_SLIDER(),
                method: "GET",
            }),
        }),
        login: build.mutation<LoginResponse | LoginResponseWithError, LoginRequest>({
            query: (body) => ({
                url: URL_LOGIN(),
                method: "POST",
                body,
            }),
        }),
        getEraProfile: build.query<ProfileResponse, void>({
            query: () => ({
                url: URL_GET_ERA_PROFILE(),
                method: "GET",
            }),
        }),
        getEraGrants: build.query<ProfileResponse, void>({
            query: () => ({
                url: URL_GET_ERA_GRANTS(),
                method: "GET",
            }),
        }),
        getEraInstitution: build.query<ProfileResponse, string>({
            query: (id) => ({
                url: URL_GET_ERA_INSTITUTION(id),
                method: "GET",
            }),
        }),
    }),
})

export const {
    useGetUnfinishedResearchesQuery,
    useGetErascopeInstitutionInfoQuery,
    useGetEraDescriptionsQuery,
    useLazyGetMappingRecommendationByTypeQuery,
    useLazyGetTooltipQuery,
    useLazyGetMainDeficitQuery,
    useLazyGetDescriptionIdsQuery,
    useLazyGetMappingQuery,
    usePutMappingMutation,
    usePostMappingMutation,
    useDeleteMappingMutation,
    useLazyGetQuestionDataQuery,
    usePostQuestionDataMutation,
    useGetEraCurrentEducationQuery,
    useLazyGetEraFindInstitutionsQuery,
    useGetEraSliderDataQuery,
    useLoginMutation,
    useGetEraProfileQuery,
    useLazyGetEraGrantsQuery,
    useLazyGetEraInstitutionQuery,
} = userEndpoints
