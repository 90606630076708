import {useState} from "react"
import {HistorySectionItem} from "./HistorySectionItem/HistorySectionItem"
import {IConclusionHistoryResponse} from "../../../../shared/store/researchSettings/types"
import classNames from "classnames"
import {faAngleUp} from "@fortawesome/free-solid-svg-icons/faAngleUp"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from "./HistorySection.module.css"

interface IHistorySection {
    historyData: IConclusionHistoryResponse[]
}

export const HistorySection = ({historyData}: IHistorySection) => {
    const [opened, setOpened] = useState(false)
    return (
        <div className={styles.wrapper}>
            <div className={styles.title} onClick={() => setOpened(!opened)}>
                История прохождения диагностики
                {opened ? (
                    <FontAwesomeIcon icon={faAngleUp} size={"lg"} />
                ) : (
                    <FontAwesomeIcon icon={faAngleDown} size={"lg"} />
                )}
            </div>
            <div className={classNames(styles.content, {[styles.show]: opened})}>
                {historyData.map((task, index) => (
                    <HistorySectionItem key={index} index={index + 1} task={task} />
                ))}
            </div>
        </div>
    )
}
