import {ReactNode} from "react"
import Erascope from "./assets/erascope.png"
import Skolkovo from "./assets/skolkovo.png"
import LogoFond from "./assets/logofond.png"
import Mik from "./assets/mik.svg"
import Mintsifry from "./assets/mintsifry.svg"
import styles from "./Footer.module.scss"

interface AlinkProps {
    href: string
    children: ReactNode
}
const ALink = ({href, children}: AlinkProps) => (
    <a href={href} target="_blank" rel="noreferrer">
        {children}
    </a>
)

export const Footer = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <div className={styles.erascope}>
                    <ALink href="https://erascope.education/main/templates/index.html">
                        <img src={Erascope} alt="erascope" />
                    </ALink>
                    <p>
                        Национальная платформа <br />
                        онлайн-образования
                    </p>
                </div>
                <div className={styles.links}>
                    <h5>Полезные ссылки</h5>
                    <ALink href="https://erascope.education/main/aboutplatform.html">
                        О платформе
                    </ALink>
                    <ALink href="https://erascope.education/main/blog.html">Статьи</ALink>
                    <ALink href="https://erascope.education/main/docs.html">
                        Политика конфидециальности
                    </ALink>
                    <ALink href="https://erascope.education/main/docs.html">Условия оплаты</ALink>
                    <ALink href="https://erascope.education/main/support.html">
                        Служба поддержки
                    </ALink>
                </div>
                <div className={styles.contacts}>
                    <h5>Связаться с нами</h5>
                    <p>
                        Россия, Москва <br /> Тел: <a href="tel:+74952552248">+7 495 255-22-48</a>
                        <br />
                        Электронная почта: <br />
                        <a href="mailto:info@erascope.education">info@erascope.education</a>
                    </p>
                </div>
            </div>
            <div className={styles.labels}>
                <img src={Skolkovo} alt="skolkovo" />
                <img src={Mik} alt="mik" />
                <img src={Mintsifry} alt="mintsifry" />
                <img src={LogoFond} alt="logofond" />
            </div>
            <div className={styles.copyright}>
                <span>
                    © 2018-2023. ВСЕ ПРАВА ЗАЩИЩЕНЫ. ПРАВООБЛАДАТЕЛЬ ООО "ЭКОСИСТЕМС НЕТВОРК"
                </span>
                <p>Копирование любых материалов возможно только с разрешения правообладателя.</p>
            </div>
        </div>
    )
}
