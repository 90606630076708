import React from "react"
import {useNavigate} from "react-router-dom"
import {useGetEraProfileQuery} from "../../shared/api/endpoints/userEndpoints"
import DefaultUserImage from "./defaultUserImage.png"
import styles from "./Navbar.module.scss"
import {Typography} from "@mui/material"
import {useSubscriptionDaysLeft} from "./utilities/use-subscription-days-left"

export const UserMenuItem = () => {
    const navigate = useNavigate()
    const navigateToUserPage = () => window.open("https://erascope.com/account", "_blank")
    const {currentData: profile} = useGetEraProfileQuery()
    const subscriptionExpiresText = useSubscriptionDaysLeft(profile)

    const fullName = () => {
        if (!profile || !profile.account) return ""
        return `${profile.account.firstName} ${profile.account.lastName}`
    }

    const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        localStorage.removeItem("token")
        localStorage.removeItem("user-id")
        localStorage.removeItem("grants")
        localStorage.removeItem("guest")
        localStorage.removeItem("institution")
        navigate("/cipui/login", {replace: true})
    }

    return (
        <div onClick={navigateToUserPage} className={styles.menu_item_with_after}>
            <img
                className={styles.user_image}
                src={
                    profile?.account.image
                        ? `https://erascope.com/uploaded-avatars/${profile.account.image}`
                        : DefaultUserImage
                }
                alt="user avatar"
            />
            <div
                className={styles.tooltip}
                style={{flexDirection: "column", alignItems: "flex-start"}}>
                <div className={styles.username}> {fullName() || "Имя пользователя"}</div>

                <Typography variant="caption" color="textSecondary" sx={{mt: 1, maxWidth: "170px"}}>
                    {subscriptionExpiresText}
                </Typography>

                <button type={"button"} className={styles.logout} onClick={handleLogout}>
                    Выйти
                </button>
            </div>
        </div>
    )
}
