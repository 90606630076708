import {Paper} from "../../../../shared/components"
import {useParams} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight"
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import React, {useEffect, useMemo, useState} from "react"
import {
    ISliderCcompetence,
    ISliderCompetence,
    ISliderSkills,
    ISliderSubject,
    researchConclusionActions,
    selectCcompetencesSelected,
    selectCompetencesSelected,
    selectSkillsSelected,
    selectSubjects,
    selectSubjectsSelected,
} from "../../../../shared/store/researchConslusion"
import {CompetenceColumn} from "./Columns/CompetenceColumn"
import {useDispatch, useSelector} from "react-redux"
import {SubjectColumn} from "./Columns/SubjectColumn"
import {CcompetenceColumn} from "./Columns/CcompetenceColumn"
import {SkillsColumn} from "./Columns/SkillsColumn"
import styles from "./SliderSection.module.scss"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import {Swiper, SwiperSlide, useSwiper} from "swiper/react"
import {A11y, Navigation, Swiper as SwiperType} from "swiper"
import classNames from "classnames"
import {SliderChart} from "./SliderChart/SliderChart"
import {
    getCcompetenceDataForChart,
    getCompetenceDataForChart,
    getSkillsDataForChart,
    getSubjectDataForChart,
} from "../../../../shared/helpers/dataForChartFormatter/dataForChartFormatter"
import {IDropdownItem} from "../ResearchConclusionForm"

interface ISliderSection {
    data: ISliderSubject[]
    dropdownValue: IDropdownItem
}

export const SliderSection = ({data, dropdownValue}: ISliderSection) => {
    const dispatch = useDispatch()
    const subjects = useSelector(selectSubjects)
    const {sessionId} = useParams()
    const {setSubjects} = researchConclusionActions
    const subjectsSelected = useSelector(selectSubjectsSelected)
    const competencesSelected = useSelector(selectCompetencesSelected)
    const ccompetencesSelected = useSelector(selectCcompetencesSelected)
    const skillsSelected = useSelector(selectSkillsSelected)
    const hasSubjects = subjectsSelected.length > 0
    const hasCompetences = competencesSelected.length > 0
    const hasCcompetences = ccompetencesSelected.length > 0
    const hasSkills = skillsSelected.length > 0
    const [swiper, setSwiper] = useState<SwiperType>()
    const [namesArray, setNamesArray] = useState<string[]>([])
    const [dashedNamesArray, setDashedNamesArray] = useState<string[]>([])
    const [skillsArray, setSkillsArray] = useState<any[]>([])
    const dropdownValuesSplittedArray = useMemo(
        () => dropdownValue.value.split("/"),
        [dropdownValue]
    )
    const skills = useMemo(
        () => ccompetencesSelected.flatMap((ccompetence) => ccompetence.skills),
        [ccompetencesSelected]
    )
    const uniqueSkillsArray = useMemo(() => {
        const skillIdSkillMap = new Map()
        for (let i = 0; i < skills.length; i++) {
            skillIdSkillMap.set(skills[i].skill_id, skills[i])
        }
        return Array.from(skillIdSkillMap.values())
    }, [skills])

    const {
        setSubjectsSelected,
        setCompetencesSelected,
        setCcompetencesSelected,
        setSkillsSelected,
    } = researchConclusionActions

    useEffect(() => {
        const [
            subjectFromDropdownId,
            competenceFromDropdownId,
            ccompetenceFromDropdownId,
            skillFromDropdownId,
        ] = dropdownValuesSplittedArray
        let tmpSubjectItem = undefined
        if (subjectFromDropdownId && subjects.length > 0) {
            const subjectItem = subjects.find(
                (subject) => subject.subject_id === subjectFromDropdownId
            ) as ISliderSubject
            if (subjectItem) {
                tmpSubjectItem = subjectItem
                dispatch(setSubjectsSelected([subjectItem]))
            }
        }
        let tmpCompetenceItem = undefined
        if (competenceFromDropdownId && tmpSubjectItem && subjects.length > 0) {
            const competencesArray = tmpSubjectItem.competences
            const competenceItem = competencesArray.find(
                (competence) => competence.competence_id === competenceFromDropdownId
            ) as ISliderCompetence
            if (competenceItem) {
                tmpCompetenceItem = competenceItem
                dispatch(setCompetencesSelected([competenceItem]))
            }
        }
        let tmpCcompetenceItem = undefined
        if (ccompetenceFromDropdownId && tmpCompetenceItem && subjects.length > 0) {
            const ccompetencesArray = tmpCompetenceItem.ccompetences
            const ccompetenceItem = ccompetencesArray.find(
                (ccompetence) => ccompetence.ccompetence_id === ccompetenceFromDropdownId
            ) as ISliderCcompetence
            if (ccompetenceItem) {
                tmpCcompetenceItem = ccompetenceItem
                dispatch(setCcompetencesSelected([ccompetenceItem]))
            }
        }
        if (skillFromDropdownId && tmpCcompetenceItem && subjects.length > 0) {
            const skillsArray = tmpCcompetenceItem.skills
            const skillItem = skillsArray.find(
                (skill) => skill.skill_id === skillFromDropdownId
            ) as ISliderSkills
            if (skillItem) {
                dispatch(setSkillsSelected([skillItem]))
            }
        }
    }, [dropdownValuesSplittedArray, subjects])

    useEffect(() => {
        if (hasSubjects && hasCompetences && hasCcompetences && hasSkills) {
            const [namesArray, dashedNamesArray, dataForChart] = getSkillsDataForChart(
                ccompetencesSelected,
                skillsSelected
            )
            setNamesArray(namesArray as string[])
            setDashedNamesArray(dashedNamesArray as string[])
            setSkillsArray(dataForChart)
        } else if (hasSubjects && hasCompetences && hasCcompetences && !hasSkills) {
            const [namesArray, dashedNamesArray, dataForChart] =
                getCcompetenceDataForChart(ccompetencesSelected)
            setNamesArray(namesArray as string[])
            setDashedNamesArray(dashedNamesArray as string[])
            setSkillsArray(dataForChart)
        } else if (hasSubjects && hasCompetences && !hasCcompetences && !hasSkills) {
            const [namesArray, dashedNamesArray, dataForChart] =
                getCompetenceDataForChart(competencesSelected)
            setNamesArray(namesArray as string[])
            setDashedNamesArray(dashedNamesArray as string[])
            setSkillsArray(dataForChart)
        } else if (hasSubjects && !hasCompetences && !hasCcompetences && !hasSkills) {
            const [namesArray, dashedNamesArray, dataForChart] =
                getSubjectDataForChart(subjectsSelected)
            setNamesArray(namesArray as string[])
            setDashedNamesArray(dashedNamesArray as string[])
            setSkillsArray(dataForChart)
        } else {
            setNamesArray([])
            setDashedNamesArray([])
            setSkillsArray([])
        }
    }, [skillsSelected, ccompetencesSelected, competencesSelected, subjectsSelected])

    useEffect(() => {
        if (swiper) {
            if (hasSubjects && hasCompetences && hasCcompetences && hasSkills) {
                swiper.slideTo(3, 500)
            }
            if (hasSubjects && hasCompetences && hasCcompetences && !hasSkills) {
                swiper.slideTo(2, 500)
            }
            if (hasSubjects && hasCompetences && !hasCcompetences && !hasSkills) {
                swiper.slideTo(1, 500)
            }
            if (hasSubjects && !hasCompetences && !hasCcompetences && !hasSkills) {
                swiper.slideTo(0, 500)
            }
        }
    }, [hasSubjects, hasCompetences, hasCcompetences, hasSkills, swiper])

    useEffect(() => {
        dispatch(setSubjects(data))
    }, [])

    return (
        <Paper>
            <div style={{position: "relative"}}>
                <Paper background="lightgray">
                    {/*<div className={styles.wrapper}>*/}
                    <Swiper
                        speed={500}
                        modules={[Navigation, A11y]}
                        spaceBetween={10}
                        slidesPerView={2}
                        // navigation
                        navigation={{
                            nextEl: ".navigation_next_arrow",
                            prevEl: ".navigation_prev_arrow",
                        }}
                        // onBeforeInit={(swiper) => {
                        //     swiperRef.current = swiper
                        // }}
                        // pagination={{clickable: true}}
                        // scrollbar={{draggable: true}}
                        onSwiper={(swiper) => setSwiper(swiper)}
                        onSlideChange={() => console.log("slide change")}>
                        <SwiperSlide>
                            <SubjectColumn />
                        </SwiperSlide>
                        <SwiperSlide>
                            {({isActive}) => <CompetenceColumn active={isActive} />}
                        </SwiperSlide>
                        <SwiperSlide>
                            {({isActive}) => <CcompetenceColumn active={isActive} />}
                        </SwiperSlide>
                        <SwiperSlide>
                            {({isActive}) => (
                                <SkillsColumn
                                    active={isActive}
                                    skills={skills}
                                    uniqueSkillsArray={uniqueSkillsArray}
                                />
                            )}
                        </SwiperSlide>
                    </Swiper>
                    <button className={classNames("navigation_prev_arrow", styles.prev)}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button className={classNames("navigation_next_arrow", styles.next)}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    {/*</div>*/}
                </Paper>
            </div>
            {skillsArray.length > 0 && (
                <SliderChart
                    skillsData={skillsArray}
                    namesArray={namesArray}
                    sessionId={sessionId}
                    dashedNamesArray={dashedNamesArray}
                />
            )}
        </Paper>
    )
}
