export function getPlural(numberOrUndef: number | undefined | null, forms2or3: string[]) {
    const numberNormalized = numberOrUndef || 0
    const formsNormalized =
        forms2or3.length == 3 ? forms2or3 : [forms2or3[0], forms2or3[1], forms2or3[1]]

    const mod10 = numberNormalized % 10
    const mod100 = numberNormalized % 100
    if (mod10 === 1 && mod100 !== 11) {
        return formsNormalized[0]
    } else if ([2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100)) {
        return formsNormalized[1]
    } else {
        return formsNormalized[2]
    }
}

export function getNumberAndPlural(numberOrUndef: number | undefined | null, forms2or3: string[]) {
    return `${numberOrUndef || 0} ${getPlural(numberOrUndef, forms2or3)}`
}

export function getHumanReadableTimeHMS(
    time_hms: string,
    minTime_hms: string = "00:00:01",
    emptyStr: string = "0 секунд",
    forms_h: string[] = ["час", "часа", "часов"],
    forms_m: string[] = ["минута", "минуты", "минут"],
    forms_s: string[] = ["секунда", "секунды", "секунд"]
): string {
    let [hours, minutes, seconds] = time_hms.split(":").map(Number)
    if (minTime_hms && hours === 0 && minutes === 0 && seconds === 0) {
        ;[hours, minutes, seconds] = minTime_hms.split(":").map(Number)
    }

    let hoursStr = ""
    if (hours > 0) {
        hoursStr = getNumberAndPlural(hours, forms_h)
    }

    let minutesStr = ""
    if (minutes > 0) {
        minutesStr = getNumberAndPlural(minutes, forms_m)
    }

    let secondsStr = ""
    if (seconds > 0) {
        secondsStr = getNumberAndPlural(seconds, forms_s)
    }

    const parts = [hoursStr, minutesStr, secondsStr].filter(Boolean)

    if (parts.length === 0) {
        return emptyStr
    } else {
        return `${parts.join(" ")}`
    }
}

export function getHumanReadableTimeHM(
    time_hms: string,
    minTime_hms: string = "00:01:00",
    emptyStr: string = "0 минут",
    forms_h: string[] = ["час", "часа", "часов"],
    forms_m: string[] = ["минута", "минуты", "минут"]
): string {
    const [hours, minutes, seconds] = time_hms.split(":").map(Number)
    time_hms = `${hours}:${minutes}:00`

    return getHumanReadableTimeHMS(time_hms, minTime_hms, emptyStr, forms_h, forms_m)
}
