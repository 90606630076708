import React, {forwardRef, RefObject, useRef} from "react"
import {Select, MenuItem, InputLabel, FormControl, Autocomplete, TextField} from "@mui/material"
import {IdNameObj} from "../../../../shared/store/researchSettings/types"
import styles from "./Select.module.scss"

interface ISelect {
    disabled?: boolean
    withAllOption?: boolean
    placeholder: string
    value?: string
    id?: string
    optionList: IdNameObj[] | {joined_ids: string; joined_names: string}[]
    onChange?: any
}

export const CustomSelect = forwardRef(
    (
        {disabled, withAllOption, placeholder, value, optionList, onChange, id, ...props}: ISelect,
        ref: ((instance: unknown) => void) | RefObject<unknown> | null | undefined
    ) => {
        return (
            <FormControl fullWidth size="small">
                <InputLabel id={`${placeholder}${id}-label`}>{placeholder}</InputLabel>
                <Select
                    {...props}
                    variant="outlined"
                    ref={ref}
                    onChange={onChange}
                    disabled={disabled}
                    defaultValue={""}
                    value={value}
                    id={id}
                    labelId={`${placeholder}${id}-label`}
                    label={placeholder}>
                    {withAllOption && <MenuItem value="all">Все</MenuItem>}
                    {optionList?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
)

interface IAutocompleteSelect {
    disabled: boolean
    label: string
    options: {value: string; label: string}[]
    value: {value: string; label: string}
    onChange?: (event: React.SyntheticEvent, value: {value: string; label: string}) => void
}

export const AutocompleteSelect = ({
    disabled,
    options,
    label,
    value,
    onChange,
}: IAutocompleteSelect) => {
    return (
        <Autocomplete
            size="small"
            value={value}
            blurOnSelect
            onChange={onChange}
            disablePortal
            options={options}
            disableClearable
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    // type={"button"}
                    // className={styles.input}
                    // onKeyDown={(e) => e.preventDefault()}
                    label={label}
                />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
        />
    )
}
