import React, {memo} from "react"
import {ProgressBar} from "../../shared/components"
import {useGetEraCurrentEducationQuery} from "../../shared/api/endpoints/userEndpoints"
import DefaultSubjectImage from "./defaultSubjectImage.jpg"
import styles from "./CurrentLessonsWidget.module.scss"
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons/faBuildingColumns"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export interface EraCurrentEducationResponse {
    finish_date: string
    institution_id: string
    institution_image: string
    institution_name: string
    mapping: string | null
    passed_lessons: number
    subject_id: string
    subject_image: string
    subject_name: string
    total_lessons: number
    institution_user_role: string
    institution_user_id: string
}

interface DetailsProps {
    icon: string
    organisation: string
    title: string
    lessonsFinished: number
    lessonsTotal: number
    finishDate: string
}

const Details = memo((props: DetailsProps) => {
    const {icon, organisation, title, lessonsFinished, lessonsTotal, finishDate} = props
    const progress = (lessonsFinished / lessonsTotal) * 100
    return (
        <div className={styles.footer}>
            <div className={styles.organisation}>
                {icon ? (
                    <img src={icon} alt="" />
                ) : (
                    <FontAwesomeIcon size="2xl" className={styles.icon} icon={faBuildingColumns} />
                )}
                <p>{organisation}</p>
            </div>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.access}>
                {`${lessonsFinished} занятий из ${lessonsTotal} | завершается ${finishDate}`}
            </div>
            <ProgressBar
                progress={progress}
                barHeight="5px"
                hideTitle
                hidePercentTitle
                progressColor="#ffc107"
            />
        </div>
    )
})

interface CurrentLessonCardProps {
    subjectImage: string | null
    institutionId: string
    institutionImage: string
    institutionName: string
    subjectName: string
    passed: number
    total: number
    finishDate: string
    mapping: string | null
    userId: string
}

const CurrentLessonCard = (props: CurrentLessonCardProps) => {
    const {
        institutionId,
        institutionImage,
        institutionName,
        subjectImage,
        passed,
        subjectName,
        finishDate,
        total,
        mapping,
        userId,
    } = props
    return (
        <div className={styles.card_container}>
            <button
                type="button"
                className={styles.card}
                onClick={() => {
                    localStorage.setItem("user-id", userId)
                    window.open(`https://erascope.com/pupil/lessons`, "_blank")
                }}>
                <div className={styles.details}>
                    {mapping && <div className={styles.subject}>{mapping}</div>}
                    <Details
                        icon={institutionImage}
                        organisation={institutionName}
                        title={subjectName}
                        lessonsFinished={passed}
                        lessonsTotal={total}
                        finishDate={finishDate}
                    />
                </div>
                <img className={styles.image} src={subjectImage || DefaultSubjectImage} alt="" />
            </button>
        </div>
    )
}

export const CurrentLessonsWidget = () => {
    const {currentData: lessons} = useGetEraCurrentEducationQuery()
    if (!lessons || lessons.length === 0) return null
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <h2>Текущее обучение</h2>
                <div className={styles.cards}>
                    {lessons.length > 0 &&
                        lessons.map((lesson) => (
                            <CurrentLessonCard
                                key={lesson.subject_id}
                                institutionId={lesson.institution_id}
                                institutionImage={lesson.institution_image}
                                institutionName={lesson.institution_name}
                                subjectName={lesson.subject_name}
                                subjectImage={lesson.subject_image}
                                total={lesson.total_lessons}
                                passed={lesson.passed_lessons}
                                finishDate={lesson.finish_date}
                                mapping={lesson.mapping}
                                userId={lesson.institution_user_id}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}
