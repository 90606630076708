import {useEffect, useState} from "react"
import {Outlet, useNavigate} from "react-router-dom"
import {UserDeficits, UnfinishedResearches, DashboardRecommendations, Footer} from "../../widgets"
import {ChartData} from "../../widgets/UserDeficits/types"
import {sortingByHighestDeficits} from "../../shared/helpers/sortingByHighestDeficits"
import {useLazyGetMainDeficitQuery} from "../../shared/api/endpoints/userEndpoints"
import {faChartSimple} from "@fortawesome/free-solid-svg-icons/faChartSimple"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from "./CipDashboardPage.module.scss"
import {SubscriptionDialog} from "./SubscriptionDialog"
import {useSubscriptionDialog} from "./utilities/use-subscription-dialog"

export const CipDashboardPage = () => {
    const navigate = useNavigate()
    const navigateToResearchSettingsPage = () =>
        navigate("/cipui/cip_dashboard/research_settings_page")
    const [sortedUserDeficits, setSortedUserDeficits] = useState<ChartData[] | undefined>()
    const [lazyGetMainDeficitQuery] = useLazyGetMainDeficitQuery()
    const {open, setOpen} = useSubscriptionDialog()

    const handleSubscriptionDialogClose = () => {
        setOpen(false)
        window.location.assign("/cipui")
    }

    useEffect(() => {
        document.title = "ЦИП: Домашняя страница"
        const getSortedUserDeficits = async () => {
            const {isSuccess, data} = await lazyGetMainDeficitQuery()
            if (isSuccess && data?.length > 0) {
                const sortedData = sortingByHighestDeficits(data)
                setSortedUserDeficits(sortedData)
            }
        }
        getSortedUserDeficits()
    }, [])

    return (
        <div className={styles.container}>
            <div>
                <button className={styles.button} onClick={navigateToResearchSettingsPage}>
                    <FontAwesomeIcon size="sm" icon={faChartSimple} />
                    Начать диагностику
                </button>
                <UnfinishedResearches finished={false} />
                <UnfinishedResearches finished={true} />
                <UserDeficits chartData={sortedUserDeficits} />
                <DashboardRecommendations sortedUserDeficits={sortedUserDeficits} />
                <Outlet />
            </div>
            <Footer />
            <SubscriptionDialog open={open} onClose={handleSubscriptionDialogClose} />
        </div>
    )
}
