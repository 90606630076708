import React, {useState, useEffect} from "react"
import {
    useGetTestConclusionQuery,
    useLazyGetTestConclusionDeficitQuery,
    useLazyGetTestConclusionChartQuery,
    useLazyGetTestConclusionHistoryQuery,
    useLazyGetTestConclusionUserHistoryQuery,
    useLazyGetEraConclusionRecommendationsQuery,
    useLazyGetEraCoursesModulesLessonsShortInfoByIdTypePairsQuery,
} from "../../../shared/api/endpoints/researchConclusionEndpoints"
import {AutocompleteSelect} from "../../ResearchSettingsPage/ResearchSettingsForm/Select/Select"
import {useParams} from "react-router-dom"
import {ConclusionBarChart, Paper, ProgressBar} from "../../../shared/components"
import {DeficitSection} from "./DeficitSection/DeficitSection"
import {HistorySection} from "./HistorySection/HistorySection"
import {RecommendationsSection} from "./RecommendationsSection/RecommendationsSection"
import {SliderSection} from "./SliderSection/SliderSection"
import styles from "./ResearchConclusionForm.module.css"
import {
    getHumanReadableTimeHM,
    getNumberAndPlural,
    getPlural,
} from "../../../shared/helpers/timeFormatter"
import {useDispatch} from "react-redux"
import {
    RecommendationIdWithType,
    researchConclusionActions,
} from "../../../shared/store/researchConslusion"
import {CourseDescriptionResponse} from "../../MainPage/Recommendations/types"

export interface IDropdownItem {
    value: string
    label: string
}
interface ResearchConclusionFormProps {
    pupilId: string | null
}

export const ResearchConclusionForm = ({pupilId}: ResearchConclusionFormProps) => {
    const dispatch = useDispatch()
    const {sessionId} = useParams()
    const {currentData} = useGetTestConclusionQuery(sessionId as string)

    //<recommendations>
    const [lazyGetTestConclusionDeficitQuery, {currentData: deficitData}] =
        useLazyGetTestConclusionDeficitQuery()
    const [lazyGetRecommendationsEraIdsTypesQuery] = useLazyGetEraConclusionRecommendationsQuery()
    const [lazyGetEraDescriptionsByIdsTypesQuery] =
        useLazyGetEraCoursesModulesLessonsShortInfoByIdTypePairsQuery()
    const [recommendationsArr, setRecommendationsArr] = useState<CourseDescriptionResponse[]>([])
    //</recommendations>

    //<charts>
    const [lazyGetTestConclusionChartQuery, {currentData: chartData}] =
        useLazyGetTestConclusionChartQuery()
    const [lazyGetTestConclusionUserHistoryQuery, {currentData: userHistory}] =
        useLazyGetTestConclusionUserHistoryQuery()
    //</charts>

    const [lazyGetTestConclusionHistoryQuery, {currentData: historyData}] =
        useLazyGetTestConclusionHistoryQuery()

    const [dropdownValue, setDropdownValue] = useState<IDropdownItem>({
        value: "",
        label: "",
    })
    const dropDownArray =
        currentData?.drop_down.map((select) => ({
            value: select.joined_ids,
            label: select.joined_names,
        })) || []
    const onlyOneDropDownValue = dropDownArray?.length === 1
    const {resetSelectedFieldsWithoutSubjects} = researchConclusionActions

    useEffect(() => {
        if (currentData) {
            handleSelectDropdown(dropDownArray[0])
        }
    }, [currentData])

    const getRecommendationsForConclusionPage = async (query: string) => {
        const {data: recommendationIdsWithType} = await lazyGetRecommendationsEraIdsTypesQuery({
            params: query,
            pupilId: pupilId,
        })
        const recommendationsArray: CourseDescriptionResponse[] = []
        let subjectsIds: RecommendationIdWithType["id"][] = []
        let modulesIds: RecommendationIdWithType["id"][] = []
        let lessonsIds: RecommendationIdWithType["id"][] = []
        recommendationIdsWithType?.forEach(({id, content_type}) => {
            if (content_type === "subject") {
                subjectsIds.push(id)
            } else if (content_type === "module") {
                modulesIds.push(id)
            } else if (content_type === "lesson") {
                lessonsIds.push(id)
            }
        })
        if (subjectsIds.length > 0) {
            const {data} = await lazyGetEraDescriptionsByIdsTypesQuery({
                by: "subjects",
                ids: subjectsIds.join("|"),
            })
            if (data) recommendationsArray.push(data)
        }
        if (modulesIds.length > 0) {
            const {data} = await lazyGetEraDescriptionsByIdsTypesQuery({
                by: "modules",
                ids: modulesIds.join("|"),
            })
            if (data) recommendationsArray.push(data)
        }
        if (lessonsIds.length > 0) {
            const {data} = await lazyGetEraDescriptionsByIdsTypesQuery({
                by: "lessons",
                ids: lessonsIds.join("|"),
            })
            if (data) recommendationsArray.push(data)
        }
        return recommendationsArray.flat()
    }
    useEffect(() => {
        if (deficitData) {
            getRecommendationsForConclusionPage(deficitData.query).then((recommendations) => {
                setRecommendationsArr(recommendations)
            })
        }
    }, [deficitData])

    const handleSelectDropdown = (dropdownValue: IDropdownItem) => {
        dispatch(resetSelectedFieldsWithoutSubjects())
        setDropdownValue(dropdownValue)
        lazyGetTestConclusionChartQuery({sessionId: sessionId, selectValue: dropdownValue.value})
        lazyGetTestConclusionUserHistoryQuery(pupilId)
        lazyGetTestConclusionDeficitQuery({selectValue: dropdownValue.value, pupilId})
        lazyGetTestConclusionHistoryQuery({sessionId: sessionId, selectValue: dropdownValue.value})
    }

    const handleOnChangeDropdown = (event: React.SyntheticEvent, value: IDropdownItem) => {
        handleSelectDropdown(value)
    }

    return (
        <>
            <ProgressBar
                progress={100}
                barHeight="5px"
                hideTitle
                hidePercentTitle
                progressColor="#ff9300"
            />
            <div className={styles.text}>
                Вы закончили диагностику по{" "}
                {getPlural(currentData?.subject_areas?.length, [
                    "области компетенции",
                    "областям компетенций",
                ])}{" "}
                {currentData?.subject_areas.join(", ")}. Было исследовано{" "}
                {getNumberAndPlural(currentData?.qty_skills, [
                    "знание/умение",
                    "знания/умения",
                    "знаний/умений",
                ])}
                , пройдено{" "}
                {getNumberAndPlural(currentData?.qty_questions, ["задание", "задания", "заданий"])}.
                Время диагностики составило{" "}
                {getHumanReadableTimeHM(
                    currentData?.examination_time_hms || "00:00:00",
                    "00:01:00",
                    "0 минут",
                    ["час", "часа", "часов"],
                    ["минуту", "минуты", "минут"]
                )}
                . Результаты представлены на диаграмме ниже.
            </div>
            {!onlyOneDropDownValue && (
                <AutocompleteSelect
                    label="Выберете тему"
                    disabled={!dropDownArray}
                    options={dropDownArray || []}
                    onChange={handleOnChangeDropdown}
                    value={
                        dropDownArray?.find((item) => item.label === dropdownValue.label) ||
                        dropdownValue
                    }
                />
            )}
            {dropdownValue.label && (
                <>
                    {chartData && (
                        <Paper>
                            <ConclusionBarChart chartData={chartData} />
                        </Paper>
                    )}
                    {userHistory && (
                        <SliderSection data={userHistory} dropdownValue={dropdownValue} />
                    )}
                    {deficitData && <DeficitSection deficitData={deficitData} />}
                    {recommendationsArr.length > 0 ? (
                        <RecommendationsSection recommendations={recommendationsArr} />
                    ) : null}
                    {historyData && <HistorySection historyData={historyData} />}
                </>
            )}
        </>
    )
}
