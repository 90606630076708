import {configureStore} from "@reduxjs/toolkit"
import rootReducer from "./reducers"
import {apiEndpoints} from "../api/endpoints/apiEndpoints"
import {researchSettingsEndpoints} from "../api/endpoints/researchSettingsEndpoints"
import {researchConclusionEndpoints} from "../api/endpoints/researchConclusionEndpoints"
import {userEndpoints} from "../api/endpoints/userEndpoints"

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(researchSettingsEndpoints.middleware)
            .concat(researchConclusionEndpoints.middleware)
            .concat(userEndpoints.middleware)
            .concat(apiEndpoints.middleware),
})
