import {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCompass} from "@fortawesome/free-solid-svg-icons"
import {PieChartWithCenterText} from "../../shared/components"
import {MainPageRecommendationsList} from "../../pages/MainPage/Recommendations/Recommendations"
import {
    useLazyGetDescriptionIdsQuery,
    useLazyGetMainDeficitQuery,
} from "../../shared/api/endpoints/userEndpoints"
import {ChartData} from "../UserDeficits/types"
import {EraCourseModuleOrLessonIdPair} from "../../pages/MainPage/Recommendations/types"
import {sortingByHighestDeficits} from "../../shared/helpers/sortingByHighestDeficits"
import styles from "./MainPageRecommendations.module.scss"
import {useNavigate} from "react-router-dom"

export const MainPageRecommendations = () => {
    const navigate = useNavigate()
    const [lazyGetMainDeficitQuery] = useLazyGetMainDeficitQuery()
    const [lazyGetDescriptionIdsQuery] = useLazyGetDescriptionIdsQuery()
    const [mainUserDeficit, setMainUserDeficit] = useState<ChartData | undefined>()
    const [recommendedCoursesList, setRecommendedCoursesList] = useState<
        EraCourseModuleOrLessonIdPair[]
    >([])

    useEffect(() => {
        const getUserDeficitAndRecommendations = async () => {
            const {data, isSuccess} = await lazyGetMainDeficitQuery() // step 1
            if (isSuccess && data?.length > 0) {
                const deficits = [...data]
                const sortedDeficits = sortingByHighestDeficits(deficits)
                const mainDeficit = sortedDeficits[0]
                setMainUserDeficit(mainDeficit)
                const {data: description, isSuccess} = await lazyGetDescriptionIdsQuery({
                    by: "subjects",
                    query: mainDeficit.query,
                })
                if (isSuccess) setRecommendedCoursesList(description.results)
            }
        }
        getUserDeficitAndRecommendations()
    }, [])

    return (
        <>
            <div className={styles.title}>
                <h2 className={styles.link} onClick={() => navigate("/cipui/cip_dashboard")}>
                    Рекомендации
                </h2>
                <FontAwesomeIcon icon={faCompass} />
                {mainUserDeficit && <h2>{`по ${mainUserDeficit.main_component.name}`}</h2>}
            </div>
            <div className={styles.deficit__recommendations}>
                <div
                    className={styles.deficit_container}
                    onClick={() => navigate("/cipui/cip_dashboard")}>
                    {mainUserDeficit ? (
                        <PieChartWithCenterText
                            deficit={mainUserDeficit.main_component.deficit}
                            width={300}
                            height={300}
                            innerRadius={90}
                            outerRadius={149}
                            labelFill="#444"
                            labelWidth={180}
                            withCustomLabel={true}
                        />
                    ) : (
                        <div className={styles.deficit_placeholder}>
                            Для определения уровня дефицитов пройдите диагностику
                        </div>
                    )}
                </div>
                {recommendedCoursesList.length > 0 ? (
                    <MainPageRecommendationsList
                        eraCoursesModulesLessonsMeta={recommendedCoursesList}
                    />
                ) : (
                    <div className={styles.placeholder}>
                        Для получения рекоммендаций, пройдите свою первую диагностику
                    </div>
                )}
            </div>
        </>
    )
}
