import {useNavigate, Navigate} from "react-router-dom"
import {Dispatch, SetStateAction, useState} from "react"
import {useLoginMutation} from "../../shared/api/endpoints/userEndpoints"
import AccessToken, {setAccessToken} from "../../shared/api/AccessToken"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import styles from "./LoginModal.module.scss"

interface LoginModalProps {
    setOpened: Dispatch<SetStateAction<boolean>>
}

export const LoginModal = ({setOpened}: LoginModalProps) => {
    const navigate = useNavigate()
    const [login, setLogin] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState(false)
    const [loginMutation] = useLoginMutation()

    if (AccessToken()) {
        return <Navigate to={"/cipui"} />
    }

    const handleLogin = async () => {
        const response = await loginMutation({login, password})
        // const response: LoginResponse = {
        //     success: true,
        //     token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZGFhNTQ4NDI3MjZmODYzMmRhZTFiZTIiLCJpYXQiOjE2ODkzMzQ2OTF9.ZUliso_tXYfFdscvQfHQYzylgS_kXZvz1Q_QZsGayCs",
        // }
        // @ts-ignore
        if (response.data?.success) {
            // @ts-ignore
            setAccessToken(response.data.token)
            window.location.href = "/cipui/"
            // navigate("/cipui/", {replace: true})
        }
        // @ts-ignore
        if (response.error?.status === 400) {
            setError(true)
        }
    }
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <form className={styles.form}>
                    <div className={styles.title}>Вход</div>
                    <input
                        placeholder="Логин"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        type="text"
                    />
                    <input
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />
                    {error && <div className={styles.error}>Неверный логин или пароль</div>}
                    <button className={styles.login} type={"button"} onClick={handleLogin}>
                        Войти
                    </button>
                    <button
                        type={"button"}
                        className={styles.close}
                        onClick={() => setOpened(false)}>
                        <FontAwesomeIcon size="xl" icon={faXmark} />
                    </button>
                </form>
            </div>
        </div>
    )
}
