import React from "react"
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
} from "recharts"
import {ISliderSkills} from "../../../../../shared/store/researchConslusion"
import {getMockedRandomColor} from "../../../../../shared/helpers/getRandomColor"
import dayjs from "dayjs"

interface ISliderChart {
    skillsData: ISliderSkills[]
    namesArray: string[]
    dashedNamesArray: string[]
    sessionId: string | undefined
}

export const SliderChart = ({
    skillsData,
    namesArray,
    dashedNamesArray,
    sessionId,
}: ISliderChart) => {
    const referenceLineDate = skillsData.find((skill) => skill.session_id === sessionId)
    return (
        <ComposedChart width={820} height={400} data={skillsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" style={{fontSize: 12}} hide />
            {/*<XAxis dataKey="date" hide />*/}
            <YAxis domain={[0, 100]} />
            <Tooltip
                labelFormatter={(label, payload) => {
                    if (payload && payload.length && payload[0].color) {
                        payload.map((item) => {
                            if (item.color) {
                                item.color = item.color.slice(0, -2)
                            }
                        })
                    }
                    return dayjs(label).format("DD/MM/YYYY, HH:mm")
                }}
            />
            <Legend
                formatter={(value, entry, index) => {
                    // @ts-ignore
                    const color = entry.payload?.stroke
                    return (
                        <span className="text-color-class" style={{color: color.slice(0, -2)}}>
                            {value}
                        </span>
                    )
                }}
            />
            {namesArray.map((name, index) => (
                <Line
                    key={name + index}
                    type="monotone"
                    dataKey={name}
                    isAnimationActive={false}
                    stroke={getMockedRandomColor(index)}
                    strokeWidth={3}
                    connectNulls
                />
            ))}
            {dashedNamesArray.map((dashedName, index) => (
                <Line
                    key={dashedName + index}
                    type="monotone"
                    legendType="none"
                    dataKey={dashedName}
                    isAnimationActive={false}
                    strokeDasharray="5 5"
                    stroke={getMockedRandomColor(index)}
                    strokeWidth={3}
                    connectNulls
                />
            ))}
            {referenceLineDate && (
                <ReferenceLine
                    x={referenceLineDate.date}
                    stroke="#444"
                    strokeDasharray="5, 5"
                    label={{
                        // position: "right",
                        value: "Текущая\n диагностика",
                        // fill: "#595959",
                        width: "50px",
                        fontSize: "0.75rem",
                    }}
                    // label="Текущая диагностика"
                />
            )}
            {/*<Line type="monotone" dataKey="*skill_1" stroke="#ff7300" connectNulls />*/}
            {/*<Line type="monotone" dataKey="*skill_2" stroke="red" connectNulls />*/}
        </ComposedChart>
    )
}
