import React from "react"
import {Paper} from "../../../../shared/components"
import {DeficitChart} from "../../../../shared/components"
import {IConclusionDeficitResponse} from "../../../../shared/store/researchSettings/types"

interface IDeficitSection {
    deficitData: IConclusionDeficitResponse
}

export const DeficitSection = ({deficitData}: IDeficitSection) => {
    const modules = [
        {
            moduleDeficit: deficitData.main_component.deficit,
            moduleTitle: deficitData.main_component.name,
            lessons:
                deficitData.subcomponents?.map((subcomponent) => ({
                    deficit: subcomponent.deficit,
                    title: subcomponent.name,
                })) || [],
        },
    ]

    return (
        <Paper>
            <DeficitChart
                withProgressBars
                subjectDeficit={deficitData.main_component.deficit}
                subjectTitle={deficitData.main_component.name}
                modules={modules}
            />
        </Paper>
    )
}
