import {AccessTypes} from "../components/RecommendationItem/RecommendationsTooltip/types"

export function getTextFromAccessType(accessType: AccessTypes): string {
    switch (accessType) {
        case AccessTypes.free:
            return "Бесплатно"
        case AccessTypes.subscription:
            return "Подписка"
        case AccessTypes.payment:
            return "Платно"
        case AccessTypes.trainingApplication:
            return "Заявка"
    }
    return ""
}
