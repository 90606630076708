import {Dispatch, SetStateAction, useEffect, useState} from "react"
import {useLazyGetEraFindInstitutionsQuery} from "../../shared/api/endpoints/userEndpoints"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import {InstitutionCard} from "../../shared/components/InstitutionCard/InstitutionCard"
import styles from "./SearchModal.module.scss"

interface SearchModalProps {
    opened: boolean
    setOpened: Dispatch<SetStateAction<boolean>>
}

export const SearchModal = ({opened, setOpened}: SearchModalProps) => {
    const [getEraFindInstitutions, {currentData}] = useLazyGetEraFindInstitutionsQuery()
    const [searchString, setSearchString] = useState("")
    const handleFindInstitutions = () => getEraFindInstitutions(searchString)

    const onKeyDownSearch = (event: any) => {
        if (event.key === "Enter") {
            handleFindInstitutions()
        }
    }

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setOpened(false)
            }
        }
        document.body.style.overflow = "hidden"
        window.addEventListener("keydown", onKeyDown)
        return () => {
            document.body.style.overflow = "visible"
            window.removeEventListener("keydown", onKeyDown)
        }
    }, [])

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.form}>
                    <input
                        type={"text"}
                        value={searchString}
                        placeholder="Поиск"
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={onKeyDownSearch}
                    />
                    <button
                        className={styles.find}
                        type={"button"}
                        disabled={!searchString}
                        onClick={handleFindInstitutions}>
                        <FontAwesomeIcon size="sm" icon={faMagnifyingGlass} />
                    </button>
                    <button
                        type={"button"}
                        className={styles.close}
                        onClick={() => setOpened(false)}>
                        <FontAwesomeIcon size="sm" icon={faXmark} />
                    </button>
                </div>
                <h4>Организации</h4>
                <div className={styles.content}>
                    {currentData?.institutions?.map((institution) => (
                        <InstitutionCard
                            key={institution._id}
                            institutionId={institution._id}
                            image={institution.image}
                            name={institution.name}
                            shortName={institution.shortName}
                            fullName={institution.fullName}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
