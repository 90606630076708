// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliderSection_wrapper__tiyeW {\n  display: flex;\n  gap: 10px;\n}\n\n.SliderSection_prev__TuZWH {\n  position: absolute;\n  left: -15px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 30px;\n  height: 30px;\n  background-color: lightgray;\n  border-radius: 50%;\n  border: none;\n  cursor: pointer;\n}\n\n.SliderSection_next__wKg1H {\n  position: absolute;\n  right: -15px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 30px;\n  height: 30px;\n  background-color: lightgray;\n  border-radius: 50%;\n  border: none;\n  cursor: pointer;\n}\n\n.SliderSection_checkbox__QBv-2 span {\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResearchConclusionPage/ResearchConclusionForm/SliderSection/SliderSection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAEF;;AAAA;EACE,kBAAA;EACA,YAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAGF;;AAAE;EACD,eAAA;AAGD","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 10px;\n}\n.prev {\n  position: absolute;\n  left: -15px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 30px;\n  height: 30px;\n  background-color: lightgray;\n  border-radius: 50%;\n  border: none;\n  cursor: pointer;\n}\n.next {\n  position: absolute;\n  right: -15px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 30px;\n  height: 30px;\n  background-color: lightgray;\n  border-radius: 50%;\n  border: none;\n  cursor: pointer;\n}\n.checkbox {\n  span {\n\tfont-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SliderSection_wrapper__tiyeW",
	"prev": "SliderSection_prev__TuZWH",
	"next": "SliderSection_next__wKg1H",
	"checkbox": "SliderSection_checkbox__QBv-2"
};
export default ___CSS_LOADER_EXPORT___;
