import {PieChartByUserDeficits} from "../../shared/components/PieChartByUserDeficits/PieChartByUserDeficits"
import {ChartData} from "./types"
import styles from "./UserDeficits.module.scss"

interface UserDeficitsProps {
    chartData?: ChartData[] | undefined
}

export const UserDeficits = ({chartData}: UserDeficitsProps) => {
    return (
        <div className={styles.wrapper}>
            <h2>Уровни исследованных областей компетенций</h2>
            <div className={styles.cards}>
                {chartData ? (
                    chartData.map((chart) => (
                        <PieChartByUserDeficits
                            key={chart.main_component.id}
                            deficit={chart.main_component.deficit}
                            subject_name={chart.main_component.name}
                            subject_competences={chart.subcomponents}
                            width={125}
                            height={125}
                            innerRadius={45}
                            outerRadius={60}
                            labelFill="#444"
                            cursor="default"
                        />
                    ))
                ) : (
                    <div>
                        Нет исследованных областей компетенций, пройдите свою первую диагностику
                    </div>
                )}
            </div>
        </div>
    )
}
