import {ChartData} from "../../widgets/UserDeficits/types"

export const sortingByHighestDeficits = (array: ChartData[]) => {
    return array.sort((a, b) => {
        if (a.main_component.deficit < b.main_component.deficit) {
            return 1
        }
        if (a.main_component.deficit > b.main_component.deficit) {
            return -1
        }
        return 0
    })
}
