import {Checkbox, FormControlLabel} from "@mui/material"
import {SliderSectionItem} from "../SliderSectionItem/SliderSectionItem"
import {useDispatch, useSelector} from "react-redux"
import {
    ISliderSubject,
    researchConclusionActions,
    selectCcompetencesSelected,
    selectCompetencesSelected,
    selectSkillsSelected,
    selectSubjects,
    selectSubjectsSelected,
} from "../../../../../shared/store/researchConslusion"
import {
    getFilteredCcompetenceForSubject,
    getFilteredCompetenceForSubject,
    getFilteredSkillsForSubject,
} from "./helpers"
import styles from "../SliderSection.module.scss"

export const SubjectColumn = () => {
    const dispatch = useDispatch()
    const subjects = useSelector(selectSubjects)
    const subjectsSelected = useSelector(selectSubjectsSelected)
    const competencesSelected = useSelector(selectCompetencesSelected)
    const ccompetencesSelected = useSelector(selectCcompetencesSelected)
    const skillsSelected = useSelector(selectSkillsSelected)
    const {
        addSubjectsSelected,
        setSubjectsSelected,
        removeSubjectsSelected,
        setCompetencesSelected,
        setCcompetencesSelected,
        setSkillsSelected,
    } = researchConclusionActions
    const disableAllButton = subjects.length <= 0
    const disableReset = subjects.length <= 0 || subjectsSelected.length === 0

    const handleSelectAll = () => dispatch(setSubjectsSelected(subjects))

    const handleReset = () => {
        dispatch(setSubjectsSelected([]))
        dispatch(setCompetencesSelected([]))
        dispatch(setCcompetencesSelected([]))
        dispatch(setSkillsSelected([]))
    }

    const checkboxIsChecked = (name: string) =>
        subjectsSelected.some((subject) => subject.subject_name === name)

    const handleToggleCheckbox = (e: any, subject: ISliderSubject) => {
        if (e.target.checked) {
            dispatch(addSubjectsSelected(subject))
        } else {
            dispatch(removeSubjectsSelected(subject))
            if (competencesSelected.length > 0) {
                const filteredCompetences = getFilteredCompetenceForSubject(
                    subject,
                    competencesSelected
                )
                dispatch(setCompetencesSelected(filteredCompetences))
            }
            if (ccompetencesSelected.length > 0) {
                const filteredCcompetence = getFilteredCcompetenceForSubject(
                    subject,
                    ccompetencesSelected
                )
                dispatch(setCcompetencesSelected(filteredCcompetence))
            }
            if (skillsSelected.length > 0) {
                const filteredSkills = getFilteredSkillsForSubject(subject, skillsSelected)
                dispatch(setSkillsSelected(filteredSkills))
            }
        }
    }

    return (
        <SliderSectionItem
            title="Область компетенции"
            active={true}
            disableAllButton={disableAllButton}
            disableReset={disableReset}
            onResetClick={handleReset}
            onSelectAllClick={handleSelectAll}>
            {subjects.map((subject, index) => (
                <FormControlLabel
                    key={index}
                    className={styles.checkbox}
                    label={subject.subject_name}
                    control={
                        <Checkbox
                            size="small"
                            checked={checkboxIsChecked(subject.subject_name)}
                            onClick={(e) => handleToggleCheckbox(e, subject)}
                        />
                    }
                />
            ))}
        </SliderSectionItem>
    )
}
